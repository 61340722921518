import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiClock } from 'react-icons/fi';
import { Radio } from '../../components/forms/radios';
import formatPrice from '../../functions/formatPrice';
import { doGetBrandSubscriptionList, doGetBrandSubscription } from '../../services/api/command/brand-subscription.command';
import SubscriptionConfirmationModal from './components/modals/subscription-confirmation';

function Package({data, active, selected, month = 1, onClick = {}}) {
  let multiplier;
  switch (month) {
    case 6:
      multiplier = 5.5;
      break;
    case 12:
      multiplier = 11;
      break;
    default:
      multiplier = 1;
      break;
  }
  return (
    <div className={`w-full p-3 border-2 rounded ${active?.id === data.id || selected?.id === data.id ? 'border-primary' : 'border-grey-400'}`} onClick={onClick}>
      <div className='flex items-center justify-between'>
        <div className='space-y-2'>
          <span>{data.name}</span>
          <h5 className='font-bold'>{null !== data.price ? (data.price === 0 ? 'Free' : formatPrice(data.price * multiplier)) : 'Harga Spesial'}</h5>
        </div>
        <div className='space-y-2 text-xs'>
          {(active?.id === data.id) && (
            <span className='text-primary'>Paket Anda saat ini</span>
          )}
          <div className='p-1 rounded bg-grey-200 text-grey-600'>{data.description}</div>
        </div>
      </div>
    </div>
  )
}

function Subscription() {
  const history = useHistory();
  const [selected, setSelected] = useState(null);
  const [isCustom, setIsCustom] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [periode, setPeriode] = useState(1);

  const data = doGetBrandSubscriptionList().query('brand-subscription-list').data?.data.data.list;
  const brandSubscription = doGetBrandSubscription().query('brand-subscription').data?.data.data;

  if (data?.filter(el => null === el.id).length === 0) {
    data.unshift({
      id: null,
      name: 'Paket 5',
      description: 'Lebih dari 5000 reseller',
      price: null,
      max_agent: null,
    })
  }

  useEffect(() => {
    let selectedPackage = data?.filter((value, key) => {
      if (value.id === brandSubscription?.subscription_package_id) {
        return value;
      }

      return null;
    }).shift()

    setSelected(selectedPackage);
  }, [data, brandSubscription])

  const handleChangedPeriode = (periode) => {
    setPeriode(periode);
  }

  const handleSelectedPackage = (selectedPackage) => {
    setSelected(selectedPackage)

    if (null === selectedPackage.id) {
      setIsCustom(true)
    } else {
      setIsCustom(false)
    }

    if (selectedPackage.price === 0) {
      setIsFree(true)
    } else {
      setIsFree(false)
    }
  }

  const handleSubcriptionHistory = () => {
    history.push(`/subscriptions/history`);
  }

  return (
    <div className='flex flex-col items-center gap-y-6'>
      <div className='flex items-center justify-between w-1/2'>
        <h5 className='font-bold'>Paket Subskripsi Rintiz</h5>
        <button
          type='button'
          className='flex items-center px-4 py-2 text-sm text-black border rounded-lg btn gap-x-2'
          onClick={() => handleSubcriptionHistory()}
        >
          <FiClock className='text-lg stroke-current' /> Riwayat Subskripsi Paket
        </button>
      </div>
      <div className='flex items-center justify-start w-1/2'>
        <span>Pilih Periode</span>
      </div>
      <div className='flex items-center w-1/2 p-2 rounded-lg bg-grey-50 justify-evenly'>
        <div onClick={() => handleChangedPeriode(1)} className={`cursor-pointer px-12 py-2 rounded-lg border ${periode === 1 ? 'bg-white text-primary border-primary' : 'border-grey-50'}`}>
          1 bulan
        </div>
        <div onClick={() => handleChangedPeriode(6)} className={`cursor-pointer px-12 py-2 rounded-lg border ${periode === 6 ? 'bg-white text-primary border-primary' : 'border-grey-50'}`}>
          6 bulan
        </div>
        <div onClick={() => handleChangedPeriode(12)} className={`cursor-pointer px-12 py-2 rounded-lg border ${periode === 12 ? 'bg-white text-primary border-primary' : 'border-grey-50'}`}>
          12 bulan
        </div>
      </div>
      <div className='flex items-center justify-start w-1/2'>
        <span>Pilih Paket</span>
      </div>
      <div className='flex flex-col-reverse items-center justify-start w-1/2 gap-y-3'>
        {data?.map((value, key) => (
          <Package 
            data={value} 
            active={value.id === brandSubscription?.subscription_package_id ? value : null}
            selected={selected}
            month={periode}
            onClick={() => handleSelectedPackage(value)} 
          />
        ))}
      </div>
      <div className='flex items-center justify-start w-1/2'>
        <SubscriptionConfirmationModal subscribeOn={selected} duration={periode} isCustom={isCustom} isFree={isFree} />
      </div>
    </div>
  );
}

export default Subscription;
