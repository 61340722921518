import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { FiCopy, FiFacebook, FiInstagram } from 'react-icons/fi';
import toast from 'react-hot-toast';

import Portal from '../../../components/portal';
import { FaFacebook, FaTelegram, FaWhatsapp } from 'react-icons/fa';

function ModalShop({ open, setOpen, data }) {
  const history = useHistory();
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef, setOpen]);

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://${data.subdomain}.rintiz.id`);
    toast.success('URL Toko telah tersalin');
  }

  const handleFacebookSharer = () => {
    window.open(`https://www.facebook.com/sharer/sharer.php?u=https://${data.subdomain}.rintiz.id&picture=https://${window.location.hostname}/rintiz-logo.svg&title=Gabung+dengan+saya&quote=Yuk,+Bergabung+dengan+Reseller+${data.subdomain}.`, '_blank')
  }

  const handleTelegramSharer = () => {
    window.open(`https://telegram.me/share/url?url=https://${data.subdomain}.rintiz.id&text=Yuk,+Bergabung+dengan+Reseller+${data.subdomain}.`, '_blank');
  }

  const handleWhatsappSharer = () => {
    window.open(`https://wa.me/?text=Yuk,+Bergabung+dengan+Reseller+${data.subdomain}+di+https://${data.subdomain}.rintiz.id.`, '_blank')
  }

  return (
    <>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/6 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='px-4 pt-6 pb-4 bg-white rounded-lg'>
                <h5 className='font-semibold'>Link Toko</h5>
                <div className='flex items-center justify-between p-3 mt-2 rounded-md link wrapper bg-blue-50'>
                  <div className='text-blue-600 web-link'>
                    {`https://${data.subdomain}.rintiz.id`}
                  </div>
                  <div className='flex items-center justify-start cursor-pointer copy gap-x-1'>
                    <div>
                      <FiCopy color='#8C98A4' />
                    </div>
                    <div className='cursor-pointer text-grey-500' onClick={handleCopy}>Copy</div>
                  </div>
                </div>
                <div className='mt-8'>
                  <h5 className='font-semibold'>Share</h5>
                  <div className='mt-4 socials'>
                    <div className='flex justify-start gap-x-5'>
                      <div className='flex flex-col items-center justify-center cursor-pointer' onClick={handleFacebookSharer}>
                        <FaFacebook size={30} color='#8C98A4' />
                        <span className='mt-2 text-xs text-grey-700'>
                          Facebook
                        </span>
                      </div>
                      <div className='flex flex-col items-center justify-center cursor-pointer' onClick={handleTelegramSharer}>
                        <FaTelegram size={30} color='#8C98A4' />
                        <span className='mt-2 text-xs text-grey-700'>
                          Telegram
                        </span>
                      </div>
                      <div className='flex flex-col items-center justify-center cursor-pointer' onClick={handleWhatsappSharer}>
                        <FaWhatsapp size={30} color='#8C98A4' />
                        <span className='mt-2 text-xs text-grey-700'>
                          Whatsapp
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='mt-10'>
                  <button
                    onClick={() => setOpen(false)}
                    className='flex px-4 py-2 ml-auto font-bold text-white border rounded-lg btn bg-primary border-primary'
                  >
                    Tutup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}

export default ModalShop;
