import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  doCheckAvailableSubdomain,
  doGetProfile,
  doUpdateSubdomain,
} from 'services/api/command/profile.command';

import { Button } from 'components/buttons/buttons';

import SubdomainIcon from '../../_assets/images/subdomain.svg';

function Subdomain() {
  const profile = doGetProfile().query('profile').data?.data.data;
  const profileData = doGetProfile().query('profile');

  const [currentSubdomain, setCurrentSubdomain] = React.useState('');
  const [availability, setAvailability] = React.useState(false);
  const [message, setMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);

  const { register, getValues, watch, setValue } = useForm();
  watch();

  const handleSubdomainCheck = () => {
    setLoading(true);
    const body = {
      subdomain: getValues('subdomain'),
    };

    doCheckAvailableSubdomain()
      .command(body)
      .then((response) => {
        setCurrentSubdomain(response.data.subdomain);
        setLoading(false);
        setAvailability(true);
        setValue('landingDomain', response.data.subdomain);
        // toast.success('Subdomain tersedia');
        setMessage('Nama domain tersedia');
      })
      .catch((err) => {
        // toast.error(err.response.data.error);
        setLoading(false);
        setAvailability(false);
        setMessage('Nama domain tidak tersedia');
      });
  };

  const handleEditSubdomain = () => {
    setLoading(true);

    doUpdateSubdomain()
      .command({
        subdomain: getValues('subdomain'),
        brandowner_id: profile?.brandowner_id,
      })
      .then(() => {
        toast.success('Subdomain berhasil diubah', {
          id: 'subdomain-success',
        });
        setLoading(false);
        profileData.refetch();
      })
      .catch(() => {
        setLoading(false);
        toast.error('Terjadi kesalahan', {
          id: 'subdomain-error',
        });
      });
  };

  useEffect(() => {
    if (currentSubdomain !== getValues('subdomain')) {
      setAvailability(false);
      setMessage('');
      return;
    }
  }, [getValues('subdomain')]);

  const handleOpenToko = () => {
    window.open(`https://${profileData.data?.data.data.subdomain}.rintiz.id`, '_blank');
  };

  const handleCopyToko = () => {
    navigator.clipboard.writeText(`https://${profileData.data?.data.data.subdomain}.rintiz.id`);
    toast.success('URL Toko telah tersalin');
  }

  const handleOpenLanding = () => {
    window.open(`https://${profileData.data?.data.data.subdomain}.landing.rintiz.id`, '_blank');
  }

  const handleCopyLanding = () => {
    navigator.clipboard.writeText(`https://${profileData.data?.data.data.subdomain}.landing.rintiz.id`);
    toast.success('URL Landing Page telah tersalin');
  }

  return (
    <div className='w-full p-4 border border-gray-200 rounded-md'>
      <div className='flex justify-between gap-x-10'>
        <div className='flex w-1/2'>
          <img src={SubdomainIcon} alt='Subdomain' className='w-12 h-12' />
          <div className='flex flex-col w-full ml-4'>
            <span className='block mb-1 text-sm font-bold'>Toko Saya</span>
            {!profileData.data?.data.data.subdomain ? (
              <div>
                <span className='block text-sm'>
                  Atur domain untuk toko Anda
                </span>
                <div className='flex flex-row items-center w-full mt-4'>
                  <div className='flex w-82'>
                    <input
                      placeholder='Masukkan subdomain'
                      type='text'
                      name='subdomain'
                      // disabled={profile?.subdomain}
                      defaultValue={profile?.subdomain}
                      className='w-full px-4 py-2 text-sm border-t border-b border-l rounded-l-lg'
                      // className='px-3 py-2 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm'
                      {...register('subdomain')}
                    />
                    <div className='w-2/5 py-2 text-center rounded-r-lg bg-grey-200'>
                      .rintiz.id
                    </div>
                  </div>
                  <div
                    onClick={handleSubdomainCheck}
                    className='ml-auto cursor-pointer text-primary'
                  >
                    Cek ketersediaan
                  </div>
                </div>
                <div className='pt-2 text-primary'>{message}</div>
                <div className='pt-3'>
                  <Button
                    loading={loading}
                    disabledClassname='bg-grey-400 px-4 text-xs rounded-lg py-2 text-white'
                    text={'Simpan'}
                    onClick={handleEditSubdomain}
                    disabled={!availability}
                    className='self-start block px-4 py-2 text-xs font-bold text-center text-white ease-in bg-blue-300 rounded-lg cursor-pointer'
                  />
                </div>
              </div>
            ) : (
              <div>
                <span className='block my-2 text-sm'>
                  Berikut adalah domain toko Anda
                </span>
                <div className='flex items-center w-full'>
                  <input
                    type='text'
                    name='subdomain'
                    disabled
                    defaultValue={profile?.subdomain}
                    className='w-full px-4 py-2 text-sm bg-white border-t border-b border-l rounded-l-lg'
                  />
                  <div className='w-2/5 py-2 text-center border rounded-r-lg bg-blue-grey-50'>
                    .rintiz.id
                  </div>
                  <div onClick={handleCopyToko} className='p-2 ml-2 rounded-lg cursor-pointer bg-blue-grey-50'>
                    <svg
                      width='23'
                      height='23'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 10.7501V14.2501C13.3332 17.1667 12.1665 18.3334 9.24984 18.3334H5.74984C2.83317 18.3334 1.6665 17.1667 1.6665 14.2501V10.7501C1.6665 7.83341 2.83317 6.66675 5.74984 6.66675H9.24984C12.1665 6.66675 13.3332 7.83341 13.3332 10.7501Z'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M18.3332 5.75008V9.25008C18.3332 12.1667 17.1665 13.3334 14.2498 13.3334H13.3332V10.7501C13.3332 7.83341 12.1665 6.66675 9.24984 6.66675H6.6665V5.75008C6.6665 2.83341 7.83317 1.66675 10.7498 1.66675H14.2498C17.1665 1.66675 18.3332 2.83341 18.3332 5.75008Z'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <div onClick={handleOpenToko} className='p-2 ml-2 rounded-lg cursor-pointer bg-blue-grey-50'>
                    <svg
                      width='23'
                      height='23'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.8335 9.16658L17.6668 2.33325'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M18.3335 5.66675V1.66675H14.3335'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M9.1665 1.66675H7.49984C3.33317 1.66675 1.6665 3.33341 1.6665 7.50008V12.5001C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5001V10.8334'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='flex w-1/2'>
          <img src={SubdomainIcon} alt='Subdomain' className='w-12 h-12' />
          <div className='flex flex-col w-full ml-4'>
            <span className='block mb-1 text-sm font-bold'>
              Landing Page Saya
            </span>
            {!profileData.data?.data.data.subdomain ? (
              <div>
                <span className='block text-sm'>Lihat landing page saya</span>
                <div className='flex flex-row items-center w-full mt-4'>
                  <div className='flex w-82'>
                    <input
                      placeholder=''
                      type='text'
                      name='subdomain'
                      disabled
                      className='w-full px-4 py-2 text-sm border-t border-b border-l rounded-l-lg'
                      // className='px-3 py-2 bg-white border border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm'
                      {...register('landingDomain')}
                    />
                    <div className='w-3/6 px-4 py-2 text-center border rounded-r-lg bg-blue-grey-50'>
                      landing.rintiz.id
                    </div>
                  </div>
                </div>
                <div className='pt-2 text-primary'>{message}</div>
              </div>
            ) : (
              <div>
                <span className='block my-2 text-sm'>
                  Berikut adalah domain landing page Anda{' '}
                </span>
                <div className='flex items-center w-full'>
                  <input
                    type='text'
                    name='subdomain'
                    disabled
                    defaultValue={profile?.subdomain}
                    className='w-full px-4 py-2 text-sm bg-white border-t border-b border-l rounded-l-lg'
                  />
                  <div className='w-3/6 py-2 text-center border rounded-r-lg bg-blue-grey-50'>
                    landing.rintiz.id
                  </div>
                  <div onClick={handleCopyLanding} className='p-2 ml-2 rounded-lg cursor-pointer bg-blue-grey-50'>
                    <svg
                      width='23'
                      height='23'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M13.3332 10.7501V14.2501C13.3332 17.1667 12.1665 18.3334 9.24984 18.3334H5.74984C2.83317 18.3334 1.6665 17.1667 1.6665 14.2501V10.7501C1.6665 7.83341 2.83317 6.66675 5.74984 6.66675H9.24984C12.1665 6.66675 13.3332 7.83341 13.3332 10.7501Z'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M18.3332 5.75008V9.25008C18.3332 12.1667 17.1665 13.3334 14.2498 13.3334H13.3332V10.7501C13.3332 7.83341 12.1665 6.66675 9.24984 6.66675H6.6665V5.75008C6.6665 2.83341 7.83317 1.66675 10.7498 1.66675H14.2498C17.1665 1.66675 18.3332 2.83341 18.3332 5.75008Z'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                  <div onClick={handleOpenLanding} className='p-2 ml-2 rounded-lg cursor-pointer bg-blue-grey-50'>
                    <svg
                      width='23'
                      height='23'
                      viewBox='0 0 21 21'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M10.8335 9.16658L17.6668 2.33325'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M18.3335 5.66675V1.66675H14.3335'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                      <path
                        d='M9.1665 1.66675H7.49984C3.33317 1.66675 1.6665 3.33341 1.6665 7.50008V12.5001C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5001V10.8334'
                        stroke='#71869D'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Subdomain;
