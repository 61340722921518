import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../App';
import Breadcrumb from '../../components/breadcrumbs';
import { Button } from '../../components/buttons/buttons';
import { Radio } from '../../components/forms/radios';
import { CustomToolbarExample } from '../../components/text-editor';
import { doCreateMembershipLevel } from '../../services/api/command/membership.command';

// import { CustomToolbarExample } from "../../../components/text-editor";

function MembershipLevelAdd() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState('');

  const {
    register,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();

  const breadcrumb = [
    { title: 'Level Membership', url: '/membership/level', last: false },
    { title: 'Detil Level', url: '', last: true },
  ];

  const colors = [
    { bg: '#2faec31a', text: '#268D9E'},
    { bg: '#FCF8E3', text: '#AC7A41' },
    { bg: '#F8FAFD', text: '#677788' },
    { bg: '#CCD9F0', text: '#677788' }
  ];

  const data = JSON.parse(localStorage.getItem('membership-level'));

  const handleChangedColor = (color) => {
    setColor(color);
  }

  const handleCancel = () => {
    history.goBack();
  }

  const handleAddMembershipLevel = () => {
    const body = {
      'name': getValues('name'),
      'description': getValues('description'),
      'transaction_duration': parseInt(getValues('transaction_duration')),
      'transaction_minimum': parseInt(getValues('transaction_minimum')),
      'color': color,
      'commission_percentage': parseInt('commision_percentage'),
      'benefit': getValues('benefit'),
      'is_active': parseInt(getValues('is_active'))
    }

    doCreateMembershipLevel().command(body)
      .then((response) => {
        setLoading(false);
        toast.success('Membership Level berhasil ditambahkan');
        queryClient.invalidateQueries('create-membership-level');
        history.goBack();
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <div className='px-32'>
      <Breadcrumb items={breadcrumb} />
      <div className='flex items-center justify-between'>
        <h4 className='py-3 font-bold'>Detil Level</h4>
        <div className='flex items-end'>
          <div>
            <button className='px-4 py-2 text-sm font-bold border rounded-md btn gap-x-2' onClick={handleCancel}>
              Batalkan
            </button>
          </div>
          <div className='ml-4'>
            <Button
              loading={loading}
              text={'Simpan'}
              onClick={handleAddMembershipLevel}
              className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
            />
          </div>
        </div>
      </div>
      {/* Jangka Waktu */}
      <div className='p-5 mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between'>
          <div className='text-base font-bold'>Informasi Umum</div>
          {/* Need Switch Button */}
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Nama Level Membership</label>
            <div className='flex col-span-6'>
              <input
                type='text'
                className='w-full mt-2 text-sm border rounded-lg form-input'
                placeholder='Masukkan nama level membership'
                name='name'
                {...register('name', { required: true })}
              />
            </div>
          </div>
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Warna</label>
            <div className='flex col-span-6'>
              {colors.map((defaultColor) => (
                <Radio 
                  name='color' 
                  label=''
                  message='' 
                  color=''
                  inline 
                  checked={data?.color === defaultColor.bg || color === defaultColor.bg ? true : false}
                  onChange={() => handleChangedColor(defaultColor.bg)}
                  styles={{'color': defaultColor.text, 'background-color': defaultColor.bg}}
                />
              ))}
              {/* <Radio label='' message='' inline color='text-[#50d71e]' />
              <Radio label='' message='' inline color='text-[#50d71e]' /> */}
            </div>
          </div>
        </div>
      </div>
      {/* Lainnya */}
      <div className='pb-2 mt-5 text-base font-semibold'>Deskripsi</div>
      <CustomToolbarExample
        onChange={(e) => setValue('description', e)}
      />
      <div className='p-5 mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between'>
          <div className='text-base font-bold'>Pengaturan Level</div>
          {/* Need Switch Button */}
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Jangka Waktu Penjualan</label>
            <select 
              className='col-span-6 text-sm border rounded-lg form-select' 
              name='transaction_duration'
              {...register('transaction_duration', {required: true})}
            >
              <option selected disabled>
                Pilih Jangka Waktu Penjualan
              </option>
              <option value='30'>1 Bulan</option>
              <option value='90'>3 Bulan</option>
              <option value='180'>6 Bulan</option>
              <option value='360'>12 Bulan</option>
            </select>
          </div>
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>
              Tentukan Nilai Penjualan Selama Periode Berlangsung
            </label>
            <div className='flex col-span-6'>
              <div className='px-5 py-2 font-bold border rounded-l-lg bg-slate-20'>
                Rp
              </div>
              <div className='flex w-full pl-4 border-t border-b border-r rounded-r-lg flex-grow-1 '>
                <input
                  placeholder='Contoh : 10.000.000,-'
                  type='text'
                  name='transaction_minimum'
                  className='block w-full bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 focus:ring-0 sm:text-sm'
                  {...register('transaction_minimum', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='p-5 mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between'>
          <div className='text-base font-bold'>Komisi</div>
          {/* Need Switch Button */}
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Besaran Komisi (Persentase)</label>
            <div className='flex col-span-6'>
              <div className='px-5 py-2 font-bold border rounded-l-lg bg-slate-20'>
                %
              </div>
              <div className='flex w-full pl-4 border-t border-b border-r rounded-r-lg flex-grow-1 '>
                <input
                  placeholder='Masukan besaran komisi'
                  type='text'
                  name='commission_percentage'
                  className='block w-full bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 focus:ring-0 sm:text-sm'
                  {...register('commission_percentage', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Benefit Lain</label>
            <div className='flex col-span-6'>
              <div className='flex w-full py-2 pl-4 border rounded-lg flex-grow-1 '>
                <input
                  placeholder='Masukkan benefit lainnya'
                  type='text'
                  name='benefit'
                  className='block w-full bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 focus:ring-0 sm:text-sm'
                  {...register('benefit', { required: true })}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='p-5 mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between'>
          <div className='text-base font-bold'>Lainnya</div>
          {/* Need Switch Button */}
        </div>
        <div className='mt-10'>
          <div className='grid items-center grid-cols-8 mt-8'>
            <label className='col-span-2'>Status Membership</label>
            <select 
              className='col-span-6 text-sm border rounded-lg form-select'
              {...register('is_active', { required: 'true' })}
            >
              <option value='1'>Aktif</option>
              <option value='0'>Tidak Aktif</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MembershipLevelAdd;
