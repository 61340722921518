import React from "react";

const ShippingLabel = () => {
  return (
    <div className="m-5">
      <div className="flex">
        <img
          src="/rintiz-logo.svg"
          className="mr-2"
          width={"30px"}
          alt="Rintiz Logo"
        />
        <span>
          <img
            src="/rintiz-name.svg"
            style={{ height: "38px" }}
            alt="Rintiz Name Logo"
          />
        </span>
      </div>
      <div className="mt-4">
        Nomor Resi <span className="font-bold"> 856749475674747</span>
      </div>
      <div className="mt-4 flex content-between border rounded-lg">
        <div className="w-full p-5 border-r">
          <div>Penerima</div>
          <div className="mt-3 font-bold">Samantha William</div>
          <div className="mt-3 font-bold">
            Jl. Anggur no.10, Gamping, Sleman, DI Yogyakarta
          </div>
          <div className="mt-3 font-bold">+62 8689-8890</div>
        </div>
        <div className="w-full p-5">
          <div>Pengirim</div>
          <div className="mt-3 font-bold">Glowserum</div>
          <div className="mt-3 font-bold">Metroharmony Sonosewu, Bantul</div>
          <div className="mt-3 font-bold">+62 7856-3376</div>
        </div>
      </div>
      <div className="mt-4 p-5 border rounded-lg">
        <div>Pengiriman</div>
        <div className="mt-3 font-bold">SiCepat</div>
      </div>
      <div className="mt-4 p-5 border rounded-lg">
        <div>Informasi Barang</div>
        <div className="flex w-full">
          <div className="w-full">
            <div>Nama Produk</div>
            <div className="mt-3 font-bold">Glowserum</div>
          </div>
          <div className="flex w-full">
            <div className="w-full">
              <div>Berat</div>
              <div className="mt-3 font-bold">500g</div>
            </div>
            <div className="w-full">
              <div>Ukuran</div>
              <div className="mt-3 font-bold">50cm</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShippingLabel;
