import React from 'react';
import { FiCheckCircle, FiDownload, FiEdit, FiFilter, FiTrash, FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CircularBadge } from '../../../components/badges';
import Datatable from '../../../components/datatable';
import Search from '../../../components/navbar-1/search';
import countries from '../../../json/countries.json';

function ResellerWithdrawal() {
  const history = useHistory();

  const Simple = () => {
    const columns = React.useMemo(
      () => [
        {
          Header: 'Nama Reseller',
          accessor: 'name',
          Cell: (props) => (
            <div className='flex items-center justify-start break-all gap-x-4'>
              <div class="shrink-0 w-8">
                <img src="/assets/faces/w1.png" alt="media" class="h-8 w-full shadow-lg rounded-full ring" />
              </div>
              <div>
                <div className='flex items-center gap-x-2'>
                  <div className='font-bold'>Amanda Harvey</div>
                  <FiCheckCircle className='mr-2 text-blue-600 stroke-current' />
                </div>
                <div className='small text-grey-500'>tim.jennings@example.com</div>
              </div>
            </div>
          ),
        },
        {
          Header: 'No. Handphone',
          accessor: 'phone',
          Cell: (props) => <span>+62 812-3456-7890</span>,
        },
        {
          Header: 'Tanggal',
          accessor: 'date',
          Cell: (props) => (
            <div className='flex items-center justify-start gap-x-8'>
              <div>
                <span>28 April 2022</span>
              </div>
            </div>
          ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: (props) => (
            <div className='inline-flex p-2 text-xs text-center text-green-600 bg-green-100 rounded-lg'>Approved</div>
          ),
        },
      ],
      []
    );
    const data = React.useMemo(() => countries, []);
    return <Datatable checkbox={false} columns={columns} data={data} />;
  };

  return (
    <div>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Reseller Report</h4>
      </div>
      <div className='mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Member' />
          <div className='flex'>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiDownload className='stroke-current text-md' />
              <a href='#' className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'>Export</a>
            </div>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiFilter className='stroke-current text-md' />
              <select
                className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
                // value={pageSize}
                // onChange={(e) => {
                //   setPageSize(Number(e.target.value));
                // }}
              >
                {['Filter', 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span>
                <CircularBadge color='bg-blue-grey-100 text-black'>
                  1
                </CircularBadge>
              </span>
            </div>
          </div>
          
        </div>
        <Simple />
      </div>
    </div>
  );
}

export default ResellerWithdrawal;
