import queryCommander from "../queryCommander";

interface MediaRequest {
  source: string;
  provider: string;
  file: any;
  title: string;
  descriptin: string;
}

export const createMediaRequest = <TMedia = MediaRequest>() => queryCommander<TMedia>({
  method: "POST",
  path: "/media/create",
  queryType: "query",
  authenticated: true
});

export const doPostMedia = () => queryCommander({
  method: "POST",
  path: "/media/create",
  queryType: "query",
  authenticated: false
});
