import React from 'react';
import { FiActivity, FiBook, FiCompass, FiCreditCard, FiHelpCircle, FiHome, FiMonitor, FiShoppingCart, FiTruck, FiUser, FiUsers } from 'react-icons/fi';
import { RiVipCrown2Line } from 'react-icons/ri';

const initialState = [
  {
    title: 'Menu',
    items: [
      {
        url: '/',
        icon: <FiHome size={20} />,
        title: 'Home',
        items: []
      },
      {
        url: '/',
        icon: <FiActivity size={20} />,
        title: 'Produk',
        items: [
          {
            url: '/products',
            title: 'List Produk',
            items: [],
          },
          {
            url: '/coupons',
            title: 'Kupon',
            items: [],
          },
        ],
      },
      {
        url: '/orders',
        icon: <FiShoppingCart size={20} />,
        title: 'Order',
        items: [],
      },
      {
        url: '/categories',
        icon: <FiBook size={20} />,
        title: 'Kategori',
        items: [],
      },
      // {
      //   url: '/',
      //   icon: <FiCreditCard size={20} />,
      //   title: 'Withdrawal',
      //   items: [
      //     {
      //       url: '/withdrawal/history',
      //       title: 'Riwayat Penarikan',
      //       items: [],
      //     },
      //   ],
      // },
      // {
      //   url: '/payment-methods',
      //   icon: <FiCreditCard size={20} />,
      //   title: 'Metode Pembayaran',
      //   items: [],
      // },
      // {
      //   url: '/shipping-providers',
      //   icon: <FiTruck size={20} />,
      //   title: 'Pengiriman',
      //   items: [],
      // },
      // {
      //   url: '/',
      //   icon: <FiUsers size={20} />,
      //   title: 'Memberships',
      //   items: [
      //     {
      //       url: '/memberships',
      //       title: 'List Memberships',
      //       items: []
      //     },
      //     {
      //       url: '/membership/type',
      //       title: 'Tipe Membership',
      //       items: []
      //     },
      //     {
      //       url: '/membership/level',
      //       title: 'Level Membership',
      //       items: []
      //     },
      //     {
      //       url: '/membership/referral-code',
      //       title: 'Referral Code',
      //       items: []
      //     }
      //   ]
      // },
      {
        url: '/profile',
        icon: <FiUser size={20} />,
        title: 'Profil',
        items: [],
      },
      // {
      //   url: '/landings',
      //   icon: <FiMonitor size={20} />,
      //   title: 'Landing Page',
      //   items: [],
      // },
      // {
      //   url: '/addon',
      //   icon: <FiMonitor size={20} />,
      //   title: 'Add-On',
      //   items: []
      // },
      // {
      //   url: '/subscriptions',
      //   icon: <RiVipCrown2Line size={20} />,
      //   title: 'Upgrade Subskripsi',
      //   items: [],
      // },
    ],
  },
];

export default function navigation(state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
