import dayjs from "dayjs";
import React, { useEffect } from "react";
import { FiEdit, FiEye, FiFilter, FiTrash } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import Datatable from "../../components/datatable";
import Search from "../../components/navbar-1/search";
import { UnderlinedTabs } from "../../components/tabs";
import formatPrice from "../../functions/formatPrice";
import countries from "../../json/countries.json";
import { doGetOrderList } from "../../services/api/command/order.command";
import Filter from "./order-filter-modal";

function Orders() {
  const tabs = [
    { index: 0, title: "All", active: true },
    { index: 1, title: "Pending Payment", active: false },
    { index: 3, title: "Paid", active: false },
    { index: 4, title: "Expired", active: false },
  ];

  const Table = () => {
    const history = useHistory();
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const orderList = doGetOrderList().query(
      ["order list", page, pageSize],
      { staleTime: 0 },
      { limit: pageSize, page: page }
    );

    let rows = [];

    if (!orderList.error && orderList.status !== "loading") {
      rows = orderList?.data?.data?.data?.list;
    }

    const handleChangePage = (newPage) => {
      setPage(newPage.selected + 1);
    };

    const columns = React.useMemo(
      () => [
        {
          Header: "Nomor Order",
          accessor: "sales_order_no",
          Cell: (props) => {
            let row = props.row;

            return (
              <div
                onClick={() =>
                  row.original.status_invoice !== ""
                    ? history.push(`/invoices/detail/${props.value}-1`)
                    : history.push(`/orders/detail/${props.value}`)
                }
                className="break-all"
              >
                <div className="font-bold cursor-pointer hover:underline">
                  {props.value}
                </div>
              </div>
            );
          },
        },
        {
          Header: "Tanggal Order",
          accessor: "created_at",
          Cell: (props) => (
            <div className="flex items-center justify-start gap-x-8">
              <div>
                <span>{dayjs(props.value).format("DD/MM/YYYY")}</span>
              </div>
            </div>
          ),
        },
        {
          Header: "Total Harga",
          accessor: "grand_total",
          Cell: (props) => <div className="">{formatPrice(props.value)}</div>,
        },
        {
          Header: "Metode Pembayaran",
          accessor: "payment_code",
          Cell: (props) => <span>{props.value}</span>,
        },
        // {
        //   Header: 'Pengiriman',
        //   accessor: 'aw',
        //   Cell: (props) => <span>SiPecat</span>,
        // },
        {
          Header: "Mitra",
          accessor: "name",
          Cell: (props) => <span>{props.value}</span>,
        },
        {
          Header: "",
          accessor: "action",
          Cell: (props) => {
            let row = props.row;
            let sales_order_no = row.original.sales_order_no;

            return (
              <div className="flex justify-end pr-4 gap-x-4">
                <button
                  className="px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon"
                  onClick={() =>
                    row.original.status_invoice !== ""
                      ? history.push(`/invoices/detail/${sales_order_no}-1`)
                      : history.push(`/orders/detail/${sales_order_no}`)
                  }
                >
                  <FiEye className="mr-2 stroke-current" />
                  <span className="text-sm">Detail</span>
                </button>
              </div>
            );
          },
        },
      ],
      []
    );

    const data = React.useMemo(() => rows, [rows]);
    return (
      <Datatable
        totalPage={orderList?.data?.data.data.total_page}
        columns={columns}
        handleChangePage={handleChangePage}
        data={data}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
  };

  return (
    <div>
      <h4 className="py-3 font-bold">Order</h4>
      <div>
        <div className="flex flex-wrap">
          <div className="w-full">
            <UnderlinedTabs haveChild={false} tabs={tabs} />
          </div>
        </div>
      </div>
      <div className="mt-6 border rounded-lg custom-shadow">
        <div className="flex items-center justify-between py-3">
          <Search placeholder="Cari Kupon" />
          <Filter />
        </div>
        <Table />
      </div>
    </div>
  );
}

export default Orders;
