import axios, { AxiosResponse } from 'axios';

import tokenBroker from './tokenBroker';

export type methodType = 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'GET';

type apiProps<ApiRequest> = {
  method: methodType;
  url: string;
  data?: ApiRequest;
  authenticated?: boolean;
};

const api = <ApiRequest = any, ApiResponse = any>(props: apiProps<ApiRequest>) => {
  let headers = {};
  headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }


  axios.interceptors.request.use(
    async (config: any) => {
      const token = await tokenBroker.get();

      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: any) => {
      throw error;
    },
  );

  switch (props.method) {
    case 'GET':
      return axios.get<ApiRequest, AxiosResponse<ApiResponse>>(props.url, { headers });
    case 'POST':
      return axios.post<ApiRequest, AxiosResponse<ApiResponse>>(props.url, props.data, {
        headers,
      });
    case 'PATCH':
      return axios.patch<ApiRequest, AxiosResponse<ApiResponse>>(props.url, props.data, {
        headers,
      });
    case 'PUT':
      return axios.put<ApiRequest, AxiosResponse<ApiResponse>>(props.url, props.data, {
        headers,
      });
    case 'DELETE':
      return axios.delete<ApiRequest, AxiosResponse<ApiResponse>>(props.url, {
        data: props.data,
        headers,
      });
    default:
      return axios.get<ApiRequest, AxiosResponse<ApiResponse>>(props.url, { headers });
  }
};

export type ApiResponse<T> = {
  status: number;
  message: string;
  validation: any;
  data: T;
};

export type PaginatedRequest = {
  page?: number;
  limit?: number;
};

export type PaginatedResponse<T> = {
  data?: T[];
  meta?: {
    current_page?: number;
    from?: number;
    last_page?: number;
    path?: string;
    per_page?: number;
    to?: number;
    total?: number;
  };
  links?: {
    self?: string;
    first?: string;
    last?: string;
    prev?: null;
    next?: string;
  };
};

export default api;
