import React, { useEffect, useRef } from 'react';
import { FiCopy, FiFacebook, FiInstagram } from 'react-icons/fi';

import Portal from '../../../components/portal';

function ModalEdit({ open, setOpen, children }) {
  const modalRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef, setOpen]);

  return (
    <>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/6 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white rounded-lg px-4 pt-6 pb-4'>
                {children}
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
}

export default ModalEdit;
