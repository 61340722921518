import React from 'react';

function OrderInformation({data}) {
  return (
    <div id='order-information'>
      <div className='p-4 mb-6 border rounded-md shadow-custom'>
        <div className='font-bold text-md'>Informasi Order</div>
        <div className='flex flex-col mt-4 gap-x-10'>
          <table className='w-full text-left capitalize table-auto'>
            <tbody>
              <tr>
                <td className='py-2 font-medium leading-4 w-52 text-grey-500'>
                  Nomor Order
                </td>
                <td className='py-2 whitespace-no-wrap'>{data?.sales_order_no}</td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Tanggal Order
                </td>
                <td className='py-2 whitespace-no-wrap'>{data?.created_at} </td>
              </tr>
              <tr>
                <td className='flex py-2 font-medium leading-4 text-grey-500'>
                  Reseller
                </td>
                <td className='py-2 whitespace-no-wrap'>
                  <div>{data?.agent.name}</div>
                  <div>{data?.agent.phone}</div>
                  <div>{data?.agent.email}</div>
                  {/* <div>Jl. Anggur no.10, Gamping, Sleman, DI Yogyakarta</div> */}
                </td>
              </tr>
              <tr>
                <td className='flex py-2 font-medium leading-4 text-grey-500'>
                  Penerima
                </td>
                <td className='py-2 whitespace-no-wrap'>
                  <div>{data?.shipping.receiver_name}</div>
                  <div>{data?.shipping.receiver_phone}</div>
                  {/* <div>samantha@mail.com</div> */}
                  <div>{data?.shipping.address} {data?.shipping.kecamatan_name} {data?.shipping.city_name} {data?.shipping.province_name} {data?.shipping.postcode}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default OrderInformation;
