import React, { useState } from 'react';
import { formatNumber } from '../../functions/numbers';
import Datatable from '../../components/datatable';
import { FiTrash, FiEdit, FiFilter, FiBox } from 'react-icons/fi';
import Search from '../../components/navbar-1/search';
import { CircularBadge } from '../../components/badges';
import { useHistory } from 'react-router-dom';
import { doGetCouponList } from '../../services/api/command/coupon.command';
import formatPrice from '../../functions/formatPrice';

const Table = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const couponList = doGetCouponList().query(
    ['coupon list', page, pageSize],
    {},
    { limit: pageSize, page: page }
  );
  
  const handleChangePage = (newPage) => {
    setPage(newPage.selected + 1);
  }

  const handleEditCoupon = (coupon_code) => {
    alert('handle edit coupon of ' + coupon_code);
  }

  const handleDeleteCoupon = (coupon_code) => {
    alert('handle delete coupon of ' + coupon_code);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Kode Kupon',
        accessor: 'code',
        Cell: (props) => {
          let row = props.row.index;
          let description = row.description;

          return (
            <div className='w-32 break-all'>
              <div className='font-bold'>{props.value}</div>
              <div className=''>{description}</div>
            </div>
          )
        },
      },
      {
        Header: 'Kategori',
        accessor: 'category',
        Cell: (props) => (
          <div className='flex items-center justify-start gap-x-8'>
            <span className='font-bold text-teal-500'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'Nominal',
        accessor: 'value',
        Cell: (props) => (
          <div className='flex items-center justify-start gap-x-8'>
            <span className='font-bold text-teal-500'>{formatPrice(props.value)}</span>
          </div>
        ),
      },
      {
        Header: 'Jumlah Penggunaan',
        accessor: 'total_used',
        Cell: (props) => {
          let row = props.row.index;
          let total_used = row.extra_meta?.total_used || 0;
          
          return (
            <div className='flex items-center justify-start gap-x-8'>
              <span className='font-bold text-teal-500'>{formatNumber(total_used)}</span>
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: 'coupon_id',
        Cell: (props) => {
          let row = props.row.index;
          let code = row.code;

          return (
            <div className='flex justify-end pr-4 gap-x-4'>
              <button className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon'
                  onClick={() =>
                    history.push("/coupon/edit", { data: props?.row?.original })
                  }
              >
                <FiEdit className='mr-2 stroke-current' />
                <span className='text-sm'>Edit</span>
              </button>
              <button className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon' onClick={() => handleDeleteCoupon(code)}>
                <FiTrash className='mr-2 stroke-current' />
                <span>Hapus</span>
              </button>
            </div>
          )
        },
      },
    ],
    []
  );
  let rows = [];

  if (!couponList.error && couponList.status !== 'loading') {
    rows = couponList.data.data.data.list;
  }

  const data = React.useMemo(() => rows, [rows]);
  
  return (
    <Datatable
      totalPage={couponList?.data?.data.data.total_page}
      columns={columns}
      handleChangePage={handleChangePage}
      data={data}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  )
};

const Coupons = () => {
  const history = useHistory();
  return (
    <>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>List Kupon</h4>
        <div className=''>
          <button className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary' onClick={() => history.push("/coupon/add")}>
            <FiBox className='text-lg stroke-current' />
            Tambah Kupon
          </button>
        </div>
      </div>
      <div className='border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Kupon' />
          <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
            <FiFilter className='stroke-current text-md' />
            <select
              className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
              // value={pageSize}
              // onChange={(e) => {
              //   setPageSize(Number(e.target.value));
              // }}
            >
              {['Filter', 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span>
              <CircularBadge color='bg-blue-grey-100 text-black'>1</CircularBadge>
            </span>
          </div>
        </div>
        <Table />
      </div>
    </>
  );
}

export default Coupons;
