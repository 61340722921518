import queryCommander from "../queryCommander";

interface TProductListRequest {
  limit: number;
  page: number;
  brandowner_id: number;
  status: string;
  warehouse_id: number;
  is_gt: number;
  category_id: number;
  q: string;
}

export const doGetProductList = <TProductList = TProductListRequest>() => queryCommander<TProductList>({
  method: "GET",
  path: "/product/list-product",
  queryType: "query",
  authenticated: true
});
interface TProductCreateRequest {
  name: string;
  description: string;
  distributor_price: number;
  original_price: number;
  selling_price: number;
  discount_percentage: number;
  msrp: number;
  comm_1: number;
  comm_2: number;
  comm_3: number;
  wb_comm_0: number;
  wb_comm_1: number;
  wb_comm_2: number;
  wb_comm_3: number;
  long: number;
  wide: number;
  height: number;
  weight: number;
  volume: number;
  categories: any[];
  status: number;
  variants: any[];
}

export const doProductCreate = <TProductCreate = any>() => queryCommander<TProductCreate>({
  method: "POST",
  path: "/product/create",
  queryType: "query",
  authenticated: true
});

type TProductDetailResponse = {}

export const doGetProductDetail = (sku: string) => queryCommander<TProductDetailResponse>({
  method: "GET",
  path: `/product/detail/${sku}`,
  queryType: "query",
  authenticated: true
})

export const doProductUpdate = <TProductUpdate = any>() => queryCommander<TProductUpdate>({
  method: "PUT",
  path: "/product/update",
  queryType: "query",
  authenticated: true
});

export const getProductTotal = <TProductUpdate = any>() => queryCommander<TProductUpdate>({
  method: "GET",
  path: "/product/total",
  queryType: "query",
  authenticated: true
});

export const doProductUpdateStatus = <TProductUpdateStatus = any>() => queryCommander<TProductUpdateStatus>({
  method: "PUT",
  path: "/product/update-status",
  queryType: "query",
  authenticated: true
});