import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";

import OtpInput from "react-otp-input";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "../../components/buttons/buttons";
import phoneSanity from "../../functions/phoneSanity";
import replacePhone from "../../functions/replacePhone";
import Layout from "../../layouts/centered";
import {
  doRequestForgetPasswordOTP,
  doRequestSubmitOPTForgetPassword,
} from "../../services/api/command/auth.command";
import LoginImage from "./assets/login.jpg";

const OTPForgotPassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [otpValue, setOtpValue] = useState("");
  const [countdown, setCountdown] = useState(60);

  const handleChange = (otp) => setOtpValue(otp);

  useEffect(() => {
    if (countdown === 0) {
      return;
    }

    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  }, [countdown]);

  const handleResendOTP = () => {
    doRequestForgetPasswordOTP()
      .command({
        phone_number: phoneSanity(location.state.phone_number) || "",
      })
      .then(() => {
        setCountdown(60);
      })
      .catch((err) => {
        // setLoading(false);
        toast.error(err.response.data.error.message);
      });
  };

  const handleSubmitOTP = () => {
    setLoading(true);
    doRequestSubmitOPTForgetPassword()
      .command({
        phone_number: phoneSanity(location.state.phone_number) || "",
        otp: otpValue,
      })
      .then(() => {
        setLoading(false);
        history.push({
          pathname: "/new-password",
          state: {
            phone_number: location.state.phone_number,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <Layout className="flex content-between">
      <div className="flex w-full justify-center">
        <div className="w-1/2 my-auto mx-32">
          <div className="flex">
            <img src="/rintiz-logo.svg" style={{ height: "38px" }} alt="Logo" />
          </div>
          <div className="mt-8">
            <div className="text-base">
              Masukkan kode OTP 6 digit yang sudah dikirim ke No.Whatsapp{" "}
              <span className="font-bold">
                {" "}
                {replacePhone(location.state.phone_number) || ""}{" "}
              </span>
            </div>
            <div className="mt-4">Masukkan Verifikasi Kode OTP</div>
            <div className="mt-4 grid grid-cols-6 gap-6">
              <OtpInput
                value={otpValue}
                onChange={handleChange}
                numInputs={6}
                isInputNum
                className="text-center text-sm border-gray-300 rounded-md mr-3 form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 "
              />
            </div>
            <div className="mt-4">
              <Button
                loading={loading}
                onClick={handleSubmitOTP}
                text={"Verifikasi kode OTP"}
                className="btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-primary text-white"
              />
            </div>
            <div className="mt-4 text-center">
              Kirim ulang verifikasi kode OTP{" "}
              {countdown === 0 ? (
                <div
                  onClick={handleResendOTP}
                  className="text-primary cursor-pointer inline"
                >
                  Kirim Ulang
                </div>
              ) : (
                `00:${countdown}`
              )}
            </div>
          </div>
        </div>
      </div>
      <img src={LoginImage} alt="..." className="h-full w-auto" />
    </Layout>
  );
};

export default OTPForgotPassword;
