/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import NumberFormat from "react-number-format";
import toast, { Toaster } from "react-hot-toast";
import TokenBroker from "services/api/tokenBroker";
import axios from "axios";
import { useQuery } from "react-query";

import { CustomToolbarExample } from "../../../components/text-editor";
import Datatable from "../../../components/datatable";
import { Button } from "../../../components/buttons/buttons";
import { getColor } from "../../../functions/colors";
import SwitchComponent from "react-switch";
import Modal from "../../../components/modals/modal";
import Breadcrumb from "../../../components/breadcrumbs";
import { FiPlus, FiImage, FiXCircle } from "react-icons/fi";
import { AiOutlineCar } from "react-icons/ai";
import { TiDocumentText } from "react-icons/ti";
import { GiSellCard } from "react-icons/gi";
import { HiOutlineDotsCircleHorizontal } from "react-icons/hi";
import { doProductCreate } from "../../../services/api/command/product.command";
import { createMediaRequest } from "../../../services/api/command/media.command";
import { getListCategories } from "../../../services/api/command/categories.command";
import { doGetAllCities } from "../../../services/api/command/addresses.command";
import { doGetMembershipList } from "../../../services/api/command/membership.command";

import "../product.scss";

const HandleSwitch = (props) => {
  let offColor = `bg-blue-grey-100`;
  let offHandleColor = "bg-blue-grey-200";

  return (
    <div className="flex items-center mt-4">
      <SwitchComponent
        onChange={() => props.onChange()}
        checked={props.status}
        onColor="#B1D5DB"
        onHandleColor="#2FAEC3"
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={12}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        height={8}
        width={38}
        className="react-switch "
      />
      <span className="pl-3">Dapat dilihat oleh reseller</span>
    </div>
  );
};

const Create = () => {
  const history = useHistory();
  const token = TokenBroker.get();
  let categoryList = getListCategories().query(
    ["category list"],
    {},
    { limit: 100, page: 1 }
  );
  const destination = [
    {
      value: 0,
      label: "Semua Lokasi",
    },
    {
      value: 1,
      label: "Hanya Kota/Kabupaten",
    },
  ];
  let citiesList = doGetAllCities().query(["citiesList"]);
  let membershipList = doGetMembershipList().query(
    ["membershipList"],
    {},
    { limit: 1000, page: 1 }
  );
  const [image, setImage] = useState([]);
  const [showMarketPrice, setShowMarketPrice] = useState(false);
  const [showResellerCommision, setShowResellerCommision] = useState(false);
  const [loading, setLoading] = useState(false);
  const [variant, setVariant] = useState([]);
  const [variantType, setVariantType] = useState([]);
  const [countingType, setCountingType] = useState([[]]);
  const [typeDestination, setTypeDestination] = useState(0);

  if (categoryList.isSuccess) {
    let newArr = [];
    // eslint-disable-next-line no-unused-expressions
    categoryList.data?.data?.data?.list.map((element) => {
      newArr.push({
        value: element.category_id,
        label: element.name,
      });
    });
    categoryList = newArr;
  }

  if (citiesList.isSuccess) {
    let newArr = [];
    // eslint-disable-next-line no-unused-expressions
    (citiesList.data?.data?.data?.cities || []).map((element) => {
      newArr.push({
        value: element.id,
        label: element.name,
      });
    });
    citiesList = newArr;
  }

  if (membershipList.isSuccess) {
    let newArr = [];
    // eslint-disable-next-line no-unused-expressions
    membershipList.data?.data?.data?.list.map((element) => {
      newArr.push({
        value: element.agent_id,
        label: element.name,
      });
    });
    membershipList = newArr;
  }

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    control,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setValue("foto-utama-0", "on");
  }, []);

  const handleMainPhoto = (index) => {
    for (let i = 0; i <= 3; i++) {
      if (i === index) {
        setValue(`foto-utama-${image}`, "on");
      } else {
        setValue(`foto-utama-${i}`, null);
      }
    }
  };

  const dataSatuan = [
    {
      value: "set",
      label: "Set",
    },
    {
      value: "pcs",
      label: "Pcs",
    },
    {
      value: "kg",
      label: "Kilogram",
    },
    {
      value: "gram",
      label: "Gram",
    },
    {
      value: "liter",
      label: "Liter",
    },
    {
      value: "ml",
      label: "Ml",
    },
    {
      value: "box",
      label: "Box",
    },
    {
      value: "krat",
      label: "Krat",
    },
    {
      value: "pak",
      label: "Pak",
    },
    {
      value: "ekor",
      label: "Ekor",
    },
  ];

  const customStyle = {
    control: (provided, state) => ({
      ...provided,
      padding: "10px",
      borderRadius: "0.375rem",
      border: "1px solid #e2e8f0",
      outline: "none",
    }),
  };

  const breadcrumbs = [
    { title: "List Produk", url: "/products", last: false },
    { title: `Tambah Produk`, url: "", last: true },
  ];

  const extractVisibilitasData = () => {
    const visibilitas = [];

    (getValues("visibilitas") || []).forEach((element) => {
      visibilitas.push(element.value);
    });
    return visibilitas;
  };

  const extractCategoryData = () => {
    const categories = [];

    (getValues("categories") || []).forEach((element) => {
      categories.push(element.value);
    });
    return categories;
  };

  const submit = () => {
    setLoading(true);
    if (image.length === 0) {
      handleCreate([]);
    } else {
      handlePostMedia();
    }
  };

  const handlePostMedia = async () => {
    const newArr = [];
    let i = 0;
    while (i < image.length) {
      var formdata = new FormData();
      formdata.append("source", "upload");
      formdata.append("provider", "cloudinary");
      formdata.append("file", image[i].data);
      formdata.append("title", image[i].name);
      formdata.append("description", image[i].name);
      let statusImage = getValues(`foto-utama-${i}`);
      if (statusImage === "on") {
        statusImage = 10;
      } else {
        statusImage = 0;
      }
      await createMediaRequest()
        .command(formdata)
        // eslint-disable-next-line no-loop-func
        .then((res) => {
          let dummyURL = res.data.data.public_url.split(
            "https://res.cloudinary.com/rintiz/image/upload"
          );
          newArr.push({
            image_url: dummyURL[1],
            angle: 10,
            status: statusImage,
          });
          i++;
        });
    }
    if (i === image.length) {
      setTimeout(() => {
        handleCreate(newArr);
      }, 3000);
    }
  };

  const extractVariant = (images) => {
    const dummyVariant = [];

    variantType?.map((item, index) => {
      const dummy = {
        satuan: getValues(`satuan-${index}.value`),
        stock: parseInt(getValues(`stock-${index}`)),
        //
        sku_brandowner: "0",
        priority: 0,
        sku: "",
        option1: item?.option1,
        option2: item?.option2,
        option3: item?.option3,
        has_weekly_bonus: 1,
        is_default: 0,
        is_set: 0,
        is_gps: 0,
        gps_type: "",
        status: 10,
        is_in_stock: 1,
        //
        prices: [
          {
            distributor_price: parseInt(
              getValues(`distributor_price-${index}`)
            ),
            original_price: parseInt(getValues(`original_price-${index}`)),
            selling_price: parseInt(getValues(`selling_price-${index}`)),
            discount_percentage: parseInt(
              getValues(`discount_percentage-${index}`)
            ),
            msrp: parseInt(getValues(`msrp-${index}`)),
          },
        ],
        images,
        commission: {
          comm_1: parseInt(getValues(`comm-${index}`)),
          comm_2: parseInt(getValues(`comm-${index}`)),
          comm_3: parseInt(getValues(`comm-${index}`)),
          wb_comm_0: parseInt(getValues(`comm-${index}`)),
          wb_comm_1: parseInt(getValues(`comm-${index}`)),
          wb_comm_2: parseInt(getValues(`comm-${index}`)),
          wb_comm_3: parseInt(getValues(`comm-${index}`)),
        },
      };
      dummyVariant.push(dummy);
    });
    return dummyVariant;
  };

  const extractCity = () => {
    const city = [];
    (getValues("citySelect") || []).forEach((element) => {
      city.push(element.value);
    });
    return city;
  };

  const handleCreate = async (images) => {
    let body = null;
    let allowedCity = [];
    if (typeDestination !== 0) {
      allowedCity = extractCity();
    }
    if (variantType.length > 0) {
      body = {
        name: getValues("name"),
        description: getValues("description"),
        status: parseInt(getValues("status")),
        categories: extractCategoryData(),
        variants: extractVariant(images),
        shipping_information: {
          length: parseInt(getValues("length")),
          width: parseInt(getValues("width")),
          height: parseInt(getValues("height")),
          weight: parseFloat(getValues("weight")),
          // volume: parseInt(getValues('volume')),
          weight_set: 1.0,
          is_free_ongkir: 0,
          is_cod: 1,
          allowed_city_cod: allowedCity,
        },
        //
        qty_set: 11,
        is_variant: 1,
        is_in_stock: 1,
        is_fragile: 0,
        is_gt: 0,
        allowed_users: extractVisibilitasData(),
        allowed_shipping_range_province: "",
        allowed_shipping_range_city: allowedCity.join(),
        allowed_shipping_range_district: "",
        disalowed_shipping_range_province: "",
        disalowed_shipping_range_city: "",
        disalowed_shipping_range_district: "",
        variant_options: variant,
        //
      };
    } else {
      body = {
        name: getValues("name"),
        description: getValues("description"),
        status: parseInt(getValues("status")),
        categories: extractCategoryData(),
        variants: [
          {
            satuan: getValues("satuan").value,
            stock: parseInt(getValues("stock")),
            //
            sku_brandowner: "0",
            priority: 0,
            sku: "",
            option1: "",
            option2: "",
            option3: "",
            has_weekly_bonus: 1,
            is_default: 0,
            is_set: 0,
            is_gps: 0,
            gps_type: "",
            status: 10,
            is_in_stock: 1,
            //
            prices: [
              {
                distributor_price: parseInt(getValues("distributor_price")),
                original_price: parseInt(getValues("original_price")),
                selling_price: parseInt(getValues("selling_price")),
                discount_percentage: parseInt(getValues("discount_percentage")),
                msrp: parseInt(getValues("msrp")),
              },
            ],
            images,
            commission: {
              comm_1: parseInt(getValues("comm")),
              comm_2: parseInt(getValues("comm")),
              comm_3: parseInt(getValues("comm")),
              wb_comm_0: parseInt(getValues("comm")),
              wb_comm_1: parseInt(getValues("comm")),
              wb_comm_2: parseInt(getValues("comm")),
              wb_comm_3: parseInt(getValues("comm")),
            },
          },
        ],
        shipping_information: {
          length: parseInt(getValues("length")),
          width: parseInt(getValues("width")),
          height: parseInt(getValues("height")),
          weight: parseFloat(getValues("weight")),
          // volume: parseInt(getValues('volume')),
          weight_set: 1.0,
          is_free_ongkir: 0,
          is_cod: 1,
          allowed_city_cod: allowedCity,
        },
        //
        qty_set: 11,
        is_variant: 0,
        is_in_stock: 1,
        is_fragile: 0,
        is_gt: 0,
        allowed_users: extractVisibilitasData(),
        allowed_shipping_range_province: "",
        allowed_shipping_range_city: allowedCity.join(),
        allowed_shipping_range_district: "",
        disalowed_shipping_range_province: "",
        disalowed_shipping_range_city: "",
        disalowed_shipping_range_district: "",
        variant_options: [],
        //
      };
    }
    doProductCreate()
      .command(body)
      .then((res) => {
        toast.success("Berhasil disimpan, halamanmu akan dialihkan");
        reset();
        setTimeout(() => {
          history.push("/products");
        }, 5000);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Gagal menyimpan");
      });
  };

  const handleImage = (e, name) => {
    var upload = URL.createObjectURL(e);
    var result = {
      name,
      data: e,
      preview: upload,
    };
    let dummy = [...image];
    let found = dummy.findIndex((e) => e.name === name);
    if (dummy.length > 0) {
      if (found < 0) {
        setImage((oldFiles) => [...oldFiles, result]);
      } else {
        dummy[found] = result;
        setImage(dummy);
      }
    } else {
      setImage((oldFiles) => [...oldFiles, result]);
    }
  };

  const handleDiscount = () => {
    const original_price = parseInt(getValues("original_price"));
    const discount_percentage = parseInt(getValues("discount_percentage"));
    if (original_price !== null && discount_percentage !== null) {
      setValue(
        "selling_price",
        original_price - (original_price * discount_percentage) / 100
      );
    }
  };

  const handleDiscountVariant = (index) => {
    const original_price = parseInt(getValues(`original_price-${index}`));
    const discount_percentage = parseInt(
      getValues(`discount_percentage-${index}`)
    );
    if (original_price !== null && discount_percentage !== null) {
      setValue(
        `selling_price-${index}`,
        original_price - (original_price * discount_percentage) / 100
      );
    }
  };

  const handleVolume = () => {
    const length = parseInt(getValues("length"));
    const width = parseInt(getValues("width"));
    const height = parseInt(getValues("height"));
    if (length !== null && width !== null && height !== null) {
      setValue("volume", length * width * height);
    }
  };

  const extractTypeVariant = () => {
    const data = [];

    for (let i = 0; i < 3; i++) {
      if (
        getValues(`typeVariant-${i}`) !== undefined &&
        getValues(`typeVariant-${i}`) !== ""
      ) {
        const dummy = {
          name: getValues(`typeVariant-${i}`),
          value: getValues(`typeVariant-${i}`),
          status: 10,
        };
        data.push(dummy);
      }
    }
    const dummyVariantType = [];
    if (variantType.length > 0) {
      variantType.map((item) => {
        data.map((itemData) => {
          if (variant.length === 1) {
            const dummy = {
              ...item,
              option2: itemData.name,
            };
            dummyVariantType.push(dummy);
          }
          if (variant.length === 2) {
            const dummy = {
              ...item,
              option3: itemData.name,
            };
            dummyVariantType.push(dummy);
          }
        });
      });
    } else {
      data?.map((itemData) => {
        const dummy = {
          ...itemData,
          option1: itemData.name,
        };
        dummyVariantType.push(dummy);
      });
    }
    setVariantType(dummyVariantType);
    // setVariantType((oldVariant) => [...oldVariant, dummyVariantType]);
    for (let i = 0; i < 3; i++) {
      setValue(`typeVariant-${i}`, undefined);
    }
    return data;
  };

  const handleVariant = () => {
    const typeVariant = extractTypeVariant();
    const newVariant = {
      name: getValues(`nameVariant`),
      position: variant.length + 1,
      values: typeVariant,
    };
    setVariant((oldVariant) => [...oldVariant, newVariant]);
    setValue("nameVariant", "");
    setCountingType([[]]);
  };

  const handleCountingType = () => {
    setCountingType((oldTypeVariant) => [...oldTypeVariant, []]);
  };

  const handleDeleteVariant = (index) => {
    const dummyType = [...variantType];
    dummyType.splice(index, 1);
    setVariantType(dummyType);
  };

  const handleTypeDestination = (data) => {
    setTypeDestination(data.value);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Varian",
        Cell: (props) => {
          return (
            <div>
              {props?.row.original.option1} {props?.row.original.option2}{" "}
              {props?.row.original.option3}
            </div>
          );
        },
      },
      {
        Header: "Unit",
        Cell: (props) => {
          return (
            <div className="w-64">
              <Controller
                control={control}
                name={`satuan-${props.row.index}`}
                render={({ field: { onChange, name, value } }) => (
                  <Select
                    name={name}
                    value={value}
                    onChange={onChange}
                    className="basic-multi-select"
                    placeholder="Pilih Satuan"
                    options={dataSatuan}
                    styles={customStyle}
                    getInputRef={register(`satuan-${props.row.index}`, {
                      required: {
                        value: true,
                        message: "Wajib Diisi",
                      },
                    })}
                  />
                )}
              />
              {errors[`satuan-${props.row.index}`] &&
                errors[`satuan-${props.row.index}`].type === "required" && (
                  <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                )}
            </div>
          );
        },
      },
      {
        Header: "Harga",
        Cell: (props) => {
          return (
            <div className="w-128">
              {/* Harga Jual */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">Harga Jual*</label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      Rp.
                    </div>
                    <Controller
                      control={control}
                      name={`original_price-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(
                            `original_price-${props.row.index}`,
                            {
                              required: {
                                value: true,
                                message: "Wajib Diisi",
                              },
                              min: {
                                value: 0,
                                message: "Angka tidak boleh minus",
                              },
                            }
                          )}
                          placeholder="Masukan Harga Jual"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors[`original_price-${props.row.index}`] &&
                    errors[`original_price-${props.row.index}`].type ===
                      "required" && (
                      <p className="mt-2 text-red-500">
                        *{errors[`original_price-${props.row.index}`].message}
                      </p>
                    )}
                </div>
              </div>
              {/* Diskon */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">Diskon*</label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      %
                    </div>
                    <Controller
                      control={control}
                      name={`discount_percentage-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(
                            `discount_percentage-${props.row.index}`,
                            {
                              required: {
                                value: true,
                                message: "Wajib Diisi",
                              },
                              min: {
                                value: 0,
                                message: "Angka tidak boleh minus",
                              },
                              max: {
                                value: 100,
                                message: "Angka maksimal 100",
                              },
                            }
                          )}
                          placeholder="Masukan Diskon"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors[`discount_percentage-${props.row.index}`] &&
                    errors[`discount_percentage-${props.row.index}`].type ===
                      "required" && (
                      <p className="mt-2 text-red-500">
                        *
                        {
                          errors[`discount_percentage-${props.row.index}`]
                            .message
                        }
                      </p>
                    )}
                </div>
              </div>
              {/* Harga Jual Setelah Diskon */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">
                  Harga Jual Setelah Diskon*
                </label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      Rp.
                    </div>
                    <Controller
                      control={control}
                      name={`selling_price-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(
                            `selling_price-${props.row.index}`,
                            {
                              required: {
                                value: true,
                                message: "Wajib Diisi",
                              },
                              min: {
                                value: 0,
                                message: "Angka tidak boleh minus",
                              },
                            }
                          )}
                          placeholder="Masukan Harga Jual Setelah Diskon"
                          value={value}
                          disabled
                        />
                      )}
                    />
                  </div>
                  {errors[`selling_price-${props.row.index}`] &&
                    errors[`selling_price-${props.row.index}`].type ===
                      "required" && (
                      <p className="mt-2 text-red-500">
                        *{errors[`selling_price-${props.row.index}`].message}
                      </p>
                    )}
                </div>
              </div>
              {/* Harga Pasar */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">Harga Pasar*</label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      Rp.
                    </div>
                    <Controller
                      control={control}
                      name={`msrp-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(`msrp-${props.row.index}`, {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Harga Pasar"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors[`msrp-${props.row.index}`] &&
                    errors[`msrp-${props.row.index}`].type === "required" && (
                      <p className="mt-2 text-red-500">
                        *{errors[`msrp-${props.row.index}`].message}
                      </p>
                    )}
                </div>
              </div>
              {/* Harga Modal */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">Harga Modal*</label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      Rp.
                    </div>
                    <Controller
                      control={control}
                      name={`distributor_price-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(
                            `distributor_price-${props.row.index}`,
                            {
                              required: {
                                value: true,
                                message: "Wajib Diisi",
                              },
                              min: {
                                value: 0,
                                message: "Angka tidak boleh minus",
                              },
                            }
                          )}
                          placeholder="Masukan Harga Modal"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors[`distributor_price-${props.row.index}`] &&
                    errors[`distributor_price-${props.row.index}`].type ===
                      "required" && (
                      <p className="mt-2 text-red-500">
                        *
                        {errors[`distributor_price-${props.row.index}`].message}
                      </p>
                    )}
                </div>
              </div>
              {/* Komisi */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-3 text-xs">Komisi*</label>
                <div className="col-span-5">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      Rp.
                    </div>
                    <Controller
                      control={control}
                      name={`comm-${props.row.index}`}
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleDiscountVariant(props.row.index);
                          }}
                          name={name}
                          getInputRef={register(`comm-${props.row.index}`, {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Komisi"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors[`comm-${props.row.index}`] &&
                    errors[`comm-${props.row.index}`].type === "required" && (
                      <p className="mt-2 text-red-500">
                        *{errors[`comm-${props.row.index}`].message}
                      </p>
                    )}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Stok",
        Cell: (props) => {
          return (
            <div className="w-64">
              <Controller
                control={control}
                name={`stock-${props.row.index}`}
                render={({ field: { onChange, name, value } }) => (
                  <NumberFormat
                    className="w-full text-sm border rounded-r-lg form-input"
                    onChange={onChange}
                    name={name}
                    getInputRef={register(`stock-${props.row.index}`, {
                      required: {
                        value: true,
                        message: "Wajib Diisi",
                      },
                      min: {
                        value: 0,
                        message: "Angka tidak boleh minus",
                      },
                    })}
                    placeholder="Masukkan stok"
                    value={value}
                  />
                )}
              />
              {errors[`stock-${props.row.index}`] &&
                errors[`stock-${props.row.index}`].type === "required" && (
                  <p className="mt-2 text-red-500">
                    *{errors[`stock-${props.row.index}`].message}
                  </p>
                )}
            </div>
          );
        },
      },
      {
        Header: "Aksi",
        Cell: (props) => {
          return (
            <button
              type="button"
              className="flex items-center text-sm rounded-md btn gap-x-2 bordertext-black"
              onClick={() => handleDeleteVariant(props.row.index)}
            >
              <FiXCircle className="text-lg stroke-current" />
            </button>
          );
        },
      },
    ],
    [
      control,
      customStyle,
      dataSatuan,
      errors,
      handleDeleteVariant,
      handleDiscountVariant,
      register,
    ]
  );

  return (
    <div className="px-20">
      <Breadcrumb items={breadcrumbs} />
      <Toaster />
      <form>
        <div className="flex items-center justify-between">
          <h4 className="py-3 font-bold">Tambah Produk</h4>
          <div className="flex items-end">
            <div>
              <button
                type="button"
                className="px-4 py-2 mt-8 text-sm font-bold border rounded-md btn gap-x-2"
                onClick={() => history.push("/products")}
              >
                Batalkan
              </button>
            </div>
            <div className="ml-4">
              <Button
                loading={loading}
                onClick={handleSubmit(submit)}
                text={"Simpan"}
                className="px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-8 mt-6">
          <div className="col-span-2 px-4">
            <div className="w-full p-4 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800">
              <div className="flex flex-col space-y-6">
                <a href="#add" className="flex flex-row">
                  <TiDocumentText className="mr-2 text-lg stroke-current" />
                  <span>Informasi Umum</span>
                </a>
                <a href="#sales" className="flex flex-row">
                  <GiSellCard className="mr-2 text-lg stroke-current" />
                  <span>Informasi Penjualan</span>
                </a>
                <a href="#delivery" className="flex flex-row">
                  <AiOutlineCar className="mr-2 text-lg stroke-current" />
                  <span>Pengiriman</span>
                </a>
                <a href="#other" className="flex flex-row">
                  <HiOutlineDotsCircleHorizontal className="mr-2 text-lg stroke-current" />
                  <span>Lainnya</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-span-6 px-4">
            {/* Informasi Umum */}
            <div className="p-4 rounded-lg shadow" id="add">
              <div className="text-base font-bold">Informasi Umum</div>
              {/* Nama Produk */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Nama Produk*</label>
                <div className="col-span-6 ">
                  <input
                    type="text"
                    className="w-full text-sm border rounded-lg form-input"
                    placeholder="Masukkan nama produk"
                    {...register("name", {
                      required: true,
                    })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                  )}
                </div>
              </div>
              {/* Deskiripsi Produk */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Deskripsi Produk*</label>
                <div className="col-span-6">
                  <CustomToolbarExample
                    value=""
                    onChange={(e) => setValue("description", e)}
                  />
                </div>
              </div>
              {/* Foto Produk */}
              <div className="grid grid-cols-8 mt-8 mb-16">
                <label className="col-span-2">Foto Produk</label>
                <div className="grid grid-cols-4 col-span-6">
                  <div className="add-product__form-image-content">
                    <label
                      className="add-product__form-image-content-review"
                      style={{
                        backgroundImage: `url(${image[0]?.preview})`,
                      }}
                    >
                      {image[0] == null && (
                        <>
                          <FiImage className="text-lg stroke-current" />
                          <p className="mt-3 text-center">Upload</p>
                        </>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e.target.files[0], "image-0");
                        }}
                      />
                    </label>
                    <div className="add-product__form-image-content-radio">
                      <label>
                        <input
                          className="mr-2"
                          type="radio"
                          {...register(`foto-utama-0`)}
                          onChange={() => handleMainPhoto(0)}
                        />
                        Foto Utama
                      </label>
                    </div>
                  </div>
                  <div className="add-product__form-image-content">
                    <label
                      className="add-product__form-image-content-review"
                      style={{
                        backgroundImage: `url(${image[1]?.preview})`,
                      }}
                    >
                      {image[1] == null && (
                        <>
                          <FiImage className="text-lg stroke-current" />
                          <p className="mt-3 text-center">Upload</p>
                        </>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e.target.files[0], "image-1");
                        }}
                      />
                    </label>
                    <div className="add-product__form-image-content-radio">
                      <label>
                        <input
                          className="mr-2"
                          type="radio"
                          {...register(`foto-utama-1`)}
                          onChange={() => handleMainPhoto(1)}
                        />
                        Foto Utama
                      </label>
                    </div>
                  </div>
                  <div className="add-product__form-image-content">
                    <label
                      className="add-product__form-image-content-review"
                      style={{
                        backgroundImage: `url(${image[2]?.preview})`,
                      }}
                    >
                      {image[2] == null && (
                        <>
                          <FiImage className="text-lg stroke-current" />
                          <p className="mt-3 text-center">Upload</p>
                        </>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e.target.files[0], "image-2");
                        }}
                      />
                    </label>
                    <div className="add-product__form-image-content-radio">
                      <label>
                        <input
                          className="mr-2"
                          type="radio"
                          {...register(`foto-utama-2`)}
                          onChange={() => handleMainPhoto(2)}
                        />
                        Foto Utama
                      </label>
                    </div>
                  </div>
                  <div className="add-product__form-image-content">
                    <label
                      className="add-product__form-image-content-review"
                      style={{
                        backgroundImage: `url(${image[3]?.preview})`,
                      }}
                    >
                      {image[3] == null && (
                        <>
                          <FiImage className="text-lg stroke-current" />
                          <p className="mt-3 text-center">Upload</p>
                        </>
                      )}
                      <input
                        type="file"
                        className="hidden"
                        accept="image/*"
                        onChange={(e) => {
                          handleImage(e.target.files[0], "image-3");
                        }}
                      />
                    </label>
                    <div className="add-product__form-image-content-radio">
                      <label>
                        <input
                          className="mr-2"
                          type="radio"
                          {...register(`foto-utama-3`)}
                          onChange={() => handleMainPhoto(3)}
                        />
                        Foto Utama
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {/* Satuan Produk */}
              {variantType.length === 0 && (
                <div className="grid items-center grid-cols-8 mt-8">
                  <label className="col-span-2">Satuan Produk*</label>
                  <div className="col-span-6 ">
                    <Controller
                      control={control}
                      name={`satuan`}
                      render={({ field: { onChange, name, value } }) => (
                        <Select
                          name={name}
                          value={value}
                          onChange={onChange}
                          className="basic-multi-select"
                          placeholder="Pilih Satuan"
                          options={dataSatuan}
                          styles={customStyle}
                          getInputRef={register(`satuan`, {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                          })}
                        />
                      )}
                    />
                    {errors[`satuan`] &&
                      errors[`satuan`].type === "required" && (
                        <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                      )}
                  </div>
                </div>
              )}
              {/* Kategori */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Kategori*</label>
                <div className="col-span-6 ">
                  <Controller
                    control={control}
                    name="categories"
                    render={({ field: { onChange, name, value } }) => (
                      <Select
                        isMulti
                        name={name}
                        value={value}
                        onChange={onChange}
                        className="basic-multi-select"
                        placeholder="Pilih Kategori"
                        options={categoryList}
                        styles={customStyle}
                      />
                    )}
                  />
                  {errors.categories &&
                    errors.categories.type === "required" && (
                      <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                    )}
                </div>
              </div>
            </div>
            {/* Informasi Penjualan */}
            <div className="p-4 mt-4 rounded-lg shadow" id="sales">
              <div className="mb-4 text-base font-bold">
                Informasi Penjualan
              </div>
              {variant.length < 3 && (
                <Modal
                  modalName={
                    <button
                      type="button"
                      className="flex items-center px-4 py-2 mt-3 text-sm text-black border rounded-md rounded-lg btn gap-x-2"
                    >
                      <FiPlus className="text-lg stroke-current" /> Varian
                      Produk
                    </button>
                  }
                  title="Variant Produk"
                  body={
                    <div>
                      <div className="grid items-center grid-cols-8 mt-8">
                        <label className="col-span-2">Nama Varian</label>
                        <input
                          type="text"
                          className="col-span-6 text-sm border rounded-lg form-input"
                          placeholder="Misalnya : Warna, Ukuran, Seri"
                          {...register(`nameVariant`)}
                        />
                      </div>
                      <div className="grid items-start grid-cols-8 mt-8">
                        <label className="col-span-2">Jenis Varian</label>
                        <div className="col-span-6 ">
                          {countingType?.map((item, index) => {
                            return (
                              <input
                                type="text"
                                className="w-full mb-4 text-sm border rounded-lg form-input"
                                placeholder="Misalnya: Merah, XL"
                                {...register(`typeVariant-${index}`)}
                              />
                            );
                          })}
                          {countingType.length < 3 && (
                            <button
                              className="flex px-4 py-2 text-sm font-bold border rounded-md btn gap-x-2"
                              type="button"
                              onClick={handleCountingType}
                            >
                              <FiPlus className="text-lg stroke-current" />
                              Jenis
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  }
                  buttonSubmit={() => handleVariant()}
                  buttonLeft="Simpan & Kembali"
                  buttonClassLeft="btn btn-default btn-rounded border"
                  buttonRight="Simpan Varian Baru"
                  buttonClassRight="btn btn-default btn-rounded bg-primary text-white"
                  showButtom
                />
              )}
              {variant.length < 1 ? (
                <>
                  {/* Stock */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Stok*</label>
                    <div className="col-span-6 ">
                      <Controller
                        control={control}
                        name="stock"
                        render={({ field: { onChange, name, value } }) => (
                          <NumberFormat
                            className="w-full text-sm border rounded-r-lg form-input"
                            onChange={onChange}
                            name={name}
                            getInputRef={register("stock", {
                              required: {
                                value: true,
                                message: "Wajib Diisi",
                              },
                              min: {
                                value: 0,
                                message: "Angka tidak boleh minus",
                              },
                            })}
                            placeholder="Masukkan stok"
                            value={value}
                          />
                        )}
                      />
                      {errors?.stock?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.stock?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Harga Modal */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Harga Modal*</label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          Rp.
                        </div>
                        <Controller
                          control={control}
                          name="distributor_price"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={onChange}
                              name={name}
                              getInputRef={register("distributor_price", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                              })}
                              placeholder="Masukan Harga Modal"
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {errors?.distributor_price?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.distributor_price?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Harga Pasar */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Harga Pasar*</label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          Rp.
                        </div>
                        <Controller
                          control={control}
                          name="msrp"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={onChange}
                              name={name}
                              getInputRef={register("msrp", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                              })}
                              placeholder="Masukan Harga Pasar"
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {errors?.msrp?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.msrp?.message}
                        </p>
                      )}
                      <HandleSwitch
                        status={showMarketPrice}
                        onChange={() => setShowMarketPrice(!showMarketPrice)}
                      />
                    </div>
                  </div>
                  {/* Harga Jual */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Harga Jual*</label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          Rp.
                        </div>
                        <Controller
                          control={control}
                          name="original_price"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleDiscount();
                              }}
                              name={name}
                              getInputRef={register("original_price", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                              })}
                              placeholder="Masukan Harga Jual"
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {errors?.original_price?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.original_price?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Presentase Diskon */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Presentase Diskon*</label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          %
                        </div>
                        <Controller
                          control={control}
                          name="discount_percentage"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={(e) => {
                                onChange(e.target.value);
                                handleDiscount();
                              }}
                              name={name}
                              getInputRef={register("discount_percentage", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                                max: {
                                  value: 100,
                                  message: "Angka maksimal 100",
                                },
                              })}
                              placeholder="Masukan Presentase Diskon"
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {errors?.discount_percentage?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.discount_percentage?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Harga Jual Setelah Diskon */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">
                      Harga Jual Setelah Diskon*
                    </label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          Rp.
                        </div>
                        <Controller
                          control={control}
                          name="selling_price"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={onChange}
                              name={name}
                              getInputRef={register("selling_price", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                              })}
                              placeholder="Masukan Harga Jual Setelah Diskon"
                              value={value}
                              disabled
                              style={{
                                background: "#E0E0E0",
                              }}
                            />
                          )}
                        />
                      </div>
                      {errors?.selling_price?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.selling_price?.message}
                        </p>
                      )}
                    </div>
                  </div>
                  {/* Komisi Reseler */}
                  <div className="grid items-center grid-cols-8 mt-8">
                    <label className="col-span-2">Komisi Reseler*</label>
                    <div className="col-span-6">
                      <div className="flex w-full">
                        <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                          Rp.
                        </div>
                        <Controller
                          control={control}
                          name="comm"
                          render={({ field: { onChange, name, value } }) => (
                            <NumberFormat
                              className="w-full text-sm border rounded-r-lg form-input"
                              onChange={onChange}
                              name={name}
                              getInputRef={register("comm", {
                                required: {
                                  value: true,
                                  message: "Wajib Diisi",
                                },
                                min: {
                                  value: 0,
                                  message: "Angka tidak boleh minus",
                                },
                              })}
                              placeholder="Masukan Komisi Reseler"
                              value={value}
                            />
                          )}
                        />
                      </div>
                      {errors?.comm?.message && (
                        <p className="mt-2 text-red-500">
                          *{errors?.comm?.message}
                        </p>
                      )}
                      <HandleSwitch
                        status={showResellerCommision}
                        onChange={() =>
                          setShowResellerCommision(!showResellerCommision)
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    maxWidth: "100%",
                    overflow: "scroll",
                  }}
                  className="px-4 mt-4"
                >
                  <Datatable
                    columns={columns}
                    data={variantType}
                    checkbox={false}
                    disablePagination
                  />
                </div>
              )}
            </div>
            {/* Informasi Pengiriman */}
            <div className="p-4 mt-4 rounded-lg shadow" id="delivery">
              <div className="text-base font-bold">Informasi Pengiriman</div>
              {/* Panjang */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Panjang*</label>
                <div className="col-span-6">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      cm
                    </div>
                    <Controller
                      control={control}
                      name="length"
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleVolume();
                          }}
                          name={name}
                          getInputRef={register("length", {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Panjang"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors?.length?.message && (
                    <p className="mt-2 text-red-500">
                      *{errors?.length?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Lebar */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Lebar*</label>
                <div className="col-span-6">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      cm
                    </div>
                    <Controller
                      control={control}
                      name="width"
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleVolume();
                          }}
                          name={name}
                          getInputRef={register("width", {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Lebar"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors?.width?.message && (
                    <p className="mt-2 text-red-500">
                      *{errors?.width?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Tinggi */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Tinggi*</label>
                <div className="col-span-6">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      cm
                    </div>
                    <Controller
                      control={control}
                      name="height"
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={(e) => {
                            onChange(e.target.value);
                            handleVolume();
                          }}
                          name={name}
                          getInputRef={register("height", {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Tinggi"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors?.height?.message && (
                    <p className="mt-2 text-red-500">
                      *{errors?.height?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Berat */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Berat*</label>
                <div className="col-span-6">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      kg
                    </div>
                    <Controller
                      control={control}
                      name="weight"
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={onChange}
                          name={name}
                          getInputRef={register("weight", {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Berat"
                          value={value}
                        />
                      )}
                    />
                  </div>
                  {errors?.weight?.message && (
                    <p className="mt-2 text-red-500">
                      *{errors?.weight?.message}
                    </p>
                  )}
                </div>
              </div>
              {/* Volume */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Volume*</label>
                <div className="col-span-6">
                  <div className="flex w-full">
                    <div className="w-10 py-4 text-center rounded-l-lg bg-grey-300">
                      cm&sup3;
                    </div>
                    <Controller
                      control={control}
                      name="volume"
                      render={({ field: { onChange, name, value } }) => (
                        <NumberFormat
                          className="w-full text-sm border rounded-r-lg form-input"
                          onChange={onChange}
                          name={name}
                          getInputRef={register("volume", {
                            required: {
                              value: true,
                              message: "Wajib Diisi",
                            },
                            min: {
                              value: 0,
                              message: "Angka tidak boleh minus",
                            },
                          })}
                          placeholder="Masukan Volume"
                          value={value}
                          style={{
                            background: "#E0E0E0",
                          }}
                          disabled
                        />
                      )}
                    />
                  </div>
                  {errors?.volume?.message && (
                    <p className="mt-2 text-red-500">
                      *{errors?.volume?.message}
                    </p>
                  )}
                </div>
              </div>
            </div>
            {/* Lainnya */}
            <div className="p-4 mt-4 rounded-lg shadow" id="other">
              <div className="text-base font-bold">Lainnya</div>
              {/* Status Produk */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Status Produk</label>
                <div className="col-span-6 ">
                  <select
                    className="w-full col-span-6 text-sm border rounded-lg form-select"
                    {...register("status", {
                      required: true,
                    })}
                  >
                    <option value={10} selected>
                      Aktif
                    </option>
                    <option value={0}>Tidak Aktif</option>
                  </select>
                  {errors.status && errors.status.type === "required" && (
                    <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                  )}
                </div>
              </div>
              {/* Visibilitas Produk */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Visibilitas Produk</label>
                <div className="col-span-6 ">
                  <Controller
                    control={control}
                    name="visibilitas"
                    render={({ field: { onChange, name, value } }) => (
                      <Select
                        isMulti
                        name={name}
                        value={value}
                        onChange={onChange}
                        className="basic-multi-select"
                        placeholder="Pilih"
                        options={membershipList}
                        styles={customStyle}
                      />
                    )}
                  />
                  {errors.visibilitas &&
                    errors.visibilitas.type === "required" && (
                      <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                    )}
                </div>
              </div>
              {/* Tujuan Pengiriman */}
              <div className="grid items-center grid-cols-8 mt-8">
                <label className="col-span-2">Tujuan Pengiriman</label>
                <div className="col-span-6 ">
                  <Controller
                    control={control}
                    name="destinationSelect"
                    render={({ field: { onChange, name, value } }) => (
                      <Select
                        name={name}
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          handleTypeDestination(e);
                        }}
                        className="basic-multi-select"
                        placeholder="Pilih"
                        options={destination}
                        styles={customStyle}
                      />
                    )}
                  />
                  {typeDestination === 1 && (
                    <div className="mt-4">
                      <Controller
                        control={control}
                        name="citySelect"
                        render={({ field: { onChange, name, value } }) => (
                          <Select
                            isMulti
                            name={name}
                            value={value}
                            onChange={onChange}
                            className="basic-multi-select"
                            placeholder="Pilih Kota/Kab"
                            options={citiesList}
                            styles={customStyle}
                          />
                        )}
                      />
                    </div>
                  )}
                  {errors.citySelect &&
                    errors.citySelect.type === "required" && (
                      <div className="pt-2 text-red-500">*Wajib Diisi!</div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Create;
