import React from 'react';
import { FiDownload, FiFilter } from 'react-icons/fi';
import { CircularBadge } from '../../components/badges';
import { flatten } from 'lodash';
import Datatable from '../../components/datatable';
import Switch from '../../components/switch';
import Search from '../../components/navbar-1/search';
import { getPaymentList } from '../../services/api/command/payment.command';

function PaymentMethods() {
  const { data: paymentListData } = getPaymentList().query('payment');

  const PaymentList = () => {
    const columns = React.useMemo(
      () => [
        {
          Header: 'Nama Metode',
          accessor: 'name',
          Cell: (props) => (
            <div className='flex items-center justify-start break-all gap-x-4'>
              <div class='shrink-0 w-8'>
                <img
                  src={props.row?.original.payment_image}
                  alt='media'
                  class='h-8 w-full object-contain shadow-lg rounded-full ring'
                />
              </div>
              <div>
                <div className='small text-grey-500'>
                  {props.row?.original.payment_display}
                </div>
                {/* <div className='flex items-center gap-x-2'>
                  <div className='font-bold'>{props.row?.original.payment_provider}</div>
                </div> */}
              </div>
            </div>
          ),
        },
        {
          Header: 'Provider',
          accessor: 'provider',
          Cell: (props) => (
            <div className='flex items-center justify-start break-all gap-x-4'>
              {/* <div class="shrink-0 w-8">
                <img src="/assets/faces/m1.png" alt="media" class="h-8 w-full shadow-lg rounded-full ring" />
              </div> */}
              <div>
                <div className='flex items-center gap-x-2'>
                  <div className='font-bold'>
                    {props.row?.original.payment_provider}
                  </div>
                </div>
              </div>
            </div>
          ),
        },
        {
          Header: '',
          accessor: 'action',
          Cell: (props) => (
            <div className='flex justify-start pr-4 gap-x-4'>
              <Switch
                hasText={true}
                textWhenFalse='Non-Aktif'
                textWhenTrue='Aktif'
              />
            </div>
          ),
        },
      ],
      []
    );

    const getAllPaymentList = flatten(
      Object.values(paymentListData?.data?.data || '')
    );
    const data = React.useMemo(() => getAllPaymentList, []);

    return (
      <Datatable
        disablePagination
        checkbox={false}
        columns={columns}
        data={data}
      />
    );
  };

  return (
    <div>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Metode Pembayaran</h4>
      </div>
      <div className='mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari metode pembayaran' />
          <div className='flex'>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiDownload className='stroke-current text-md' />
              <a
                href='#'
                className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
              >
                Export
              </a>
            </div>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiFilter className='stroke-current text-md' />
              <select
                className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
                // value={pageSize}
                // onChange={(e) => {
                //   setPageSize(Number(e.target.value));
                // }}
              >
                {['Filter', 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span>
                <CircularBadge color='bg-blue-grey-100 text-black'>
                  1
                </CircularBadge>
              </span>
            </div>
          </div>
        </div>
        <PaymentList />
      </div>
    </div>
  );
}

export default PaymentMethods;
