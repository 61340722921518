import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, shallowEqual } from 'react-redux';
import { FiMinusCircle, FiPlusCircle, FiX } from 'react-icons/fi';
import Portal from '../../../components/portal';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../App';
import { doApprovalMembership } from '../../../services/api/command/membership.command';

const UpdateStatusMembershipModal = ({ membership }) => {
  const history = useHistory();
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;

      if (event.target.id === 'updateStatus') {
        handleUpdateStatus(membership);
      }
      
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef, membership]);

  const handleUpdateStatus = (membership) => {
    const body = {
      agent_id: membership.agent_id,
      status: membership.status === 0 ? 1 : 0
    }

    doApprovalMembership()
      .command(body)
      .then((response) => {
        hide();
        toast.success('Status Membership berhasil diubah');
        queryClient.invalidateQueries('update-status-membership');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <>
      <button className={`px-4 py-1 ${membership.status === 0 ? 'bg-green-300' : 'bg-red-300'} border shadow-sm btn btn-rounded btn-icon text-white`} onClick={show}>
        {membership.status === 0 ? (
          <FiPlusCircle className='mr-2 stroke-current' />
        ) : (
          <FiMinusCircle className='mr-2 stroke-current' />
        )}
        <span className='text-sm'>{membership.status === 0 ? 'Aktifkan' : 'Non-aktifkan'}</span>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-1/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h5 className='text-xl font-semibold'>Konfirmasi</h5>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex-auto p-4 text-center'>
                  <span>Apakah anda yakin ingin mengubah status membership atas nama <span className='font-bold'>{membership.name}</span> menjadi <span className='font-bold'>{membership.status === 0 ? 'Aktif' : 'Non-aktif'}</span>?</span>
                  <div className='self-center mt-5 space-x-2 text-center'>
                    <button
                      className='border btn btn-default btn-rounded'
                      type='button'
                      onClick={hide}
                    >
                      Tidak
                    </button>
                    <button
                      className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                      type='button'
                      id='updateStatus'
                      onClick={hide}
                    >
                      Ya
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default UpdateStatusMembershipModal;
