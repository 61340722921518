import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import Layout from '../../layouts/centered';
import { doLogin } from '../../services/api/command/auth.command.ts';
import LoginImage from './assets/login.jpg';
import { Button } from '../../components/buttons/buttons';
import { FiEye, FiEyeOff } from 'react-icons/fi';

//Logo
import Logo from './assets/rintiz-logo.svg';

const Login = () => {
  const history = useHistory();
  const [togglePassword, setTogglePassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  const handleLogin = () => {
    setLoading(true);
    doLogin()
      .command(getValues())
      .then((res) => {
        setLoading(false);
        history.push({
          pathname: '/otp',
          state: {
            phone_number: res.data.data.phone,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <>
      <div className='flex justify-center w-full mx-56'>
        <div className='w-full mx-10 my-auto xl:w-3/5 xl:mx-32'>
				<div className='flex justify-center'>
          <img src={Logo} className="w-60" alt='Rintiz Logo' />
        </div>
          <form onSubmit={handleSubmit(handleLogin)}>
            <div className='mt-6 xl:mt-16'>
              <label className='text-base'>Email/Nomor HP</label>
              <input
                placeholder='Isi dengan alamat email / nomor hp'
                type='text'
                {...register('username', { required: true, maxLength: 30 })}
                className='block w-full px-5 py-4 mt-4 text-sm border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
              />
              {errors.username && errors.username.type === 'required' && (
                <div className='pt-2 text-red-500'>*Wajib diisi</div>
              )}
            </div>
            <div className='relative mt-4'>
              <label className='text-base'>Kata Sandi</label>
              <input
                placeholder='Minimal 8 karakter'
                type={togglePassword ? 'text' : 'password'}
                {...register('password', { required: true, maxLength: 30 })}
                className='block w-full px-5 py-4 mt-4 text-sm border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0'
              />
              {errors.password && errors.password.type === 'required' && (
                <div className='pt-2 text-red-500'>*Wajib diisi</div>
              )}
              <div
                className='absolute right-0 flex pr-6'
                style={{ top: '59px' }}
              >
                {togglePassword ? (
                  <FiEye
                    onClick={() => setTogglePassword(!togglePassword)}
                    size={18}
                    className='cursor-pointer stroke-current'
                  />
                ) : (
                  <FiEyeOff
                    onClick={() => setTogglePassword(!togglePassword)}
                    size={18}
                    className='cursor-pointer stroke-current'
                  />
                )}
              </div>
            </div>
            <div className='mt-4 text-right'>
              <a
                href='/forgot-password'
                className='m-0 text-sm shadow-none outline-none appearance-none text-primary focus:shadow-none'
              >
                Lupa Kata Sandi
              </a>
            </div>
            <div className='mt-4'>
              <Button
                loading={loading}
                text={'Login'}
                className='w-full py-4 text-base font-bold text-white rounded-md btn gap-x-2 bg-primary'
              />
            </div>
          </form>
          <div className='mt-4 text-center'>
            Belum punya akun?{' '}
            <a
              href='/register'
              className='m-0 text-base font-medium shadow-none outline-none appearance-none text-primary focus:shadow-none'
            >
              Daftar Sekarang
            </a>
          </div>
        </div>
      </div>
      {/* <img
        src={LoginImage}
        className='hidden w-auto h-full xl:flex'
        alt='...'
      /> */}
    </>
  );
};

export default Login;
