import React from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Index from "./pages/home/index";
import ErrorPage from "./pages/error-page";
import Products from "./pages/products";
import ProductAdd from "./pages/products/detail/create";
import ProductSales from "./pages/products/detail/sales-information";
import ProductDelivery from "./pages/products/detail/delivery";
import ProductOther from "./pages/products/detail/other";
import ProductEdit from "./pages/products/detail/edit";
import Coupons from "./pages/coupons";
import CouponAdd from "./pages/coupons/create";
import Orders from "./pages/orders";
import Memberships from "./pages/memberships";
import MembershipAdd from "./pages/memberships/create";
import MembershipType from "./pages/memberships-type";
import FreeMembershipType from "./pages/memberships-type/free-membership-type";
import PaidMembershipType from "./pages/memberships-type/paid-membership-type";
import OrderDetailPage from "./pages/orders/order-details.page";
import MembershipsLevel from "./pages/memberships-level";
import PaymentMethods from "./pages/payment-methods";
import ShippingProviders from "./pages/shipping-providers";
import HistoryWithdrawal from "./pages/withdrawals";
import ResellerWithdrawal from "./pages/withdrawals/reseller";
import MembershipLevelEdit from "./pages/memberships-level/edit";
import MembershipsReferral from "./pages/memberships-referral";
import Profile from "./pages/profile";
import AddOn from "./pages/add-on";
import Login from "./pages/auth/login";
import Register from "./pages/auth/register";
import OTP from "./pages/auth/otp";
import ForgotPassword from "./pages/auth/forgot-password";
import OTPForgotPassword from "./pages/auth/otp-forgot-password";
import NewPassword from "./pages/auth/new-password";
import ShippingLabel from "./pages/shipping-label";
import LandingPages from "./pages/landings";
import LandingBuilder from "./pages/landing-builder";

import { isEmpty } from "lodash";
import TokenBroker from "./services/api/tokenBroker";
import Categories from "./pages/categories";
import CreateCategory from "./pages/categories/create";
import InvoiceDetailPage from "./pages/orders/components/invoice/invoice-detail-page";
import Subscription from "./pages/subscriptions";
import SubscriptionHistory from "./pages/subscriptions/history";
import SubscriptionInvoice from "./pages/subscriptions/invoice";
import MembershipLevelAdd from "pages/memberships-level/create";
import CouponEdit from "pages/coupons/edit";

function RequireAuth({ children }) {
  const location = useLocation();

  if (isEmpty(TokenBroker.get())) {
    return <Redirect to="/login" state={{ from: location }} />;
  }

  return children;
}

function NoAuth({ children }) {
  const location = useLocation();

  if (TokenBroker.get()) {
    return <Redirect to="/" state={{ from: location }} />;
  }

  return children;
}

const MainRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <NoAuth>
          <Login />
        </NoAuth>
      </Route>
      <Route path="/register">
        <NoAuth>
          <Register />
        </NoAuth>
      </Route>
      <Route path="/otp">
        <OTP />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/otp-forgot-password">
        <OTPForgotPassword />
      </Route>
      <Route path="/new-password">
        <NewPassword />
      </Route>
      <Route path="/error-page">
        <ErrorPage />
      </Route>
      <Route exact path="/">
        <RequireAuth>
          <Index />
        </RequireAuth>
      </Route>
      <Route path="/products">
        <Products />
      </Route>
      <Route path="/product/add">
        <ProductAdd />
      </Route>
      <Route path="/product/edit/:sku">
        <ProductEdit />
      </Route>
      <Route path="/product/sales">
        <ProductSales />
      </Route>
      <Route path="/product/delivery">
        <ProductDelivery />
      </Route>
      <Route path="/product/other">
        <ProductOther />
      </Route>
      <Route path="/categories/add">
        <CreateCategory />
      </Route>
      <Route path="/categories">
        <Categories />
      </Route>
      <Route path="/coupons">
        <Coupons />
      </Route>
      <Route exact path="/coupon/add">
        <CouponAdd />
      </Route>
      <Route exact path="/coupon/edit">
        <CouponEdit />
      </Route>
      <Route path="/orders/detail/:id">
        <OrderDetailPage />
      </Route>
      <Route path="/invoices/detail/:id">
        <InvoiceDetailPage />
      </Route>
      <Route path="/orders">
        <Orders />
      </Route>
      <Route exact path="/memberships">
        <Memberships />
      </Route>
      <Route exact path="/membership/add">
        <MembershipAdd />
      </Route>
      <Route exact path="/payment-methods">
        <PaymentMethods />
      </Route>
      <Route exact path="/shipping-providers">
        <ShippingProviders />
      </Route>
      <Route exact path="/withdrawal/history">
        <HistoryWithdrawal />
      </Route>
      <Route exact path="/withdrawal/reseller">
        <ResellerWithdrawal />
      </Route>
      <Route exact path="/membership/type">
        <MembershipType />
      </Route>
      <Route exact path="/membership/type/free">
        <FreeMembershipType />
      </Route>
      <Route exact path="/membership/type/paid">
        <PaidMembershipType />
      </Route>

      <Route exact path="/membership/referral-code">
        <MembershipsReferral />
      </Route>
      <Route exact path="/membership/level/add">
        <MembershipLevelAdd />
      </Route>
      <Route exact path="/membership/level/edit/:id">
        <MembershipLevelEdit />
      </Route>
      <Route exact path="/membership/level">
        <MembershipsLevel />
      </Route>
      <Route exact path="/landings/builder">
        <LandingBuilder />
      </Route>
      <Route exact path="/landings">
        <LandingPages />
      </Route>
      <Route exact path="/profile">
        <Profile />
      </Route>
      <Route exact path="/addon">
        <AddOn />
      </Route>
      <Route exact path="/shipping-label">
        <ShippingLabel />
      </Route>
      <Route exact path="/subscriptions">
        <Subscription />
      </Route>
      <Route exact path="/subscriptions/history">
        <SubscriptionHistory />
      </Route>
      <Route exact path="/subscriptions/invoice/:id">
        <SubscriptionInvoice />
      </Route>
      <Route component={ErrorPage} />
    </Switch>
  );
};
export default MainRoutes;
