import queryCommander from "../queryCommander";

export const getPaymentList = () => queryCommander({
  method: "GET",
  path: "/payment/list",
  queryType: "query",
  authenticated: true
});

export const doGetPaymentBrandownerList = () => queryCommander({
  method: "GET",
  path: '/payment/list/payment_brandowner',
  queryType: 'query',
  authenticated: true
});
