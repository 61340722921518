import React from 'react';
import { FiBox, FiEdit, FiFilter, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Datatable from '../../components/datatable';
import Search from '../../components/navbar-1/search';
import Switch from '../../components/switch';
import { UnderlinedTabs } from '../../components/tabs';
import countries from '../../json/countries.json';
import { doGetMembershipLevelList } from '../../services/api/command/membership.command';
import MembershipLevelModal from './components/modal';
import UpdateStatusMembershipLevelModal from './components/update-status';
// import Filter from './order-filter-modal';

function MembershipsLevel() {
  const history = useHistory();
  const Table = () => {
    const history = useHistory();
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const membershipLevelList = doGetMembershipLevelList().query(
      ['membership-level-list', page, pageSize],
      {},
      { limit: pageSize, page: page }
    );

    let rows = [];

    if (! membershipLevelList.error && membershipLevelList.status !== 'loading') {
      rows = Object.entries(membershipLevelList.data?.data.data).length > 0 ? membershipLevelList.data?.data.data : [];
    }
    
    const handleChangePage = (newPage) => {
      setPage(newPage.selected + 1);
    }

    const handleEditMembershipLevel = (level) => {
      localStorage.setItem('membership-level', JSON.stringify(level));
      history.push(`/membership/level/edit/${level.id}`);
    }

    const columns = React.useMemo(
      () => [
        {
          Header: 'LEVEL MEMBER',
          accessor: 'name',
          Cell: (props) => {
            let row = props.row;
            let color = row.original.color;
            let name = row.original.name;

            return (
              <div className='inline-block px-3 py-1 rounded-md' style={{background: color}}>
                <div className='text-xs font-bold text-black cursor-pointer hover:underline'>{name}</div>
              </div>
            )
          },
        },
        {
          Header: 'STATUS',
          accessor: 'is_active',
          Cell: (props) => <span>{props.value === 1 ? 'Aktif' : 'Non-Aktif'}</span>,
        },
        {
          Header: '',
          accessor: 'action',
          Cell: (props) => {
            let row = props.row;
            let id = row.original.id;

            return (
              <div className='flex justify-end pr-4 gap-x-4'>
                <button onClick={() => handleEditMembershipLevel(row.original)} className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon'>
                  <FiEdit className='mr-2 stroke-current' />
                  <span className='text-sm'>Edit</span>
                </button>
                <UpdateStatusMembershipLevelModal membership={row.original} />
              </div>
            )
          },
        },
      ],
      []
    );

    const data = React.useMemo(() => rows, [rows]);
    return (
      <Datatable
        totalPage={membershipLevelList?.data?.data.data.total_page}
        columns={columns}
        handleChangePage={handleChangePage}
        data={data}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
  };

  return (
    <div>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
      <h4 className='py-3 font-bold'>Level Membership</h4>
        <div className=''>
          <button className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary' onClick={() => history.push("/membership/level/add")}>
            <FiBox className='text-lg stroke-current' />
            Tambah Level
          </button>
        </div>
      </div>
      <div className='mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Search Membership' />
          <MembershipLevelModal />
        </div>
        <Table />
      </div>
    </div>
  );
}

export default MembershipsLevel;
