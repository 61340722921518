import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Portal from './../../../components/portal';
import { FiX } from 'react-icons/fi';
import { FiFilter } from 'react-icons/fi';

const SelectOptions = ({ options }) => {
  return (
    <div className='inline-flex gap-2'>
      {(options || []).map((option) => (
        <div className='px-3 py-1 border rounded-md cursor-pointer bg-slate-20 border-slate-30'>
          {option.label}
        </div>
      ))}
    </div>
  );
};

const MembershipLevelModal = ({ title, body }) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      console.log(modalRef.current.contains(event.target));
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef]);

  return (
    <>
      <button type='button' onClick={show}>
        <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
          <FiFilter className='stroke-current text-md' />
          Filter
        </div>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h3 className='text-xl font-semibold'>Filter</h3>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex-auto p-4'>
                  <div className='grid grid-cols-2 gap-4 mb-8'>
                    <div>Tipe Level Member</div>
                    <SelectOptions
                      options={[
                        { value: '', label: 'Free' },
                        { value: '', label: 'Berbayar' },
                      ]}
                    />
                  </div>
                  <div className='grid grid-cols-2 gap-4 mb-8'>
                    <div>Status Member</div>
                    <SelectOptions
                      options={[
                        { value: '', label: 'Aktif' },
                        { value: '', label: 'Non Aktif' },
                      ]}
                    />
                  </div>
                </div>
                <div className='space-x-2 modal-footer'>
                  <button
                    className='border btn btn-default btn-rounded'
                    type='button'
                    onClick={hide}
                  >
                    Reset
                  </button>
                  <button
                    className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                    type='button'
                    onClick={hide}
                  >
                    Terapkan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default MembershipLevelModal;
