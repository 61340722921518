import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, shallowEqual } from 'react-redux';
import { FiX } from 'react-icons/fi';
import Portal from '../../../../components/portal';
import { doUpdateStatusInvoice } from '../../../../services/api/command/order.command';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../../App';
import ucwords from '../../../../functions/ucwords';

const UpdateStatusInvoiceModal = ({ order }) => {
  const history = useHistory();
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  const orderStatuses = ['paid', 'invoice_send_to_brand', 'confirm_stock', 'confirm_ready_ship', 'send_delivery_order', 'shipped', 'delivered', 'completed'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;

      if (event.target.id === 'updateStatus') {
        handleUpdateStatus(order.invoice);
      }
      
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef, order]);

  const handleUpdateStatus = (invoice) => {
    let nextStatus = orderStatuses[orderStatuses.indexOf(invoice.status_ops) + 1];

    if (['paid', 'invoice_send_to_brand', 'confirm_stock', 'confirm_ready_ship'].includes(order?.invoice.status_ops)) {
      nextStatus = 'send_delivery_order';
    }

    const body = {
      sales_invoice_no: invoice.sales_invoice_no,
      status: nextStatus,
      shipping_awb: '',
      shipping_awb_image_url: '',
      courier: '',
      service_type: '',
      autocreate_shipping_order: 0,
      note: '',
    }

    doUpdateStatusInvoice()
      .command(body)
      .then((response) => {
        hide();
        toast.success('Status Invoice berhasil diubah');
        queryClient.invalidateQueries('update-status-invoice');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <>
      {order?.invoice.status_ops !== 'completed' && (
        <button type='button' onClick={show}>
        <div className='flex items-center justify-start px-3 py-1 text-white border rounded-lg border-primary gap-x-2 bg-primary'>
          {['paid', 'invoice_send_to_brand', 'confirm_stock'].includes(order?.invoice.status_ops) ? (
            <span>On Process</span>
          ) : (
            <span>{ucwords(orderStatuses[orderStatuses.indexOf(order?.invoice.status_ops) + 1])}</span>
          )}
        </div>
        </button>
      )}
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-1/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h5 className='text-xl font-semibold'>Konfirmasi</h5>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex-auto p-4 text-center'>
                  {['paid', 'invoice_send_to_brand', 'confirm_stock', 'confirm_ready_stock', 'confirm_ready_ship'].includes(order?.invoice.status_ops) ? (
                    <span>Apakah anda yakin ingin mengubah status order menjadi <span className='font-bold'>On Process</span>?</span>
                  ) : (
                    <span>Apakah anda yakin ingin mengubah status order menjadi <span className='font-bold'>{ucwords(orderStatuses[orderStatuses.indexOf(order?.invoice.status_ops) + 1])} </span>?</span>
                  )}
                  <div className='self-center mt-5 space-x-2 text-center'>
                    <button
                      className='border btn btn-default btn-rounded'
                      type='button'
                      onClick={hide}
                    >
                      Tidak
                    </button>
                    <button
                      className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                      type='button'
                      id='updateStatus'
                      onClick={hide}
                    >
                      Ya
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default UpdateStatusInvoiceModal;
