import React from 'react';
import formatPrice from '../../../functions/formatPrice';

function PaymentInformation({data}) {
  const subtotal = data?.subtotal || 0;
  const shipping_total = data?.shipping_total || 0;
  const discount_total = data?.discount.discount_total || 0;
  const cashback_coupon = data?.coupon?.cashback || null;
  const discount_coupon = data?.coupon?.discount || null;
  const ongkir_coupon = data?.coupon?.ongkir || null;
  const coupons = [cashback_coupon, discount_coupon, ongkir_coupon].map((coupon) => {
    if (typeof coupon != 'undefined') {
      return coupon;
    }

    return null;
  }).filter((coupon) => coupon).toString();
  const grand_total = data?.grand_total_with_margin || 0;
  const payment_method = data?.payment?.payment_method || null;

  return (
    <div id='order-information'>
      <div className='p-4 mb-6 border rounded-md shadow-custom'>
        <div className='font-bold text-md'>Informasi Pembayaran</div>
        <div className='flex flex-col mt-4 gap-x-10'>
          <table className='w-full text-left capitalize table-auto'>
            <tbody>
              <tr>
                <td className='py-2 font-medium leading-4 w-52 text-grey-500'>
                  Sub total
                </td>
                <td className='py-2 whitespace-no-wrap'>{formatPrice(subtotal)}</td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Ongkos Kirim
                </td>
                <td className='py-2 whitespace-no-wrap'>{formatPrice(shipping_total)}</td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Diskon
                </td>
                <td className='py-2 whitespace-no-wrap'>
                  <div>{formatPrice(discount_total)}</div>
                </td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Kode Diskon
                </td>
                <td className='py-2 whitespace-no-wrap'>{coupons || '-'}</td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  TOTAL
                </td>
                <td className='py-2 whitespace-no-wrap'>{formatPrice(grand_total)}</td>
              </tr>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Metode Pembayaran
                </td>
                <td className='py-2 whitespace-no-wrap'>{payment_method}</td>
              </tr>
              {/* <tr>
                <td className='flex py-2 font-medium leading-4 text-grey-500'>
                  Bukti Pembayaran
                </td>
                <td className='py-2 whitespace-no-wrap'>
                  <div className='flex items-center'>
                    <img
                      src='https://picsum.photos/40'
                      className='w-16 rounded-lg'
                      alt='...'
                    />
                    <div className='flex flex-col ml-5'>
                      <span>Bukti Transfer</span>
                      <span className='pt-1 font-bold text-primary'>Download</span>
                    </div>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PaymentInformation;
