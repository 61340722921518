import React from 'react'
import { useHistory } from 'react-router-dom'

function LandingPages() {
  const history = useHistory()

  return (
    <div>
      <h4 className='py-3 font-bold mb-3'>Landing Page</h4>
      <div id='landing-page-list'>
        <div className='border custom-shadow rounded-lg p-3 bg-grey-50 inline-block'>
          <div>
            <img src='https://picsum.photos/200/200' alt='...' />
          </div>
          <div className='mt-6'>
            <span className='font-bold'>Template 1</span>
            <div className='flex justify-between gap-x-2 mt-5 items-center'>
              <button className='btn border w-full rounded-md text-primary py-1 border-primary'>
                Preview
              </button>
              <button
                onClick={() => history.push('/landings/builder')}
                className='btn border rounded-md w-full text-white bg-primary py-1 border-primary'
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPages;
