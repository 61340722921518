import React from 'react';

function InvoiceStatus({data}) {
  let status = typeof data == 'undefined' ? null : (['invoice_send_to_brand', 'confirm_stock', 'confirm_ready_ship', 'send_delivery_order'].includes(data) ? 'On Process' : data.replaceAll('_', ' ')[0].toString().toUpperCase() + data.slice(1));

  return (
    <div id='invoice-status'>
      <div className='p-4 mb-6 border rounded-md shadow-custom'>
        <div className='font-bold text-md'>Status Invoice</div>
        <div className='inline-block px-2 py-1 mt-4 rounded-md bg-grey-200'>
          <div>{status}</div>
        </div>
      </div>
    </div>
  );
}

export default InvoiceStatus;
