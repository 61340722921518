/* eslint-disable react-hooks/rules-of-hooks */
import { useInfiniteQuery, useQuery } from 'react-query';

import api, { ApiResponse, methodType, PaginatedRequest, PaginatedResponse } from './api';
import wrapApi from '../wrap-api';

type QueryType = 'query' | 'infinite';

type queryCommanderProps<D> = {
  method: methodType;
  path: string;
  customDomain?: string;
  data?: D;
  queryType: QueryType;
  authenticated: boolean;
  defaultQueryKey?: string;
};

const queryCommander = <TCommandRequestData, TCommandResponseData = any>(
  props: queryCommanderProps<TCommandRequestData>
) => {
  const command = (commandData?: TCommandRequestData) =>
    api({
      method: props.method,
      url: wrapApi(props.path, props.method == 'GET' && props.data),
      authenticated: props.authenticated ?? false,
      data: commandData ?? props.data,
    });

  const query = (
    queryKey?: any,
    queryOptions = {},
    data?: TCommandRequestData
  ) => {
    return useQuery(
      queryKey ?? props?.defaultQueryKey,
      () => {
        return api<TCommandRequestData, ApiResponse<TCommandResponseData>>({
          method: props.method,
          url: wrapApi(props.path, props.method == 'GET' && data),
          data: data,
          authenticated: props.authenticated ?? false,
        });
      },
      { ...queryOptions }
    );
  }

  const infiniteQuery = (
    queryKey?: any,
    queryOptions = {},
    queryData?: TCommandRequestData
  ) => {
    return useInfiniteQuery(
      queryKey || props.defaultQueryKey,
      (page) => {
        return api<TCommandRequestData & PaginatedRequest, PaginatedResponse<TCommandResponseData>>({
          method: props.method,
          url: wrapApi(
            props.path,
            props.method == 'GET' ? { ...queryData, page: page.pageParam || 1 } : {}
          ),
          data: ({ ...queryData, page: page.pageParam ?? 1 }) as any,
          authenticated: props.authenticated ?? false,
        });
      },
      {
        ...queryOptions,
        getNextPageParam: (lastPage: any) => {
          const currentTotal =
            (lastPage?.data.data.page || 1) * (lastPage?.data.data.limit || 1);
          if (currentTotal >= (lastPage?.data.data.total || 1)) {
            return undefined;
          }

          const currentPage = lastPage?.data.data.page || 1;
          const nextPage = currentPage + 1;
          return nextPage;
        }
      }
    );
  };

  return { infiniteQuery, query, command };
};

export default queryCommander;
