import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, shallowEqual } from 'react-redux';
import { FiUserPlus, FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Portal from '../../../components/portal';
import { queryClient } from '../../../App';
import { useForm } from 'react-hook-form';
import { doGetProfile } from '../../../services/api/command/profile.command';
import { doCreateReferralCode } from '../../../services/api/command/membership.command';

const CreateMembershipReferralModal = ({ membership }) => {
  const history = useHistory();
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  const {
    control,
		register,
		handleSubmit,
		getValues,
		setValue,
    resetField,
		formState: { errors },
	} = useForm();

  const profile = doGetProfile().query('profile').data?.data.data;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;

      if (event.target.id === 'createReferralCode') {
        handleCreateReferralCode();
      }
      
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef]);

  const handleCreateReferralCode = () => {
    const body = {
      brandowner_id: profile?.brandowner_id,
      count: parseInt(getValues('count')),
      prefix: getValues('prefix')
    }

    doCreateReferralCode()
      .command(body)
      .then((response) => {
        hide();
        toast.success('Referral Code berhasil dibuat');
        queryClient.invalidateQueries('create-referral-code');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <>
      <button className={`px-4 py-2 text-xs font-bold text-white rounded-md btn btn-icon gap-x-2 bg-primary`} onClick={show}>
        <FiUserPlus className='mr-1 text-lg stroke-current' />
        <span className='text-xs'>Generate Referral</span>
      </button>
      {open && (
        <Portal selector='#portal'>
        <div className='modal-backdrop fade-in'></div>
        <div className={`modal show bg-black bg-opacity-25`}>
          <div
            className='relative w-2/5 mx-auto min-w-sm lg:max-w-5xl'
            ref={modalRef}
          >
            <div className='bg-white modal-content'>
              <div className='modal-header'>
                <h5 className='text-xl font-semibold'>Input Resi</h5>
                <button
                  className='modal-close btn btn-transparent'
                  onClick={hide}
                >
                  <FiX size={18} className='stroke-current' />
                </button>
              </div>
              <div className='p-4 space-y-3 modal-body'>
                <div className="flex flex-row items-center space-x-5">
                  <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Jumlah Referral</label>
                  <div className="flex w-full">
                    <input 
                      placeholder="Max. 100" 
                      type="text" 
                      name="count" 
                      className="block w-full bg-white border-gray-300 rounded-md rounded-r-none form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                      {...register('count')}
                    />
                  </div>
                </div>
                <div className="flex flex-row items-center space-x-5">
                  <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Kode Awalan</label>
                  <div className="flex w-full">
                    <input 
                      placeholder="Masukkan kode awalan (opsional)" 
                      type="text" 
                      name="prefix" 
                      className="block w-full bg-white border-gray-300 rounded-md rounded-r-none form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                      {...register('prefix')}
                    />
                  </div>
                </div>
              </div>
              <div className='modal-footer'>
                <div className='self-center space-x-2 text-center'>
                  <button
                  className='border btn btn-default btn-rounded'
                  type='button'
                  onClick={hide}
                  >
                  Tidak
                  </button>
                  <button
                  className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                  type='button'
                  id='createReferralCode'
                  onClick={hide}
                  >
                  Ya
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Portal>
      )}
    </>
  );
};

export default CreateMembershipReferralModal;
