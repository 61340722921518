import React, { useEffect, useState } from 'react';
import Datatable from '../../components/datatable';
import { FiEdit, FiBox, FiTrash } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import {
  deleteCategoryRequest,
  getListCategories,
  updateCategoryRequest,
} from '../../services/api/command/categories.command';
import ModalEdit from './components/modal';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { queryClient } from '../../App';
import { Button } from '../../components/buttons/buttons';
import { createMediaRequest } from '../../services/api/command/media.command';

const Table = () => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [prevImage, setPrevImage] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const categoryList = getListCategories().query(
    ['category list', page, pageSize],
    {},
    { limit: pageSize, page: page }
  );
  const { register, getValues, setValue, resetField } = useForm();

  const handleChangePage = (newPage) => {
    setPage(newPage.selected + 1);
  };

  const handleEditCategory = (name, desc, id, image) => {
    setIsModalOpen(true);
    setPrevImage(image);
    setValue('name', name);
    setValue('description', desc);
    setValue('category_id', id);
  };

  const handleEditCategoryRequest = () => {
    handleUploadImage().then((res) => {
      updateCategoryRequest(getValues('category_id'))
        .command({
          ...getValues(),
          parent_id: 0,
          article_hierarchy: '',
          attribute_set_id: 0,
          attribute_variant_set_id: 0,
          article_hierarchy_parent: '',
          path: '2',
          image_app: res.public_url,
          image_web: '',
          image: '',
          include_in_menu: 0,
          status: 10,
          order_no: 0,
        })
        .then(() => {
          setLoading(false);
          setIsModalOpen(false);
          toast.error('Kategori berhasil diubah');
          queryClient.invalidateQueries('category list');
          resetField();
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
          setLoading(false);
          setIsModalOpen(false);
        });
    });
  };

  const handleUploadImage = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', image);
    formData.append('source', 'upload');
    formData.append('provider', 'cloudinary');
    formData.append('title', getValues('name'));
    formData.append('description', getValues('description'));

    return createMediaRequest()
      .command(formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      });
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(e.target.files[0].name);
  };

  useEffect(() => {
    return () => {
      setImagePath(null);
      resetField();
    };
  }, [isModalOpen]);

  const handleDelete = (id) => {
    deleteCategoryRequest(id).command().then(() => {
      toast.success('Kategori berhasil dihapus');
      queryClient.invalidateQueries('category list');
    })
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nama Kategori',
        accessor: 'code',
        Cell: (props) => {
          let data = props.row.original;

          return (
            <div className='w-32 break-all'>
              <div className='font-bold'>{data.name}</div>
            </div>
          );
        },
      },
      {
        Header: 'Deskripsi Kategori',
        accessor: 'category',
        Cell: (props) => (
          <div className='flex items-center justify-start gap-x-8'>
            <span className='font-medium'>
              {props.row.original.description}
            </span>
          </div>
        ),
      },
      {
        Header: '',
        accessor: 'coupon_id',
        Cell: (props) => {
          let row = props.row.index;
          let code = row.code;

          return (
            <div className='flex justify-center pr-4 gap-x-4'>
              <button
                className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon'
                onClick={() =>
                  handleEditCategory(
                    props.row.original.name,
                    props.row.original.description,
                    props.row.original.category_id,
                    props.row.original.image_app
                  )
                }
              >
                <FiEdit className='mr-2 stroke-current' />
                <span className='text-sm'>Edit</span>
              </button>
              <button
                onClick={() => handleDelete(props.row.original.category_id)}
                className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon'
              >
                <FiTrash className='mr-2 stroke-current' />
                <span>Hapus</span>
              </button>
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <>
      <Datatable
        checkbox={false}
        totalPage={categoryList?.data?.data.data.total_page}
        columns={columns}
        handleChangePage={handleChangePage}
        data={categoryList.data?.data?.data?.list || []}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      <ModalEdit open={isModalOpen} setOpen={setIsModalOpen}>
        <div className='flex flex-col mb-6 mt-2'>
          <div className='w-full'>
            <label className='col-span-2'>Nama Kategori</label>
            <input
              type='text'
              className='form-input border mt-2 rounded-lg text-sm w-full'
              placeholder='Masukkan nama kategori'
              name='name'
              {...register('name', { required: true })}
            />
          </div>
          <div className='w-full mt-6'>
            <label className='col-span-2'>Deskripsi Kategori</label>
            <textarea
              type='text'
              className='form-input border mt-2 rounded-lg text-sm w-full'
              placeholder='Masukkan deskripsi kategori'
              {...register('description', { required: true })}
            />
          </div>
          <div className='my-4'>
            <label className='col-span-2'>Logo</label>
            <img
              src={prevImage}
              alt='logo'
              className='w-24 my-2 rounded-md border h-24'
            />
            <label className='w-24 flex flex-col items-center px-4 py-1 bg-white rounded-md shadow-sm border cursor-pointer text-primary ease-linear transition-all duration-150'>
              <span className='text-sm leading-normal'>Ubah</span>
              <input
                accept='.jpg,.png'
                onChange={(res) => handleChangeImage(res)}
                type='file'
                className='hidden'
              />
            </label>
            {imagePath && (
              <div className='mt-2 text-primary'>../{imagePath}</div>
            )}
          </div>
          <div className='flex justify-end gap-x-4'>
            <div className='mt-10'>
              <button
                onClick={() => setIsModalOpen(false)}
                className='btn ml-auto text-primary flex font-bold border rounded-lg bg-transparent px-4 py-2 border-primary'
              >
                Batalkan
              </button>
            </div>
            <div className='mt-10'>
              <Button
                loading={loading}
                text={'Simpan'}
                onClick={handleEditCategoryRequest}
                className='btn ml-auto flex font-bold border rounded-lg text-white bg-primary px-4 py-2 border-primary'
              />
            </div>
          </div>
        </div>
      </ModalEdit>
    </>
  );
};

const Categories = () => {
  const history = useHistory();

  return (
    <>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>List Kategori</h4>
        <div className=''>
          <button
            className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
            onClick={() => history.push('/categories/add')}
          >
            <FiBox className='text-lg stroke-current' />
            Tambah Kategori
          </button>
        </div>
      </div>
      <div className='border rounded-lg custom-shadow'>
        {/* <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Kategori' />
          <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
            <FiFilter className='stroke-current text-md' />
            <select
              className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
              // value={pageSize}
              // onChange={(e) => {
              //   setPageSize(Number(e.target.value));
              // }}
            >
              {['Filter', 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span>
              <CircularBadge color='bg-blue-grey-100 text-black'>
                1
              </CircularBadge>
            </span>
          </div>
        </div> */}
        <Table />
      </div>
    </>
  );
};

export default Categories;
