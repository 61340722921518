import React from 'react';
import { FiCheckCircle, FiDownload, FiEdit, FiFilter, FiMinusCircle, FiPlusCircle, FiUser } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CircularBadge } from '../../components/badges';
import Datatable from '../../components/datatable';
import Search from '../../components/navbar-1/search';
import ucwords from '../../functions/ucwords';
import { doGetMembershipList } from '../../services/api/command/membership.command';
import UpdateStatusMembershipModal from './components/update-status';

function Memberships() {
  const history = useHistory();

  const Table = () => {
    const history = useHistory();
    const [page, setPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(10);
    const membershipList = doGetMembershipList().query(
      ['membership list', page, pageSize],
      {},
      { limit: pageSize, page: page }
    );
    
    const handleChangePage = (newPage) => {
      setPage(newPage.selected + 1);
    }

    const handleUpdateStatus = (membership) => {
      console.log(membership)
    }

    const columns = React.useMemo(
      () => [
        {
          Header: 'Nama Lengkap',
          accessor: 'name',
          Cell: (props) => {
            let row = props.row;
            let email = row.original.email;

            return (
              <div className='flex items-center justify-start break-all gap-x-4'>
                <div class="shrink-0 w-8">
                  <img src="/assets/faces/w1.png" alt="media" class="h-8 w-full shadow-lg rounded-full ring" />
                </div>
                <div>
                  <div className='flex items-center gap-x-2'>
                    <div className='font-bold'>{props.value}</div>
                    <FiCheckCircle className='mr-2 text-blue-600 stroke-current' />
                  </div>
                  <div className='small text-grey-500'>{email}</div>
                </div>
              </div>
            )
          },
        },
        {
          Header: 'ID User',
          accessor: 'agent_id',
          Cell: (props) => (
            <div className='flex items-center justify-start gap-x-8'>
              <div>
                <span>{props.value}</span>
              </div>
            </div>
          ),
        },
        {
          Header: 'Level',
          accessor: 'membership_level_name',
          Cell: (props) => {
            let bg_color = props.membership_level_color;
            
            return  (
              <div style={{background: bg_color}} className='inline-flex p-2 text-xs text-center text-blue-600 rounded-lg'>{props.value}</div>
            )
          },
        },
        {
          Header: 'No. Handphone',
          accessor: 'phone',
          Cell: (props) => <span>{props.value}</span>,
        },
        {
          Header: 'Jangka Waktu',
          accessor: 'membership_type_name',
          Cell: (props) => <span>{props.value}</span>,
        },
        {
          Header: 'Status',
          accessor: 'status',
          Cell: (props) => <span>{props.value === 10 ? 'Aktif' : 'Non-Aktif'}</span>,
        },
        {
          Header: 'Masa Aktif',
          accessor: 'membership_type_expired_at',
          Cell: (props) => <span className="capitalize"></span>, // @TODO: check expired date {ucwords(props.value)}
        },
        {
          Header: '',
          accessor: 'action',
          Cell: (props) => {
            let row = props.row.original;
            
            return (
              <div className='flex justify-end pr-4 gap-x-4'>
                {/* <button className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon' onClick={() => history.push(`/memberships/edit/${agent_id}`)}>
                  <FiEdit className='mr-2 stroke-current' />
                  <span className='text-sm'>Edit</span>
                </button> */}
                <UpdateStatusMembershipModal membership={row} />
              </div>
            )
          },
        },
      ],
      []
    );

    const data = React.useMemo(() =>  membershipList?.data?.data?.data?.list || [], [membershipList]);
    console.log(data)
    return (
      <Datatable
        totalPage={membershipList?.data?.data?.data?.total_page}
        columns={columns}
        handleChangePage={handleChangePage}
        data={data}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
  };

  return (
    <div>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>List Membership</h4>
        {/* <div className=''>
          <button className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary' onClick={() => history.push("/membership/add")}>
            <FiUser className='text-lg stroke-current' />
            Tambah Mitra
          </button>
        </div> */}
      </div>
      <div className='mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Member' />
          <div className='flex'>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiDownload className='stroke-current text-md' />
              <a href='#' className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'>Export</a>
            </div>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiFilter className='stroke-current text-md' />
              <select
                className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
                // value={pageSize}
                // onChange={(e) => {
                //   setPageSize(Number(e.target.value));
                // }}
              >
                {['Filter', 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span>
                <CircularBadge color='bg-blue-grey-100 text-black'>
                  1
                </CircularBadge>
              </span>
            </div>
          </div>
          
        </div>
        <Table />
      </div>
    </div>
  );
}

export default Memberships;
