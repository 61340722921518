import { isArray, isEmpty } from 'lodash';

export default (
  url: string,
  queryParams: any = {},
): string => {
  let qp = Object.keys(queryParams)
    .map((key) => {
      if (isArray(queryParams[key])) {
        return (queryParams[key] as Array<string>).map((val) => `${key}[]=${val}`).join("&");
      }

      return `${key}=${queryParams[key]}`
    })
    .join('&');

  if (!isEmpty(qp)) {
    qp = `?${qp}`;
  }

  return `https://wapi-brand.rintiz.id${url}${qp}`;
};
