import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { FiArchive, FiBox, FiCreditCard, FiEye, FiFile, FiHeart, FiMenu, FiSettings, FiTruck } from 'react-icons/fi';
import { doGetInvoiceDetail, doGetOrderDetail } from '../../../../services/api/command/order.command';
import Breadcrumb from '../../../../components/breadcrumbs';
import InvoiceModal from '../modals/invoice';
import UpdateStatusInvoiceModal from '../modals/update-status';
import { VerticalTabs } from '../../../../components/tabs';
import InvoiceStatus from './invoice-status';
import InvoiceInformation from './invoice-information';
import InvoiceProductInformation from './invoice-product-information';
import InvoicePaymentInformation from './invoice-payment-information';
import InvoiceShippingInformation from './invoice-shipping-information';
import UpdateAwbModal from '../modals/update-awb';

function InvoiceDetailPage() {
  let { id } = useParams();

  const breadcrumbs = [
    { title: 'List Order', url: '/orders', last: false },
    { title: `Detail Invoice ${id}`, url: '', last: true },
  ];

  const pills = [
    {
      index: 0,
      content: '',
      title: (
        <>
          <FiFile size={18} style={{ stroke :'#71869D' }} />
          <span>Status Invoice</span>
        </>
      ),
    },
    {
      index: 1,
      title: (
        <>
          <FiArchive size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Invoice</span>
        </>
      ),
      content: '',
    },
    {
      index: 2,
      title: (
        <>
          <FiBox size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Produk</span>
        </>
      ),
      content: '',
    },
    {
      index: 3,
      title: (
        <>
          <div>
            <FiCreditCard size={18} style={{ stroke :'#71869D' }} />
          </div>
          <div>
            <span>Informasi Pembayaran</span>
          </div>
        </>
      ),
      content: '',
    },
    {
      index: 4,
      title: (
        <>
          <FiTruck size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Pengiriman</span>
        </>
      ),
      content: '',
    },
  ];

  const response = doGetInvoiceDetail(id).query(['invoice']);
  const order = response.data?.data.data.order;
  const status = response.data?.data.data.invoice.status_ops;
  const products = response.data?.data.data.items;
  const subtotal = response.data?.data.data.order.subtotal || 0;
  const shipping = response.data?.data.data.shipping;
  const tracking = response.data?.data.data.tracking;

  return (
    <div className='px-20'>
      <Breadcrumb items={breadcrumbs} />
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Detail Invoice {id}</h4>
        <div className='flex gap-x-2'>
          <InvoiceModal order={response.data?.data.data} />
          {response.data?.data.data.invoice.status_ops === 'send_delivery_order' 
            ? <UpdateAwbModal order={response.data?.data.data} />
            : <UpdateStatusInvoiceModal order={response.data?.data.data} />}
        </div>
      </div>
      <div className='flex flex-wrap my-6'>
        <div className='w-full'>
          <VerticalTabs customChild={true} tabs={pills}>
            <InvoiceStatus data={status} />
            <InvoiceInformation data={order} />
            <InvoiceProductInformation data={products} subtotal={subtotal} />
            <InvoicePaymentInformation data={order} />
            <InvoiceShippingInformation data={shipping} tracking={tracking} />
          </VerticalTabs>
        </div>
      </div>
    </div>
  );
}

export default InvoiceDetailPage;
