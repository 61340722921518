import queryCommander from "../queryCommander";

interface TLoginRequest {
  username: string;
  password: string;
}

export const doLogin = <TLogin = TLoginRequest>() => queryCommander<TLogin>({
  method: "POST",
  path: "/auth/login",
  queryType: "query",
  authenticated: false
});

interface TOtpRequest {
  otp: string;
  phone_number: string;
}

export const doSubmitOtp = <TOtp = TOtpRequest>() => queryCommander<TOtp>({
  method: "POST",
  path: "/auth/otp-login",
  queryType: "query",
  authenticated: false
});

interface TOtpRequestRegister {
  otp: string;
  phone_number: string;
}

export const doSubmitOtpRegister = <TOtp = TOtpRequestRegister>() => queryCommander<TOtp>({
  method: "POST",
  path: "/auth/otp-register",
  queryType: "query",
  authenticated: false
});

interface TRegisterRequest {
  fullname: string;
  name: string;
  email: string;
  password: string;
  phone_number: string;
  kategory_id: string;
  reseller_qty_estimation: string;
  transaction_qty_estimation: string;
  referral_code: string
}

export const doRegister = <TRegister = TRegisterRequest>() => queryCommander<TRegister>({
  method: "POST",
  path: "/auth/register",
  queryType: "query",
  authenticated: false
});

interface TRequestOTPForgetPassword {
  phone_number: string;
}

export const doRequestForgetPasswordOTP = <TResetPassword = TRequestOTPForgetPassword>() => queryCommander<TResetPassword>({
  method: "POST",
  path: "/auth/request-otp",
  queryType: "query",
  authenticated: false
});


export const doRequestSubmitOPTForgetPassword = <TResetPassword = TOtpRequestRegister>() => queryCommander<TResetPassword>({
  method: "POST",
  path: "/auth/submit-otp",
  queryType: "query",
  authenticated: false
});

interface TRequestNewPassword {
  phone_number: string;
  password: string;
  re_password: string;
}

export const doRequestChangePassword = <TRenew = TRequestNewPassword>() => queryCommander<TRenew>({
  method: "POST",
  path: "/auth/change-password",
  queryType: "query",
  authenticated: false
});