import React from 'react'
import {Link} from 'react-router-dom'
import {FiMail, FiStar, FiUser, FiLogIn} from 'react-icons/fi'
import TokenBroker from '../../services/api/tokenBroker'

const AccountLinks = () => {

  const handleLogout = () => {
    TokenBroker.clear();
    window.location.href = '/login'
  };

  const items = [
    // {
    //   url: '/',
    //   icon: <FiMail size={18} className="stroke-current" />,
    //   name: 'Inbox',
    //   badge: {
    //     number: 2,
    //     color: 'bg-red-500 text-white'
    //   }
    // },
    // {
    //   url: '/',
    //   icon: <FiStar size={18} className="stroke-current" />,
    //   iconColor: 'default',
    //   name: 'Messages',
    //   badge: {
    //     number: 3,
    //     color: 'bg-blue-500 text-white'
    //   }
    // },
    // {
    //   url: '/profile',
    //   icon: <FiUser size={18} className="stroke-current" />,
    //   name: 'Profile',
    //   badge: null
    // },
    {
      url: '/login',
      icon: <FiLogIn size={18} className="stroke-current" />,
      name: 'Logout',
      badge: null, 
    }
  ]

  return (
    <div className="flex flex-col w-full">
      <ul className="list-none">
        {items.map((item, i) => (
          <li key={i} className="dropdown-item">
            <div
              onClick={handleLogout}
              className="flex flex-row cursor-pointer items-center justify-start w-full h-10 px-2">
              {item.icon}
              <span className="mx-2">{item.name}</span>
              {item.badge && (
                <span
                    className={`uppercase font-bold inline-flex text-center p-0 leading-none text-2xs h-4 w-4 inline-flex items-center justify-center rounded-full ${item.badge.color} ml-auto`}>
                  {item.badge.number}
                </span>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default AccountLinks
