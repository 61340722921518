import React from "react";
import { FiMenu } from "react-icons/fi";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { Link } from "react-router-dom";

const Logo = () => {
  const dispatch = useDispatch();
  const { config, leftSidebar } = useSelector(
    (state) => ({
      config: state.config,
      leftSidebar: state.leftSidebar,
    }),
    shallowEqual
  );
  const { collapsed } = { ...config };
  const { showLogo } = { ...leftSidebar };
  if (showLogo) {
    return (
      <div className="truncate logo">
        <Link
          to="/"
          className="flex flex-row items-center justify-center space-x-2"
        >
          <span>
            <img
              src="/rintiz-logo.svg"
              style={{ height: "40px" }}
              alt="WES Logo"
            />
          </span>
        </Link>
        <button
          onClick={() =>
            dispatch({
              type: "SET_CONFIG_KEY",
              key: "collapsed",
              value: !collapsed,
            })
          }
          className="block ml-auto mr-4 lg:hidden"
        >
          <FiMenu size={20} />
        </button>
      </div>
    );
  }
  return null;
};

export default Logo;
