import React from 'react';

function OrderStatus({data}) {
  let status = typeof data == 'undefined' ? null : data[0].toString().toUpperCase() + data.slice(1);

  return (
    <div id='order-status'>
      <div className='p-4 mb-6 border rounded-md shadow-custom'>
        <div className='font-bold text-md'>Status Order</div>
        <div className='inline-block px-2 py-1 mt-4 rounded-md bg-grey-200'>
          <div>{status}</div>
        </div>
      </div>
    </div>
  );
}

export default OrderStatus;
