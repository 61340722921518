import React from 'react';
import { useHistory } from 'react-router-dom';

import Breadcrumb from '../../../components/breadcrumbs';
import { AiOutlineCar } from 'react-icons/ai';
import { TiDocumentText } from 'react-icons/ti';
import { GiSellCard } from 'react-icons/gi';
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';

const Other = () => {
	const history = useHistory();

	const breadcrumbs = [
		{ title: 'List Produk', url: '/products', last: false },
		{ title: `Lainnya`, url: '', last: true },
	];

	return (
		<div className='px-20'>
			<Breadcrumb items={breadcrumbs} />
			<form>
				<div className='flex justify-between items-center'>
					<h4 className='py-3 font-bold'>
            Lainnya
					</h4>
					<div className='flex items-end'>
						<div>
							<button
								type='button'
								className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 border mt-8'
								onClick={() => history.push('/products')}
							>
								Batalkan
							</button>
						</div>
						<div className='ml-4'>
							<button
								type='submit'
								className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 bg-primary text-white'
							>
								Simpan
							</button>
						</div>
					</div>
				</div>
				<div className='grid grid-cols-8 mt-6'>
					<div className='col-span-2 px-4'>
						<div className='w-full p-4 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800'>
							<div className='flex flex-col space-y-6'>
								<a href='add' className='flex flex-row'>
									<TiDocumentText className='mr-2 text-lg stroke-current' />
									<span>Informasi Umum</span>
								</a>
								<a href='sales' className='flex flex-row'>
									<GiSellCard className='mr-2 text-lg stroke-current' />
									<span>Informasi Penjualan</span>
								</a>
								<a href='delivery' className='flex flex-row'>
									<AiOutlineCar className='mr-2 text-lg stroke-current' />
									<span>Pengiriman</span>
								</a>
								<a href='other' className='flex flex-row'>
									<HiOutlineDotsCircleHorizontal className='mr-2 text-lg stroke-current' />
									<span>Lainnya</span>
								</a>
							</div>
						</div>
					</div>
					<div className='col-span-6 px-4'>
            Other
					</div>
				</div>
			</form>
		</div>
	);
};

export default Other;
