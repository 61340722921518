import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Breadcrumb from '../../../components/breadcrumbs';
import { Button } from '../../../components/buttons/buttons';
import { formatNumberIDR } from '../../../functions/numbers';

const WithdrawFunds = (props) => {
	const history = useHistory();
	console.log(props);

	// const breadcrumbs = [
	// 	{ title: 'Riwayat Penarikan', last: false },
	// 	{ title: `Tarik Dana`, last: true },
	// ];

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm();

	return (
		<div>
			{/* <Breadcrumb items={breadcrumbs} /> */}
			<form>
				<div className='flex justify-between items-center'>
					<h4 className='py-3 font-bold'>Tarik Dana</h4>
					<div className='flex items-center'>
						<div>
							<button
								type='button'
								className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 border'
								onClick={() => props.buttonBack()}
							>
								Batalkan
							</button>
						</div>
						<div className='ml-4'>
							<Button
								text={'Proses'}
								onClick={handleSubmit()}
								className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 bg-primary text-white'
							/>
						</div>
					</div>
				</div>
				<div className='border rounded-lg p-4 mt-6'>
					<div className='text-base font-bold'>Informasi Penarikan</div>
					<div className='grid grid-cols-8 items-center mt-8'>
						<label className='col-span-2 text-xs'>Akun Penerima</label>
						{props.bankName !== undefined &&
						props.bankAccountName !== undefined &&
						props.bankAccount !== undefined ? (
							<div className='col-span-6 '>
								{props.bankName} {props.bankAccount} - {props.bankAccountName}
							</div>
						) : (
							<div className='col-span-6 '>-</div>
						)}
					</div>
					<div className='grid grid-cols-8 items-center mt-8'>
						<label className='col-span-2 text-xs'>Nominal yang ditarik</label>
						<div className='col-span-6'>
							<div className='flex w-full'>
								<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
									Rp.
								</div>
								<Controller
									control={control}
									name={`amount`}
									render={({ field: { onChange, name, value } }) => (
										<NumberFormat
											className='form-input border rounded-r-lg text-sm w-full'
											onChange={onChange}
											name={name}
											getInputRef={register(`amount`, {
												required: {
													value: true,
													message: 'Wajib Diisi',
												},
												min: {
													value: 0,
													message: 'Angka tidak boleh minus',
												},
												max: {
													value: props.wallet,
													message:
														'Nominal yang Anda masukan tidak boleh melebihi saldo Anda saat ini',
												},
											})}
											placeholder='Masukkan nominal'
											value={value}
										/>
									)}
								/>
							</div>
							{errors?.amount?.message && (
								<p className='text-red-500 mt-2'>*{errors?.amount?.message}</p>
							)}
						</div>
					</div>
					<div className='grid grid-cols-8 items-center mt-8'>
						<label className='col-span-2 text-xs'>Fee Transfer</label>
						<div className='col-span-6 '>{formatNumberIDR(4000)}</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default WithdrawFunds;
