import queryCommander from "../queryCommander";

export const getCategories = () => queryCommander({
  method: "GET",
  path: "/category/?limit=20&page=1",
  queryType: "query",
  authenticated: false
});

interface TCategoryRequest {
  limit: number;
  page: number;
}

export const getListCategories = () => queryCommander({
  method: "GET",
  path: "/category/list",
  queryType: "query",
  authenticated: true,
})

interface TCreateCategoryRequest {
  parent_id: 0 | number;
  article_hierarchy_parent: "" | string;
  article_hierarchy: "" | string;
  attribute_set_id: 0 | number;
  attribute_variant_set_id: 0 | number;
  name: string;
  path: "" | string;
  description: string;
  image_app: "/v1586166234/banner-dev/b_1586166233_1.png" | string;
  image_web: "" | string;
  image: "" | string;
  include_in_menu: 0 | number;
  status: 10 | number;
  order_no: 0 | number;
  category_id?: 0 | number;
}
export const createCategoryRequest = <TCategory = TCreateCategoryRequest>() => queryCommander<TCategory>({
  method: "POST",
  path: "/category/create",
  queryType: "query",
  authenticated: true,
})

export const updateCategoryRequest = <TCategory = TCreateCategoryRequest>(id: number) => queryCommander<TCategory>({
  method: "POST",
  path: `/category/update/${id}`,
  queryType: "query",
  authenticated: true,
})

export const deleteCategoryRequest = (id: any) => queryCommander({
  method: "DELETE",
  path: `/category/delete/${id}`,
  queryType: "query",
  authenticated: true,  
})