import React, { useEffect, useMemo, useState } from "react";
import toast from 'react-hot-toast';
import { FiEdit3 } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import SwitchComponent from 'react-switch';
import DOMPurify from "dompurify";
import { queryClient } from "../../App";
import { getColor } from "../../functions/colors";
import { doGetMembershipType, doUpdateStatusMembershipType } from "../../services/api/command/membership.command";

function MembershipTypeSwitch({initialState = false, code, status}) {
  let offColor = `bg-blue-grey-100`;
	let offHandleColor = 'bg-blue-grey-200';
  const history = useHistory();

	const [checked, setChecked] = useState(initialState);

  const handleChange = () => {
    const body = {
      kode: code,
      status: status === 'active' ? 'inactive' : 'active'
    }

    doUpdateStatusMembershipType()
      .command(body)
      .then((response) => {
        toast.success('Status Membership Type berhasil diubah');
        queryClient.invalidateQueries('update-status-membership');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })

    setChecked(!checked)
  }

  return (
    <div className='flex'>
      <SwitchComponent
        onChange={() => handleChange(!checked)}
        checked={checked}
        onColor='#B1D5DB'
        onHandleColor='#2FAEC3'
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        height={8}
        width={38}
        className="react-switch "
      />
    </div>
  )
}

function MembershipType() {
  const history = useHistory();
  const membershipTypes = doGetMembershipType().query('membership-type').data?.data.data;
  
  const handleEdit = (data, type) => {
    localStorage.setItem('membership-type', JSON.stringify(data));
    history.push(`/membership/type/${type}`);
  }


  return (
    <div className="px-32">
      <h4 className="font-bold">Tipe Membership</h4>
      {typeof membershipTypes !== 'undefined' ? Object.entries(membershipTypes).map(([key, value]) => {
        return (
          <div className="mt-8 border shadow">
            <div className="flex justify-between px-4 py-3 text-xs border-b">
              <div>{value.status === 'active' ? 'Nonaktifkan' : 'Aktifkan'} {value.label} Membership</div>
              <MembershipTypeSwitch initialState={value.status === 'active' ? true : false} code={value.kode} status={value.status} />
            </div>
            <div className="flex justify-between px-4 py-3">
              <div>
                <div className="text-base font-semibold">{value.label} Membership</div>
                <div className="mb-2 text-xs" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(value.description) }} />
              </div>
              <div>
                <button
                  onClick={() => handleEdit(value, key)}
                  className="flex items-center px-4 py-2 text-sm border rounded-md btn gap-x-2"
                >
                  <FiEdit3 className="text-lg stroke-current" />
                  Edit
                </button>
              </div>
            </div>
            {value.kode === 1 && (
              <>
                <div className="p-3 m-3 bg-blue-100 rounded">
                  <span className="block mb-2 font-bold">Informasi Potongan Membership</span>
                  <ul className="list-disc list-inside text-grey-700">
                    <li>Pendaftaran member/reseller yang berbayar, akan dipotong biaya platform 8%.</li>
                    <li>Terdapat tambahan biaya metode pembayaran yang besarannya bervariasi.</li>
                  </ul>
                </div>
              </> 
            )} 
          </div>
        )
      }) : ''}
    </div>
  );
}

export default MembershipType;
