import queryCommander from "../queryCommander";

export const doGetProfile = () => queryCommander({
  method: "GET",
  path: "/brand/detail",
  queryType: "query",
  authenticated: true
});

interface TSubdomainCheckRequest {
  subdomain: string
}

export const doCheckAvailableSubdomain = <TSubdomainCheck = TSubdomainCheckRequest>() => queryCommander<TSubdomainCheck>({
  method: "POST",
  path: "/brand/check_available_subdomain",
  queryType: "query",
  authenticated: true
});

interface TUpdateProfileRequest {
  name: string,
  phone: string,
  image: string,
  banner: string,
  email: string,
  address: string,
  bank_account_name: string,
  bank_account_number: string,
  bank_name: string,
  status: number,
  payment_brandowner_id: number,
  subdomain: string
  supported_couriers: TSupportedCourierRequest[]
}

interface TSupportedCourierRequest {
  code: string,
  service_code: string
}

export const doUpdateProfile = <TProfile = TUpdateProfileRequest>() => queryCommander<TProfile>({
  method: 'PUT',
  path: '/brand/update',
  queryType: 'query',
  authenticated: true
})

interface ISubdomain {
  brandowner_id: number,
  subdomain: string
}

export const doUpdateSubdomain = <Subdomain = ISubdomain>() => queryCommander<Subdomain>({
  method: 'PUT',
  path: '/brand/update_subdomain',
  queryType: 'query',
  authenticated: true
})