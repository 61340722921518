import React, { useState } from 'react';
import { useParams } from "react-router-dom";
import { useHistory } from 'react-router-dom';
import formatPrice from '../../../functions/formatPrice';
import { doGetDetailInvoiceBrandSubscription } from '../../../services/api/command/brand-subscription.command';
import { Button } from '../../../components/buttons/buttons';
import { doGetProfile } from '../../../services/api/command/profile.command';
import ucwords from '../../../functions/ucwords';
import FeatureComingSoonModal from '../../common/modal/feature-coming-soon';

const SubscriptionInvoice = () => {
  let { id } = useParams();

  const invoice = doGetDetailInvoiceBrandSubscription(id).query('detail invoice').data?.data.data;
  const profile = doGetProfile().query('profile').data?.data.data;

  return (
    <div className='flex items-center justify-center'>
      <div className='w-3/5'>
        <div className='flex items-center justify-between overflow-x-scroll'>
          <h5 className='py-3 font-bold'>Invoice Pembelian Paket Subskripsi</h5>
        </div>
        <div className='flex justify-between py-3'>
          <div className='flex flex-col w-1/2'>
            {/* logo */}
            <span>PT. Rintiz Jaya Abadi</span>
            <span>Go-Work Menara Rajawali Ground Floor - Jl. DR. Ide Anak Agung RT.5/RW.2, Kuningan, Kuningan Tim., Kecamatan Setiabudi, Jakarta, Daerah Khusus Ibukota Jakarta 12950</span>
          </div>
          <div className='flex justify-end w-1/2'>
            <span>No. Invoice: {invoice?.transaction_number}</span>
          </div>
        </div>
        <div className='w-full py-3'>
          <span className='block mb-1 font-bold'>Dikeluarkan untuk</span>
          <div className='flex flex-col w-2/5'>
            <span>Nama Pengguna : {profile?.bank_account_name}</span>
            <span>Nama Perusahaan : {profile?.name}</span>
            <span>Email : {profile?.email}</span>
            <span>No. Telp : {profile?.phone}</span>
          </div>
        </div>
        <div className='w-full py-3'>
          <span className='block mb-1 font-bold'>Detail Pembelian</span>
          <div className='flex justify-between py-1 border-b border-grey-300'>
            <span>Tanggal & Waktu Pembelian</span>
            <span>{invoice?.created_at}</span>
          </div>
          <div className='flex justify-between py-1 border-b border-grey-300'>
            <span>Produk</span>
            <span>{invoice?.subscription_package_name} ({invoice?.duration} Bulan) dengan maksimal {invoice?.subscription_package_max_agent} reseller</span>
          </div>
          <div className='flex justify-between py-1 border-b border-grey-300'>
            <span>Jumlah</span>
            <span>1</span>
          </div>
          <div className='flex justify-between py-1 font-bold border-b border-grey-300'>
            <span>TOTAL</span>
            <span>{invoice?.subscription_package_price ? formatPrice(invoice?.subscription_package_price) : 0}</span>
          </div>
        </div>
        <div className='w-full py-3'>
          <span className='block mb-1 font-bold'>Detail Pembayaran</span>
          <div className='flex justify-between py-1 border-b border-grey-300'>
            <span>Metode Pembayaran</span>
            <span>Bank Transfer BCA</span>
          </div>
          <div className='flex justify-between py-1 border-b border-grey-300'>
            <span>Status</span>
            <span className='font-bold'>{invoice?.status ? ucwords(invoice?.status) : ''}</span>
          </div>
        </div>
        <div className='w-full py-3'>
          <FeatureComingSoonModal 
            text={'Cetak Invoice'}
            className='px-4 py-2 border rounded text-primary border-primary'
          />
        </div>
      </div>
    </div>
  );
}

export default SubscriptionInvoice;
