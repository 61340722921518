import React, { useEffect, useState } from 'react';
import {
	FiCheckCircle,
	FiDownload,
	FiEdit,
	FiFilter,
	FiTrash,
	FiUser,
} from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CircularBadge } from '../../components/badges';
import Datatable from '../../components/datatable';
import Search from '../../components/navbar-1/search';
import countries from '../../json/countries.json';

import iconWallet from './images/icon-wallet.jpg';
import emptyWithdraw from './images/empty-withdraw.jpg';
import { doGetWallet } from '../../services/api/command/brand-subscription.command';
import { doGetProfile } from '../../services/api/command/profile.command';
import { formatNumberIDR } from '../../functions/numbers';

// components
import WithdrawFunds from './components/withdraw-funds';

function HistoryWithdrawal() {
	const [selectedMenu, setSelectedMenu] = useState('');
	let wallet = doGetWallet().query(['wallet']);

	const profile = doGetProfile().query('profile').data?.data.data;

	if (wallet.isSuccess && !wallet.isLoading) {
		wallet = wallet.data.data.data.amount;
	}

	return (
		<div className='px-20'>
			{selectedMenu === '' && (
				<>
					<div className='relative flex items-center justify-between overflow-x-scroll'>
						<h4 className='py-3 font-bold'>Riwayat Penarikan</h4>
						<div className='flex items-center border rounded-lg p-4'>
							<img
								src={iconWallet}
								alt='icon-wallet'
								width={40}
								height={40}
								className='mr-2'
							/>
							<div className='mr-2'>
								<div className='text-xs'>Sisa saldo Anda</div>
								<div className='text-xl font-bold'>
									{formatNumberIDR(wallet)}
								</div>
							</div>

							<button
								type='button'
								className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
								onClick={() => setSelectedMenu('withdraw-funds')}
							>
								Tarik Dana
							</button>
						</div>
					</div>
					<div className='mt-8'>
						<div className='text-center'>
							<img
								src={emptyWithdraw}
								alt='empty-withdraw'
								className='m-auto'
							/>
							<div className='text-sm text-grey-400 mt-2'>
								Belum ada riwayat penarikan saldo
							</div>
						</div>
					</div>
				</>
			)}
			{selectedMenu === 'withdraw-funds' && (
				<WithdrawFunds
					bankName={profile?.bank_name}
					bankAccountName={profile?.bank_account_name}
					bankAccount={profile?.bank_account_number}
					buttonBack={() => setSelectedMenu('')}
					wallet={wallet}
				/>
			)}
		</div>
	);
}

export default HistoryWithdrawal;
