import queryCommander from "../queryCommander";

interface TOrderListRequest {
  limit: number;
  page: number;
  q: string;
  status: string;
  agent_id: number;
  order: string;
  orderby: string;
}

export const doGetOrderList = <TOrderList = TOrderListRequest>() => queryCommander<TOrderList>({
  method: "GET",
  path: "/order/list",
  queryType: "query",
  authenticated: true
});

type TOrderDetailResponse = {}

export const doGetOrderDetail = (id: string) => queryCommander<TOrderDetailResponse>({
  method: "GET",
  path: `/order/${id}`,
  queryType: "query",
  authenticated: true
})

type TInvoiceDetailResponse = {}

export const doGetInvoiceDetail = (id: string) => queryCommander<TInvoiceDetailResponse>({
  method: "GET",
  path: `/order/invoice/${id}`,
  queryType: "query",
  authenticated: true
})

interface TUpdateStatusInvoiceRequest {
  sales_invoice_no: string,
  status: string,
  shipping_awb: string,
  shipping_awb_image_url: string,
  courier: string,
  service_type: string,
  autocreate_shipping_order: number,
  note: string,
  brandowner_id: number
}

export const doUpdateStatusInvoice = <TUpdateStatusInvoice = TUpdateStatusInvoiceRequest>() => queryCommander<TUpdateStatusInvoice>({
  method: "PUT",
  path: "/order/invoice/update_status",
  queryType: "query",
  authenticated: true
});

export const doGetOrderDelivered = () => queryCommander({
  method: "GET",
  path: "/order/total-invoice?status=completed",
  queryType: "query",
  authenticated: true
});

interface IOrderType {
  type: 'daily' | 'weekly' | 'monthly' | 'yearly';
}

export const doGetTotalOrderByTime = (props: IOrderType) => queryCommander({
  method: "GET",
  path: `/order/total-order?type=${props.type}`,
  queryType: "query",
  authenticated: true
});
