import React, { useState } from "react";
import { TiDocument, TiUserOutline, TiCogOutline } from 'react-icons/ti';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Previews from "../../components/dropzone/previews";

const MembershipAdd = () => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <div className="w-full pt-3 mb-6">
        <div className="flex flex-row items-end justify-between mb-4">
          <div className="flex flex-col">
            <div className="flex flex-row my-3">
              <a href="#" className="mr-2 text-xs font-light text-primary">List Membership</a>
              <div className="mr-2 text-xs font-light text-grey-500">/</div>
              <div className="mr-2 text-xs font-light text-grey-500">Tambah Mitra</div>
            </div>
            <div className="text-xl font-bold">Tambah Mitra</div>
          </div>
          <div className="space-x-2 shrink-0">
            <button className="px-4 py-2 space-x-1 text-xs font-bold uppercase bg-transparent border rounded-lg text-grey-500 border-grey-500 hover:text-grey-700 hover:border-grey-700">
              <span>Batalkan</span>
            </button>
            <button className="px-4 py-2 text-xs font-bold text-white uppercase bg-primary rounded-lg hover:bg-[color:#175761] space-x-1">
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full mb-2 space-x-5">
        <div className="w-1/5"></div>
        <div className="w-3/5 space-y-6">
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="informasi-umum">
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Profile</label>
              <div className="flex flex-col items-start w-full">
                <Previews />
              </div>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Full Name</label>
              <div className="flex w-full">
                <input placeholder="First Name" type="text" name="first_name" className="block w-full bg-white border-gray-300 rounded-md rounded-r-none form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
                <input placeholder="Last Name" type="text" name="last_name" className="block w-full bg-white border-gray-300 rounded-md rounded-l-none form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
              </div>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">No. Handphone</label>
              <input placeholder="+62 XXX-XXXX-XXXX" type="text" name="name" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Email</label>
              <input placeholder="budi@example.com" type="email" name="name" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Level Membership</label>
              <select name="select" className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm">
                <option value="">Pilih</option>
                <option value="basic">Basic</option>
                <option value="silver">Silver</option>
                <option value="gold">Gold</option>
                <option value="platinum">Platinum</option>
              </select>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Jangka Waktu</label>
              <select name="select" className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm">
                <option value="">Pilih</option>
                <option value="monthly">Bulanan</option>
                <option value="oneyear">Satu Tahun</option>
                <option value="forever">Seumur Hidup</option>
              </select>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Opsi (Jangka Waktu)</label>
              <select name="select" className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm">
                <option value="">Pilih</option>
                <option value="1">1</option>
                <option value="3">3</option>
                <option value="6">6</option>
                <option value="12">12</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MembershipAdd;