import React from 'react';
import { useTable, useSortBy, usePagination, useRowSelect } from 'react-table';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input
        type='checkbox'
        ref={resolvedRef}
        {...rest}
        className='w-4 h-4 form-checkbox'
      />
    );
  }
);

const Datatable = ({
  columns,
  data,
  checkbox = true,
  disablePagination = false,
  totalPage,
  handleChangePage,
  pageSize,
  setPageSize,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 25 } // be aware of this
    },
    useSortBy,  
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <>
              {checkbox && (
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              )}
            </>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <>
              {checkbox && (
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              )}
            </>
          ),
        },
        ...columns,
      ]);
    }
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className='table'>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  <div className='flex flex-row items-center justify-start'>
                    <span className='text-blue-grey-500'>
                      {column.render('Header')}
                    </span>
                    {/* Add a sort direction indicator */}
                    <span className='ml-auto'>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FiChevronDown className='stroke-current text-2xs' />
                        ) : (
                          <FiChevronUp className='stroke-current text-2xs' />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      
      {!disablePagination && (
        <div className='flex flex-row items-center justify-between p-4 bg-white border-t rounded-lg'>
          <div className='flex flex-start'>
            <div className='text-blue-grey-400'>Tampilkan</div>
            <select
              defaultValue={pageSize}
              className='pl-4 text-sm shadow-none outline-none text-blue-grey-400 focus:shadow-none'
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 25, 50, 100].map((size) => (
                <option selected key={size} value={size}>
                  {size}
                </option>
              ))}
            </select>
            <span className='ml-3 text-blue-grey-400'>of {pageSize}</span>
          </div>
          <ReactPaginate
            breakLabel='...'
            nextLabel='Selanjutnya'
            className='flex flex-wrap items-center justify-start space-x-2 text-blue-grey-400 pagination'
            onPageChange={handleChangePage}
            activeClassName='bg-blue-grey-50 p-1 rounded-lg'
            pageLinkClassName='px-2 py-1'
            pageRangeDisplayed={4}
            pageCount={totalPage}
            previousLabel='Sebelumnya'
            previousClassName='pr-4'
            nextClassName='pl-4'
            renderOnZeroPageCount={null}
          />
        </div>
      )}
    </>
  );
};

export default Datatable;
