import React from 'react';
import { FiCheckCircle, FiDownload, FiEdit, FiFilter, FiTrash, FiUser } from 'react-icons/fi';
import { TiCreditCard, TiUserOutline } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import { CircularBadge } from '../../components/badges';
import Datatable from '../../components/datatable';
import Previews from '../../components/dropzone/previews';
import Modal from '../../components/modals/modal';
import Search from '../../components/navbar-1/search';
import { DefaultTabs } from '../../components/tabs';
import countries from '../../json/countries.json';

function AddOn() {
  const history = useHistory();

  const data = [
    [
      <div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Administrasi</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Qiscus</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>System Setup Support</li>
                  <li>Chat Admin Customer Support: Manual and Bot</li>
                  <li>Stock Keeping and Performance Reporting</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Akuntansi</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Moodah</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Accounting Report</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Fotografi Produk</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Mitra</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Product Photography</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Custom Domain</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Custom Domain Service</li>                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Logistik</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Keeppack/Haistar</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Admin full-processing fulfillment</li>
                  <li>Commerce enablement and packing</li>
                  <li>Warehousing</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Marketing, Komunitas dan Konsultasi</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz (Wooniversity)</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Community/Reseller Development services</li>
                  <li>Community Activation services</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Pro</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz (Wooniversity) dan Sosiago/Optify</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Community/Reseller Development services</li>
                  <li>Community Activation services</li>
                  <li>Training Collab services</li>
                  <li>Marketing bundle</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Finansial</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Pinjaman Usaha</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh BRI/Mandiri/Koinworks/Crowde</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Pinjaman Usaha</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Fasilitas Reseller</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Kredivo</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Fasilitas Cicilan Reseller</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Asuransi</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh PasarPolis/LifePa</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Asuransi Umum</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Layanan Custom Domain</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Custom Domain Service</li>                
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ],
    [
      <div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Administrasi</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Qiscus</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>System Setup Support</li>
                  <li>Chat Admin Customer Support: Manual and Bot</li>
                  <li>Stock Keeping and Performance Reporting</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Akuntansi</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Moodah</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Accounting Report</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Fotografi Produk</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Mitra</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Product Photography</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Custom Domain</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Custom Domain Service</li>                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ],
    [
      <div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Logistik</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz dan Keeppack/Haistar</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Admin full-processing fulfillment</li>
                  <li>Commerce enablement and packing</li>
                  <li>Warehousing</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ],
    [
      <div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Marketing, Komunitas dan Konsultasi</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Dasar</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz (Wooniversity)</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Community/Reseller Development services</li>
                  <li>Community Activation services</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Pro</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz (Wooniversity) dan Sosiago/Optify</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Community/Reseller Development services</li>
                  <li>Community Activation services</li>
                  <li>Training Collab services</li>
                  <li>Marketing bundle</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ],
    [
      <div>
        <div className="w-full pt-3 mb-6">
          <div className="text-xl font-bold">Layanan Finansial</div>
          <div className='grid grid-cols-2 mt-5 gap-x-12 gap-y-5'>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Pinjaman Usaha</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh BRI/Mandiri/Koinworks/Crowde</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Pinjaman Usaha</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Fasilitas Reseller</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Kredivo</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Fasilitas Cicilan Reseller</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Paket Asuransi</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh PasarPolis/LifePa</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Asuransi Umum</li>
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            <div className='w-full p-4 border rounded-lg shadow-lg border-grey-300'>
              <div className='flex flex-col'>
                <span className='mb-1 text-base font-bold'>Layanan Custom Domain</span>
                <span className='mb-5 text-xs text-grey-500'>Disediakan oleh Woobiz</span>
                <span className='text-xs'>Benefit:</span>
                <ul className='text-xs list-disc list-inside'>
                  <li>Custom Domain Service</li>                
                </ul>
                <span className='px-2 py-1 my-5 font-bold rounded-lg bg-blue-50'>Rp. 299.000/bulan</span>
                <div className='flex flex-row justify-end'>
                  <Modal
                    color="primary"
                    modalName={
                      <div className='px-4 py-2 text-white rounded-md btn bg-primary'>Permintaan Penawaran</div>
                    }
                    body={
                      <div className='mt-5 space-y-5 text-center'>
                        <span className='block mb-2 text-lg font-bold'>Permintaan Layanan Tambahan</span>
                        <span className='w-50'>Lanjutkan proses permintaan layanan tambahan paket akuntansi yang disediakan oleh Woobiz dan Mitra?</span>
                        <div className='flex justify-center space-x-2'>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-primary'>Lanjutkan</a>
                          <a href='#' className='px-4 py-2 text-sm text-white btn btn-rounded bg-grey-500'>Batalkan</a>
                        </div>
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ]
  ]

  const tabs = [
    { index: 0, title: 'Semua', active: true, content: data[0] },
    { index: 1, title: 'Layanan Administrasi', active: false, content: data[1] },
    { index: 2, title: 'Layanan Logistik', active: false, content: data[2] },
    { index: 3, title: 'Layanan Marketing, Komunitas dan Konsultasi', active: false, content: data[3] },
    { index: 4, title: 'Layanan Finansial', active: false, content: data[4] },
  ];

  return (
    <>
      <div className="w-full pt-3 mb-6">
        <div className="flex flex-row items-end justify-between mb-4">
          <div className="flex flex-col">
            <div className="text-xl font-bold">Tambahan Layanan Membership</div>
          </div>
        </div>
      </div>
      <div className='px-10'>
        <div className='flex flex-wrap'>
          <div className='w-full'>
            <DefaultTabs tabs={tabs} />
          </div>
        </div>
      </div>
    </>
  );
}

export default AddOn;
