import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/buttons/buttons';
import phoneSanity from '../../functions/phoneSanity';
import Layout from '../../layouts/centered';
import { doRequestForgetPasswordOTP } from '../../services/api/command/auth.command';
import LoginImage from './assets/login.jpg';

/**
 *
 * @URL /forgot-password
 */

const ForgotPassword = () => {
  const history = useHistory();
  const [phone, setPhone] = useState('');
  const [loading, setLoading] = useState('');

  const handleChange = (e) => {
    setPhone(e.target.value);
  };

  const submitPhone = () => {
    setLoading(true);
    doRequestForgetPasswordOTP()
      .command({
        phone_number: phoneSanity(phone),
      })
      .then((res) => {
        setLoading(false);
        history.push({
          pathname: '/otp-forgot-password',
          state: {
            phone_number: phone,
          },
        });
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err.response.data.error.message);
      });
  };

  return (
    <Layout className='flex content-between'>
      <div className='flex w-full justify-center'>
        <div className='w-1/2 my-auto mx-32'>
          <div className='flex'>
            <span>
              <img
                src='/rintiz-logo.svg'
                style={{ height: '38px' }}
                alt='WES Logo'
              />
            </span>
          </div>
          <div className='mt-16'>
            <div className='text-base'>
              Masukkan nomor whatsapp untuk mendapatkan kode OTP
            </div>
            <label className='text-base'>No.Whatsapp</label>
            <input
              placeholder='Masukkan no.whatsapp '
              type='number'
              onChange={handleChange}
              name='phone'
              className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
            />
          </div>
          <div className='mt-4'>
            <Button
              loading={loading}
              disabled={!phone || phone.length < 10 || phone.length > 13}
              onClick={submitPhone}
              text={'Meminta Kode OTP'}
              className='btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-primary text-white'
              disabledClassname='btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-secondary text-black'
            />
          </div>
        </div>
      </div>
      {/* <img src={LoginImage} className='h-full w-auto' alt='...' /> */}
    </Layout>
  );
};

export default ForgotPassword;
