import queryCommander from "../queryCommander";

interface TBrandSubscriptionListRequest {
  limit: number;
  page: number;
  sort_by: string;
  sort_type: string;
}

export const doGetBrandSubscriptionList = <TBrandSubscriptionList = TBrandSubscriptionListRequest>() => queryCommander<TBrandSubscriptionList>({
  method: "GET",
  path: "/brand/package/list",
  queryType: "query",
  authenticated: true
});

interface TBrandSubscriptionRequest {
  package_id: number,
  duration: number
}

export const doSubscribe = <TBrandSubscription = TBrandSubscriptionRequest>() => queryCommander<TBrandSubscription>({
  method: "POST",
  path: "/brand/subscription/subscribe",
  queryType: "query",
  authenticated: true
})

export const doGetBrandSubscription = () => queryCommander({
  method: "GET",
  path: "/brand/subscription/info",
  queryType: "query",
  authenticated: true
})

interface TBrandSubscriptionHistoryListRequest {
  limit: number;
  page: number;
  sort_by: string;
  sort_type: string;
}

export const doGetBrandSubscriptionHistoryList = <TBrandSubscriptionHistoryList = TBrandSubscriptionHistoryListRequest>() => queryCommander<TBrandSubscriptionHistoryList>({
  method: "GET",
  path: "/brand/subscribe_invoice/list",
  queryType: "query",
  authenticated: true
});

export const doGetTotalAgent = () => queryCommander({
  method: "GET",
  path: "/agent/count?status=10",
  queryType: "query",
  authenticated: true
})

export const doGetDetailInvoiceBrandSubscription = (id: number) => queryCommander({
  method: "GET",
  path: `/brand/subscribe_invoice/detail/${id}`,
  queryType: "query",
  authenticated: true
});

export const doGetWallet = () => queryCommander({
  method: "GET",
  path: `/brand/wallet/info`,
  queryType: "query",
  authenticated: true
});
