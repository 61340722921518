import React, { useState } from "react";
import { TiDocument, TiUserOutline, TiCogOutline } from 'react-icons/ti';
import DatePicker from "react-datepicker";
import { useHistory } from 'react-router-dom';
import "react-datepicker/dist/react-datepicker.css";
import { Controller, useForm } from "react-hook-form";
import { doCouponCreate } from "../../services/api/command/coupon.command";

const CouponAdd = () => {
  const {
    control,
		register,
		handleSubmit,
		getValues,
		setValue,
		formState: { errors },
	} = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [expiredDate, setExpiredDate] = useState(new Date());
  const history = useHistory();

  let sd = new Date(getValues('start_date') || startDate);
  let formattedStartDate = sd.getFullYear() + "-" + ("0" + (sd.getMonth()+1)).slice(-2) + "-" + ("0" + sd.getDate()).slice(-2);

  let ed = new Date(getValues('end_date') || expiredDate);
  let formattedExpiredDate = ed.getFullYear() + "-" + ("0" + (ed.getMonth()+1)).slice(-2) + "-" + ("0" + ed.getDate()).slice(-2);

  const handleCreate = () => {
    const body = {
      code: getValues('code'),
      name: getValues('name'),
      description: getValues('description') || '',
      category: getValues('category'),
      type: getValues('type'),
      value: parseInt(getValues('value').replace('.', '')),
      maximum_value: parseInt(getValues('maximum_value').replace('.', '')),
      start_date: formattedStartDate,
      expired_date: formattedExpiredDate,
      minimum_spend: parseInt(getValues('minimum_spend').replace('.', '')),
      maximum_spend: parseInt(getValues('maximum_spend').replace('.', '')),
      maximum_use: 999,
      maximum_use_coupon: parseInt(getValues('maximum_use_coupon').replace('.', '')),
      individual_use: getValues('individual_use') == 'no' ? false : true,
      allow_all_products: true,
      allowed_products: [],
      exclude_products: [],
      allow_all_brands: true,
      allowed_brands: [],
      exclude_brands: [],
      requirement: getValues('requirement'),
      how_to_use: getValues('how_to_use'),
      agent_id: 0,
      image: ''
    }

    doCouponCreate()
			.command(body)
			.then((res) => {
        console.log(res);
        alert('Kupon berhasil ditambahkan');
        history.push("/coupons");
      })
			.catch((err) => console.log(err));
	};

  return (
    <>
    <form onSubmit={handleSubmit(handleCreate)}>
      <div className="w-full pt-3 mb-6">
        <div className="flex flex-row items-end justify-between mb-4">
          <div className="flex flex-col">
            <div className="flex flex-row my-3">
              <span className="mr-2 text-xs font-light cursor-pointer text-primary" onClick={() => history.push("/coupons")}>List Kupon</span>
              <div className="mr-2 text-xs font-light text-grey-500">/</div>
              <div className="mr-2 text-xs font-light text-grey-500">Tambah Kupon</div>
            </div>
            <div className="text-xl font-bold">Tambah Kupon</div>
          </div>
          <div className="space-x-2 shrink-0">
            <button className="px-4 py-2 space-x-1 text-xs font-bold uppercase bg-transparent border rounded-lg text-grey-500 border-grey-500 hover:text-grey-700 hover:border-grey-700">
              <span>Batalkan</span>
            </button>
            <button type="submit" className="px-4 py-2 text-xs font-bold text-white uppercase bg-primary rounded-lg hover:bg-[color:#175761] space-x-1">
              <span>Simpan</span>
            </button>
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full mb-2 space-x-5">
        <div className="w-1/4">
          <div className="w-full p-4 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800">
            <div className="flex flex-col space-y-6">
              <a href="#informasi-umum" className="flex flex-row text-primary">
                <TiDocument className='mr-2 text-lg stroke-current' />
                <span>Informasi Umum</span>
              </a>
              <a href="#pembatasan-penggunaan" className="flex flex-row">
                <TiUserOutline className='mr-2 text-lg stroke-current' />
                <span>Pembatasan Penggunaan</span>
              </a>
              <a href="#ketentuan-cara-penggunaan" className="flex flex-row">
                <TiCogOutline className='mr-2 text-lg stroke-current' />
                <span>Ketentuan & Cara Penggunaan</span>
              </a>
            </div>
          </div>
        </div>
        <div className="w-3/4 space-y-6">
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="informasi-umum">
            <div className="mb-5 font-bold">Informasi Umum</div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nama Kupon</label>
              <input 
                placeholder="Masukkan nama kupon" 
                type="text" 
                name="name" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('name', { required: true })}
              />
              {errors.name && errors.name.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Kode Kupon</label>
              <input 
                placeholder="Masukkan kode kupon" 
                type="text" 
                name="code" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('code', { required: true })}
              />
              {errors.code && errors.code.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Kategori</label>
              <select 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('category', { required: true })}
              >
                <option value="">Pilih</option>
                <option value="cashback">Komisi</option>
                <option value="discount">Potongan</option>
                <option value="ongkir">Potongan Ongkir</option>
                <option value="gps">GPS</option>
              </select>
              {errors.category && errors.category.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Deskripsi</label>
              <textarea 
                rows="4" 
                placeholder="Masukkan deskripsi kupon" 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-textarea focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('description')}
              ></textarea>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Tanggal Berlaku</label>
              <div className="flex flex-row items-center w-full space-x-10">
                <Controller
                  control={control}
                  name='start_date'
                  render={({ field }) => (
                    <DatePicker 
                      selected={startDate}
                      onChange={(date) => {field.onChange(date); setStartDate(date)}}
                      placeholderText="Pilih tanggal" 
                      className="w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                    />
                  )}
                />
                <div className="w-2/3 text-center">sampai dengan</div>
                <Controller
                  control={control}
                  name='expired_date'
                  render={({ field }) => (
                    <DatePicker 
                      selected={expiredDate}
                      onChange={(date) => {field.onChange(date); setExpiredDate(date)}}
                      placeholderText="Pilih tanggal" 
                      className="w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="pembatasan-penggunaan">
            <div className="font-bold">Pembatasan Penggunaan</div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Jenis Kupon</label>
              <select 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('type', { required: true })}
              >
                <option value="">Pilih</option>
                <option value="percentage">Persentase</option>
                <option value="fixed">Fixed Rate</option>
              </select>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nilai Kupon</label>
              <input 
                placeholder="%" 
                type="text" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('value', { required: true })}
              />
              {errors.value && errors.value.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nilai Maksimal Kupon</label>
              <input 
                placeholder="Masukkan nilai maksimal" 
                type="text" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('maximum_value', { required: true })}
              />
              {errors.maximum_value && errors.maximum_value.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Minimum Pembelian</label>
              <input 
                placeholder="Minimum pembelian" 
                type="text" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('minimum_spend', { required: true })}
              />
              {errors.minimum_spend && errors.minimum_spend.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Maksimum Pembelian</label>
              <input 
                placeholder="Maksimum pembelian" 
                type="text" 
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('maximum_spend', { required: true })}
              />
              {errors.maximum_spend && errors.maximum_spend.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Jumlah Kupon</label>
              <input 
                placeholder="Masukkan jumlah kupon" 
                type="text"
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('maximum_use_coupon', { required: true })}
              />
              {errors.maximum_use_coupon && errors.maximum_use_coupon.type === 'required' && (
                <div className='pt-2 text-red-500'>*This is required</div>
              )}
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Kupon Bisa Digabung?</label>
              <fieldset className="flex flex-row items-center justify-start w-full space-x-2">
                <div className="flex flex-row items-center justify-start space-x-2">
                  <input 
                    type="radio" 
                    className="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded-full dark:bg-gray-800 dark:border-gray-700 form-radio focus:ring-blue-500" value="yes" 
                    {...register('individual_use')}
                  />
                  <label className="block text-sm text-gray-700">Ya</label>
                </div>
                <div className="flex flex-row items-center justify-start space-x-2">
                  <input 
                    type="radio" 
                    className="w-4 h-4 text-blue-600 bg-white border-gray-300 rounded-full dark:bg-gray-800 dark:border-gray-700 form-radio focus:ring-blue-500" value="no" 
                    {...register('individual_use')}
                  />
                  <label className="block text-sm text-gray-700">Tidak</label>
                </div>
              </fieldset>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Berlaku Untuk Produk</label>
              <div className="block w-full">
                <select  
                  className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                  disabled
                  {...register('allowed_products[]')}
                >
                  <option value="">Pilih</option>
                  <option value="SKU01">Produk 01</option>
                  <option value="SKU02">Produk 02</option>
                </select>
                <div className='block w-full pt-2 text-red-500'>*Fitur ini masih dalam pengembangan</div>
              </div>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Pengecualian Produk</label>
              <div className="block w-full">
                <select 
                  className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                  disabled
                  {...register('exclude_products[]')}
                >
                  <option value="">Pilih</option>
                  <option value="SKU01">Produk 01</option>
                  <option value="SKU02">Produk 02</option>
                </select>
                <div className='block w-full pt-2 text-red-500'>*Fitur ini masih dalam pengembangan</div>
              </div>
            </div>
          </div>
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="ketentuan-cara-penggunaan">
            <div className="font-bold">Ketentuan & Cara Penggunaan</div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Ketentuan</label>
              <textarea 
                rows="4" 
                placeholder="Masukkan ketentuan kupon" 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-textarea focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('requirement')}
              ></textarea>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Cara Penggunaan</label>
              <textarea 
                rows="4" 
                placeholder="Masukkan cara penggunaan kupon" 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-textarea focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('how_to_use')}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </form>
    </>
  )
}

export default CouponAdd;