import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Layout from '../../layouts/centered';
import OtpInput from 'react-otp-input';
import { Button } from '../../components/buttons/buttons';
import replacePhone from '../../functions/replacePhone';
import {
  doRequestForgetPasswordOTP,
  doSubmitOtp,
  doSubmitOtpRegister,
} from '../../services/api/command/auth.command';
import phoneSanity from '../../functions/phoneSanity';
import toast, { Toaster } from 'react-hot-toast';

const OTP = () => {
  const location = useLocation();
  const history = useHistory();

  const [otpValue, setOtpValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [countdown, setCountdown] = useState(60);

  const handleChange = (otp) => setOtpValue(otp);

  const handleSubmitOtp = () => {
    if (location.state.from === '/register') {
      handleOTPRegister();
      return;
    } else {
      handleOTPLogin();
      return;
    }
  };

  const handleOTPRegister = () => {
    setLoading(true);
    doSubmitOtpRegister()
      .command({
        phone_number: phoneSanity(location.state.phone_number) || '',
        otp: otpValue,
      })
      .then((response) => {
        localStorage.setItem('rintizToken', response.data.data.auth_token);
        history.push('/');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        setLoading(false);
      });
  };

  const handleOTPLogin = () => {
    setLoading(true);
    doSubmitOtp()
      .command({
        phone_number: phoneSanity(location.state.phone_number) || '',
        otp: otpValue,
      })
      .then((response) => {
        localStorage.setItem('rintizToken', response.data.data.auth_token);
        history.push('/');
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        setLoading(false);
      });
  };

  const handleResendOTP = () => {
    doRequestForgetPasswordOTP()
    .command({
      phone_number: phoneSanity(location.state.phone_number) || '', 
    })
    .then(() => {
      setCountdown(60);
    })
    .catch((err) => {
      // setLoading(false);
      toast.error(err.response.data.error.message);
    });
  };

  useEffect(() => {
    if (countdown === 0) {
      return;
    }

    setTimeout(() => {
      setCountdown(countdown - 1);
    }, 1000);
  }, [countdown]);

  return (
    <Layout>
      <div className='w-1/4 m-auto'>
        <div className='flex'>
          <span>
            <img
              src='/rintiz-logo.svg'
              style={{ height: '38px' }}
              alt='Logo'
            />
          </span>
        </div>
        <div className='mt-8'>
          <div className='text-base'>
            Masukkan verifikasi kode OTP 6 digit, yang dikirim ke nomor
            <span className='font-bold'>
              {' '}
              {replacePhone(location.state.phone_number) || ''}{' '}
            </span>{' '}
            untuk masuk.
          </div>
          <div className='mt-4'>Masukkan Verifikasi Kode OTP</div>
          <div className='mt-4 flex gap-x-3'>
            <OtpInput
              value={otpValue}
              onChange={handleChange}
              numInputs={6}
              isInputNum={true}
              className='text-center text-sm border-gray-300 rounded-md mr-3 form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
            />
          </div>
          <div className='mt-4'>
            <Button
              loading={loading}
              onClick={handleSubmitOtp}
              text={'Verifikasi Kode OTP'}
              className='btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-primary text-white'
            />
          </div>
          <div className='mt-4 text-center'>
            Kirim ulang verifikasi kode OTP{' '}
            {countdown === 0 ? (
              <div onClick={handleResendOTP} className='text-primary cursor-pointer inline'>
                Kirim Ulang
              </div>
            ) : (
              `00:${countdown}`
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OTP;
