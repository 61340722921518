import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { FiCheckCircle, FiHelpCircle } from 'react-icons/fi';
// import {
//   CategoryScale,
//   Chart as ChartJS,
//   LinearScale,
//   LineElement,
//   PointElement,
// } from 'chart.js';

// Import Swiper styles
import 'swiper/swiper.scss'; // core Swiper
import { getProductTotal } from '../../services/api/command/product.command';
import { doGetTotalAgent } from '../../services/api/command/brand-subscription.command';
import {
  doGetOrderDelivered,
  doGetTotalOrderByTime,
} from '../../services/api/command/order.command';
import {
  doCheckAvailableSubdomain,
  doGetProfile,
  doUpdateSubdomain,
} from '../../services/api/command/profile.command';
import toast from 'react-hot-toast';
import { Button } from '../../components/buttons/buttons';
import { ProgressBar } from '../../components/progress-bars';
import Subdomain from './_components/subdomain';

const Home = () => {

  const totalProductQuery = getProductTotal().query('product total');
  const totalAgentQury = doGetTotalAgent().query('total agent');
  const totalOrderDelivered = doGetOrderDelivered().query(
    'total order delivered'
  );

  const { data: totalDailyOrderQuery } = doGetTotalOrderByTime({
    type: 'daily',
  }).query('total order daily');
  const { data: totalWeeklyOrderQuery } = doGetTotalOrderByTime({
    type: 'weekly',
  }).query('total order weekly');
  const { data: totalMonthlyOrderQuery } = doGetTotalOrderByTime({
    type: 'monthly',
  }).query('total order monthly');

  const [isClosedOptions, setIsClosedOptions] = React.useState(false);
  // const sliderRef = useRef(null);
  const history = useHistory();

  // const handlePrev = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slidePrev();
  // }, []);

  // const handleNext = useCallback(() => {
  //   if (!sliderRef.current) return;
  //   sliderRef.current.swiper.slideNext();
  // }, []);

  // ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement);

  const handleCloseOptions = () => {
    setIsClosedOptions(!isClosedOptions);
  };
  
  return (
    <>
      <div>
        <h5 className='py-3 font-bold'>Langkah mudah persiapkan tokomu</h5>
        <div className='mb-5'>
          <div className='relative flex items-center justify-between overflow-x-scroll'>
            <div className='flex flex-row items-center w-1/2 space-x-3'>
              <div className='w-1/6'>
                <ProgressBar width={100} color='bg-primary' />
              </div>
              <span className='w-1/2 text-grey-500'>4 dari 4 (100%)</span>
            </div>
            <span
              onClick={handleCloseOptions}
              className='text-primary cursor-pointer'
            >
              {isClosedOptions ? 'Tampilkan' : 'Sembunyikan'}
            </span>
          </div>
        </div>
        {!isClosedOptions && (
          <div className='flex flex-row mb-5 space-x-6 overflow-x-auto'>
            <div className='flex flex-row flex-shrink-0 w-2/5 p-3 border border-green-300 rounded'>
              <div className='w-1/4'></div>
              <div className='flex flex-col w-2/4 space-y-3'>
                <span className='block font-bold'>1. Tambah Produk</span>
                <span className='block w-4/5 text-xs text-grey-500'>
                  Maksimalkan tokomu dengan menambahkan produk
                </span>
                <div className='mb-3'>
                  <div
                    className='inline-block cursor-pointer border rounded btn btn-default border-grey-300'
                    onClick={() => history.push('/product/add')}
                  >
                    Tambah Produk
                  </div>
                </div>
              </div>
              <div className='flex justify-end w-1/4'>
                <FiCheckCircle size={24} color='#4caf50' className='mr-2' />
              </div>
            </div>
            <div className='flex flex-row flex-shrink-0 w-2/5 p-3 border border-green-300 rounded'>
              <div className='w-1/4'></div>
              <div className='flex flex-col w-2/4 space-y-3'>
                <span className='block font-bold'>2. Kelola Order</span>
                <span className='block w-4/5 text-xs text-grey-500'>
                  Atur order agar masuk dan proses pengiriman
                </span>
                <div className='mb-3'>
                  <div
                    className='inline-block cursor-pointer border rounded btn btn-default border-grey-300'
                    onClick={() => history.push('/orders')}
                  >
                    Kelola Order
                  </div>
                </div>
              </div>
              <div className='flex justify-end w-1/4'>
                <FiCheckCircle size={24} color='#4caf50' className='mr-2' />
              </div>
            </div>
            <div className='flex flex-row flex-shrink-0 w-2/5 p-3 border border-green-300 rounded'>
              <div className='w-1/4'></div>
              <div className='flex flex-col w-2/4 space-y-3'>
                <span className='block font-bold'>3. Kelola Pengiriman</span>
                <span className='block w-4/5 text-xs text-grey-500'>
                  Atur jasa kirim yang akan kamu gunakan.
                </span>
                <div className='mb-3'>
                  <div
                    className='inline-block cursor-pointer border rounded btn btn-default border-grey-300'
                    onClick={() => history.push('/shipping-providers')}
                  >
                    Kelola Pengiriman
                  </div>
                </div>
              </div>
              <div className='flex justify-end w-1/4'>
                <FiCheckCircle size={24} color='#4caf50' className='mr-2' />
              </div>
            </div>
            <div className='flex flex-row flex-shrink-0 w-2/5 p-3 border border-green-300 rounded'>
              <div className='w-1/4'></div>
              <div className='flex flex-col w-2/4 space-y-3'>
                <span className='block font-bold'>4. Kelola Membership</span>
                <span className='block w-4/5 text-xs text-grey-500'>
                  Atur resellermu dengan pengaturan membership
                </span>
                <div className='mb-3'>
                  <div
                    className='inline-block cursor-pointer border rounded btn btn-default border-grey-300'
                    onClick={() => history.push('/memberships')}
                  >
                    Kelola Membership
                  </div>
                </div>
              </div>
              <div className='flex justify-end w-1/4'>
                <FiCheckCircle size={24} color='#4caf50' className='mr-2' />
              </div>
            </div>
          </div>
        )}
      </div>

      <Subdomain />

      <div className='mb-5'>
        <h5 className='py-3 font-bold'>Overview</h5>
        <div className='grid grid-cols-4 gap-x-5'>
          {/* <div className='p-4 border rounded shadow-lg border-grey-200'>
            <span className='block mb-3 text-xs font-bold text-grey-500'>
              LATEST ORDER
            </span>
            <div className='flex flex-row justify-between'>
              <div className=''>
                <span className='text-xl font-extrabold'>288</span>
                <div className='mt-2 text-2xs'>
                  <span className='p-1 mr-1 text-red-600 bg-red-100 rounded'>
                    12.5 %
                  </span>
                  <span>from 604</span>
                </div>
              </div>
              <div className=''>
                <Line
                  data={latestOrders}
                  options={areaOptions}
                  height={50}
                  width={100}
                  id='latestOrderChart'
                />
              </div>
            </div>
          </div> */}
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <span className='block mb-3 text-xs font-bold text-grey-500'>
              TOTAL RESELLER
            </span>
            <div className='flex flex-row justify-between'>
              <div className=''>
                <span className='text-xl font-extrabold'>
                  {totalAgentQury.data?.data.data?.total}
                </span>
                {/* <div className='mt-2 text-2xs'>
                  <span className='p-1 mr-1 text-green-600 bg-green-100 rounded'>8.6 %</span>
                  <span>from 1.260</span>
                </div> */}
              </div>
              {/* <div className=''>
                <Line data={resellers} options={areaOptions} height={50} width={100} id="resellerChart" />
              </div> */}
            </div>
          </div>
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <span className='block mb-3 text-xs font-bold text-grey-500'>
              TOTAL PRODUCT
            </span>
            <div className='flex flex-row justify-between'>
              <div className=''>
                <span className='text-xl font-extrabold'>
                  {totalProductQuery?.data?.data?.data.total}
                </span>
                {/* <div className='mt-2 text-2xs'>
                  <span className='p-1 mr-1 text-green-600 bg-green-100 rounded'>10.2 %</span>
                  <span>from 2.104</span>
                </div> */}
              </div>
              {/* <div className=''>
                <Line data={products} options={areaOptions} height={50} width={100} id="productChart" />
              </div> */}
            </div>
          </div>
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <span className='block mb-3 text-xs font-bold text-grey-500'>
              PRODUCT DELIVERED
            </span>
            <div className='flex flex-row justify-between'>
              <div className=''>
                <span className='text-xl font-extrabold'>
                  {
                    totalOrderDelivered.data?.data.data?.invoice_items
                      .total_data
                  }
                </span>
                {/* <div className='mt-2 text-2xs'>
                  <span className='p-1 mr-1 text-green-600 bg-green-100 rounded'>6.5 %</span>
                  <span>from 1.104</span>
                </div> */}
              </div>
              {/* <div className=''>
                <Line data={deliveredProducts} options={areaOptions} height={50} width={100} id="deliveredProductChart" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className='mb-5'>
        <h5 className='py-3 font-bold'>Total Transaction</h5>
        <div className='grid grid-cols-3 gap-x-6'>
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <div className='flex justify-between'>
              <span className='block mb-3 text-xs font-bold text-grey-500'>
                Daily
              </span>
              <FiHelpCircle />
            </div>
            <span className='text-xl font-extrabold'>
              {totalDailyOrderQuery?.data.data.total.total_data}
            </span>
          </div>
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <div className='flex justify-between'>
              <span className='block mb-3 text-xs font-bold text-grey-500'>
                Weekly
              </span>
              <FiHelpCircle />
            </div>
            <span className='text-xl font-extrabold'>
              {totalWeeklyOrderQuery?.data.data.total.total_data}
            </span>
          </div>
          <div className='p-4 border rounded shadow-lg border-grey-200'>
            <div className='flex justify-between'>
              <span className='block mb-3 text-xs font-bold text-grey-500'>
                Monthly
              </span>
              <FiHelpCircle />
            </div>
            <span className='text-xl font-extrabold'>
              {totalMonthlyOrderQuery?.data.data.total.total_data}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
