import React from 'react';
import formatPrice from '../../../../functions/formatPrice';

function ProductList(props) {
  const products = typeof props.items == 'undefined' ? null : props.items.map((product) => {
    return (
      <tbody>
        <tr>
          <td className='py-2 font-medium leading-4 w-52 text-grey-500'>
            Nomor Produk
          </td>
          <td className='py-2 whitespace-no-wrap'>{product.details[0].name}</td>
        </tr>
        <tr>
          <td className='py-2 font-medium leading-4 text-grey-500'>
            Qty
          </td>
          <td className='py-2 whitespace-no-wrap'>{product.details[0].qty}</td>
        </tr>
        <tr>
          <td className='py-2 font-medium leading-4 text-grey-500'>
            Harga per Produk / Satuan
          </td>
          <td className='py-2 whitespace-no-wrap'>
            <div>{formatPrice(product.details[0].selling_price)}</div>
          </td>
        </tr>
      </tbody>
    )
  });

  return products;
}

function InvoiceProductInformation({data, subtotal}) {
  return (
    <div id='order-information'>
      <div className='p-4 mb-6 border rounded-md shadow-custom'>
        <div className='font-bold text-md'>Informasi Produk</div>
        <div className='flex flex-col mt-4 gap-x-10'>
          <table className='w-full text-left capitalize table-auto'>
            <ProductList products={data} />
            <tfoot>
              <tr>
                <td className='py-2 font-medium leading-4 text-grey-500'>
                  Sub total
                </td>
                <td className='py-2 whitespace-no-wrap'>
                    {formatPrice(subtotal)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvoiceProductInformation;
