import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import NumberFormat from 'react-number-format';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';

import { CustomToolbarExample } from '../../../components/text-editor';
import Datatable from '../../../components/datatable';
import { Button } from '../../../components/buttons/buttons';
import { getColor } from '../../../functions/colors';
import SwitchComponent from 'react-switch';
import Modal from '../../../components/modals/modal';
import Breadcrumb from '../../../components/breadcrumbs';
import { FiPlus, FiImage, FiXCircle } from 'react-icons/fi';
import { AiOutlineCar } from 'react-icons/ai';
import { TiDocumentText } from 'react-icons/ti';
import { GiSellCard } from 'react-icons/gi';
import { HiOutlineDotsCircleHorizontal } from 'react-icons/hi';
import {
	doProductUpdate,
	doGetProductDetail,
} from '../../../services/api/command/product.command';
import { createMediaRequest } from '../../../services/api/command/media.command';
import { getListCategories } from '../../../services/api/command/categories.command';
import { getListProvincies } from '../../../services/api/command/addresses.command';
import { doGetMembershipList } from '../../../services/api/command/membership.command';

import '../product.scss';

const HandleSwitch = (props) => {
	let offColor = `bg-blue-grey-100`;
	let offHandleColor = 'bg-blue-grey-200';

	return (
		<div className='flex items-center mt-4'>
			<SwitchComponent
				onChange={() => props.onChange()}
				checked={props.status}
				onColor='#B1D5DB'
				onHandleColor='#2FAEC3'
				offColor={getColor(offColor)}
				offHandleColor={getColor(offHandleColor)}
				handleDiameter={12}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow='0px 1px 5px rgba(0, 0, 0, 0.2)'
				height={8}
				width={38}
				className='react-switch '
			/>
			<span className='pl-3'>Dapat dilihat oleh reseller</span>
		</div>
	);
};

const Edit = () => {
	let { sku } = useParams();
	const {
		data: detail,
		status: detailStatus,
		remove: detailRemove,
	} = doGetProductDetail(sku).query(['order', sku]);

	useEffect(() => {
		return () => detailRemove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [responseHandle, setResponseHandle] = useState(false);
	const history = useHistory();
	let categoryList = getListCategories().query(
		['category list'],
		{},
		{ limit: 100, page: 1 },
	);
	const destination = [
		{
			value: 0,
			label: 'Semua Lokasi',
		},
		{
			value: 1,
			label: 'Hanya Kota/Kabupaten',
		},
	];
	let provinciesList = getListProvincies().query(['provinciesList']);
	let membershipList = doGetMembershipList().query(
		['membershipList'],
		{},
		{ limit: 1000, page: 1 },
	);
	const [image, setImage] = useState([]);
	let dummyImageURL = [];
	const [showMarketPrice, setShowMarketPrice] = useState(false);
	const [showResellerCommision, setShowResellerCommision] = useState(false);
	const [loading, setLoading] = useState(false);
	const [variant, setVariant] = useState();
	const [variantType, setVariantType] = useState([]);
	const [variantSKU, setvariantSKU] = useState([]);
	const [countingType, setCountingType] = useState([[]]);
	const [typeDestination, setTypeDestination] = useState(0);
	const [createdAt, setCreatedAt] = useState();
	const [imageUpload, setImageUpload] = useState();
	const [editedImage, setEditedImage] = useState([]);

	if (categoryList.isSuccess) {
		let newArr = [];
		// eslint-disable-next-line no-unused-expressions
		categoryList.data?.data?.data?.list.map((element) => {
			newArr.push({
				value: element.category_id,
				label: element.name,
			});
		});
		categoryList = newArr;
	}

	if (provinciesList.isSuccess) {
		let newArr = [];
		// eslint-disable-next-line no-unused-expressions
		provinciesList.data?.data?.data?.provinces.map((element) => {
			newArr.push({
				value: element.id,
				label: element.name,
			});
		});
		provinciesList = newArr;
	}

	if (membershipList.isSuccess) {
		let newArr = [];
		// eslint-disable-next-line no-unused-expressions
		membershipList.data?.data?.data?.list.map((element) => {
			newArr.push({
				value: element.agent_id,
				label: element.name,
			});
		});
		membershipList = newArr;
	}

	const {
		register,
		handleSubmit,
		getValues,
		setValue,
		control,
		reset,
		formState: { errors },
	} = useForm();

	const dataSatuan = [
		{
			value: 'set',
			label: 'Set',
		},
		{
			value: 'pcs',
			label: 'Pcs',
		},
		{
			value: 'kg',
			label: 'Kilogram',
		},
		{
			value: 'gram',
			label: 'Gram',
		},
		{
			value: 'liter',
			label: 'Liter',
		},
		{
			value: 'ml',
			label: 'Ml',
		},
		{
			value: 'box',
			label: 'Box',
		},
		{
			value: 'krat',
			label: 'Krat',
		},
		{
			value: 'pak',
			label: 'Pak',
		},
		{
			value: 'ekor',
			label: 'Ekor',
		},
	];

	const handleMainPhoto = (index) => {
		const dummyImageUpload = [...imageUpload];
		for (let i = 0; i <= 3; i++) {
			if (i === index) {
				setValue(`foto-utama-${image}`, 'on');
				if (dummyImageUpload[i] !== undefined) {
					dummyImageUpload[i].status = 10;
				}
			} else {
				setValue(`foto-utama-${i}`, null);
				if (dummyImageUpload[i] !== undefined) {
					dummyImageUpload[i].status = 0;
				}
			}
		}
		setImageUpload(dummyImageUpload);
	};

	const customStyle = {
		control: (provided, state) => ({
			...provided,
			padding: '10px',
			borderRadius: '0.375rem',
			border: '1px solid #e2e8f0',
			outline: 'none',
		}),
	};

	const breadcrumbs = [
		{ title: 'List Produk', url: '/products', last: false },
		{ title: `Edit Produk`, url: '', last: true },
	];

	const handleImageURL = async (e, index) => {
		let blob = await fetch(e).then((r) => r.blob());
		var upload = await URL.createObjectURL(blob);
		var result = {
			name: `image-${index}`,
			data: blob,
			preview: upload,
		};
		return await setImage((oldImage) => [...oldImage, result]);
	};

	if (detail !== undefined && !responseHandle) {
		setResponseHandle(true);
		const res = detail.data.data.data;
		setValue('name', res.name);
		setValue('description', res.description);
		const dummyCategories = [];
		res.categories.map((data) => {
			dummyCategories.push({
				value: data.category_id,
				label: data.name,
			});
		});
		setValue('categories', dummyCategories);
		setValue('status', res.status);
		setValue('stock', res.variants[0].stock);
		setValue('weight', res.shipping_information.weight);
		setValue('length', res.shipping_information.length);
		setValue('width', res.shipping_information.width);
		setValue('height', res.shipping_information.height);
		setValue('volume', res.shipping_information.volume);
		const dummyImage = res.variants[0].images;
		setImageUpload(res.variants[0].images);
		dummyImage.map((item, index) => {
			if (item.status === 10) {
				setValue(`foto-utama-${index}`, 'on');
			}
			if (dummyImageURL[index] === undefined) {
				dummyImageURL[index] = {
					preview: `https://res.cloudinary.com/rintiz/image/upload${item.image_url}`,
				};
			}
		});
		dummyImageURL.map((item, index) => {
			handleImageURL(item.preview, index);
		});
		const dummySKU = [];
		if (res.variants.length > 1) {
			res.variants.map((item, index) => {
				dummySKU.push(item.sku);
				setValue(`satuan-${index}`, {
					value: res.variants[0].satuan,
					label:
						res.variants[0].satuan.charAt(0).toUpperCase() +
						res.variants[0].satuan.slice(1),
				});
				setValue(`stock-${index}`, item.stock);
				setValue(
					`discount_percentage-${index}`,
					item.prices[0].discount_percentage,
				);
				setValue(
					`original_price-${index}`,
					item.prices[0].original_price,
				);
				setValue(
					`distributor_price-${index}`,
					item.prices[0].distributor_price,
				);
				setValue(`msrp-${index}`, item.prices[0].msrp);
				setValue(
					`original_price-${index}`,
					item.prices[0].original_price,
				);
				setValue(
					`selling_price-${index}`,
					item.prices[0].selling_price,
				);
				setValue(`comm-${index}`, item.commission.comm_1);
			});
		} else {
			dummySKU.push(res.variants[0].sku);
			setValue('satuan', {
				value: res.variants[0].satuan,
				label:
					res.variants[0].satuan.charAt(0).toUpperCase() +
					res.variants[0].satuan.slice(1),
			});
			setValue(
				'distributor_price',
				res.variants[0].prices[0].distributor_price,
			);
			setValue(
				'original_price',
				res.variants[0].prices[0].original_price,
			);
			setValue('selling_price', res.variants[0].prices[0].selling_price);
			setValue(
				'discount_percentage',
				res.variants[0].prices[0].discount_percentage,
			);
			setValue('msrp', res.variants[0].prices[0].msrp);
			setValue('comm', res.variants[0].commission.comm_1);
		}
		setvariantSKU(dummySKU);
		setVariant(res.variant_options);
		setVariantType(res.variants);
		if (res.shipping_information.allowed_city_cod.length > 0) {
			const dummyDestination = [];
			res.shipping_information.allowed_city_cod.map((data) => {
				dummyDestination.push({
					value: data.city_id,
					label: data.name,
				});
			});
			setValue('destination', dummyDestination);
			setValue('destinationSelect', {
				value: 1,
				label: 'Hanya Kota/Kabupaten',
			});
			setTypeDestination(1);
		} else {
			setValue('destinationSelect', {
				value: 0,
				label: 'Semua Lokasi',
			});
		}
		const dummyVisibilitas = [];
		res.allowed_users.map((data) => {
			dummyVisibilitas.push({
				value: data.agent_id,
				label: data.name,
			});
		});
		setValue('visibilitas', dummyVisibilitas);
		setCreatedAt(res.created_at);
	}

	const extractVisibilitasData = () => {
		const visibilitas = [];

		(getValues('visibilitas') || []).forEach((element) => {
			visibilitas.push(element.value);
		});
		return visibilitas;
	};

	const extractCategoryData = () => {
		const categories = [];

		(getValues('categories') || []).forEach((element) => {
			categories.push(element.value);
		});
		return categories;
	};

	const submit = () => {
		setLoading(true);
		if (editedImage.find((e) => e === true)) {
			handlePostMedia();
		} else {
			handleCreate(imageUpload);
		}
	};

	const handlePostMedia = async () => {
		const dummyImage = [...imageUpload];
		let i = 0;
		while (i < image.length) {
			if (editedImage[i] === true) {
				var formdata = new FormData();
				formdata.append('source', 'upload');
				formdata.append('provider', 'cloudinary');
				formdata.append('file', image[i].data);
				formdata.append('title', image[i].name);
				formdata.append('description', image[i].name);
				let statusImage = getValues(`foto-utama-${i}`);
				if (statusImage === 'on') {
					statusImage = 10;
				} else {
					statusImage = 0;
				}
				await createMediaRequest()
					.command(formdata)
					// eslint-disable-next-line no-loop-func
					.then((res) => {
						let dummyURL = res.data.data.public_url.split(
							'https://res.cloudinary.com/rintiz/image/upload',
						);
						dummyImage[i] = {
							image_url: dummyURL[1],
							angle: 10,
							status: statusImage,
						};
						i++;
					});
			} else {
				i++;
			}
		}
		setImageUpload(dummyImage);
		if (i === image.length) {
			setTimeout(() => {
				handleCreate(dummyImage);
			}, 3000);
		}
	};

	const extractVariant = (images) => {
		const dummyVariant = [];

		variantType.map((item, index) => {
			const dummy = {
				satuan: getValues(`satuan-${index}.value`),
				stock: parseInt(getValues(`stock-${index}`)),
				sku: variantSKU[index],
				//
				sku_brandowner: '0',
				priority: 0,
				option1: '',
				option2: '',
				option3: '',
				has_weekly_bonus: 1,
				is_default: 0,
				is_set: 0,
				is_gps: 0,
				gps_type: '',
				status: 10,
				is_in_stock: 1,
				//
				prices: [
					{
						distributor_price: parseInt(
							getValues(`distributor_price-${index}`),
						),
						original_price: parseInt(
							getValues(`original_price-${index}`),
						),
						selling_price: parseInt(
							getValues(`selling_price-${index}`),
						),
						discount_percentage: parseInt(
							getValues(`discount_percentage-${index}`),
						),
						msrp: parseInt(getValues(`msrp-${index}`)),
					},
				],
				images,
				commission: {
					comm_1: parseInt(getValues(`comm-${index}`)),
					comm_2: parseInt(getValues(`comm-${index}`)),
					comm_3: parseInt(getValues(`comm-${index}`)),
					wb_comm_0: parseInt(getValues(`comm-${index}`)),
					wb_comm_1: parseInt(getValues(`comm-${index}`)),
					wb_comm_2: parseInt(getValues(`comm-${index}`)),
					wb_comm_3: parseInt(getValues(`comm-${index}`)),
				},
			};
			dummyVariant.push(dummy);
		});
		return dummyVariant;
	};

	const extactDestination = () => {
		const destination = [];

		(getValues('destination') || []).forEach((element) => {
			destination.push(element.value);
		});
		return destination;
	};

	const handleCreate = async (images) => {
		console.log(images)
		let body = null;
		let allowedCity = [];
		if (typeDestination !== 0) {
			allowedCity = extactDestination();
		}
		if (variantType.length > 1) {
			body = {
				product_id: detail?.data.data.data.product_id,
				name: getValues('name'),
				description: getValues('description'),
				status: parseInt(getValues('status')),
				categories: extractCategoryData(),
				variants: extractVariant(images),
				shipping_information: {
					length: parseInt(getValues('length')),
					width: parseInt(getValues('width')),
					height: parseInt(getValues('height')),
					weight: parseFloat(getValues('weight')),
					// volume: parseInt(getValues('volume')),
					weight_set: 1.0,
					is_free_ongkir: 0,
					is_cod: 1,
					allowed_city_cod: allowedCity,
				},
				//
				qty_set: 11,
				is_variant: 1,
				is_in_stock: 1,
				is_fragile: 0,
				is_gt: 0,
				allowed_users: extractVisibilitasData(),
				allowed_shipping_range_province: allowedCity.join(),
				allowed_shipping_range_city: '',
				allowed_shipping_range_district: '',
				disalowed_shipping_range_province: '',
				disalowed_shipping_range_city: '',
				disalowed_shipping_range_district: '',
				variant_options: variant,
				created_at: createdAt,
				//
			};
		} else {
			body = {
				product_id: detail?.data.data.data.product_id,
				name: getValues('name'),
				description: getValues('description'),
				status: parseInt(getValues('status')),
				categories: extractCategoryData(),
				variants: [
					{
						satuan: getValues('satuan').value,
						stock: parseInt(getValues('stock')),
						sku: variantSKU[0],
						//
						sku_brandowner: '0',
						priority: 0,
						option1: '',
						option2: '',
						option3: '',
						has_weekly_bonus: 1,
						is_default: 0,
						is_set: 0,
						is_gps: 0,
						gps_type: '',
						status: 10,
						is_in_stock: 1,
						//
						prices: [
							{
								distributor_price: parseInt(
									getValues('distributor_price'),
								),
								original_price: parseInt(
									getValues('original_price'),
								),
								selling_price: parseInt(
									getValues('selling_price'),
								),
								discount_percentage: parseInt(
									getValues('discount_percentage'),
								),
								msrp: parseInt(getValues('msrp')),
							},
						],
						images: images,
						commission: {
							comm_1: parseInt(getValues('comm')),
							comm_2: parseInt(getValues('comm')),
							comm_3: parseInt(getValues('comm')),
							wb_comm_0: parseInt(getValues('comm')),
							wb_comm_1: parseInt(getValues('comm')),
							wb_comm_2: parseInt(getValues('comm')),
							wb_comm_3: parseInt(getValues('comm')),
						},
					},
				],
				shipping_information: {
					length: parseInt(getValues('length')),
					width: parseInt(getValues('width')),
					height: parseInt(getValues('height')),
					weight: parseFloat(getValues('weight')),
					// volume: parseInt(getValues('volume')),
					weight_set: 1.0,
					is_free_ongkir: 0,
					is_cod: 1,
					allowed_city_cod: allowedCity,
				},
				//
				qty_set: 11,
				is_variant: 0,
				is_in_stock: 1,
				is_fragile: 0,
				is_gt: 0,
				allowed_users: extractVisibilitasData(),
				allowed_shipping_range_province: allowedCity.join(),
				allowed_shipping_range_city: '',
				allowed_shipping_range_district: '',
				disalowed_shipping_range_province: '',
				disalowed_shipping_range_city: '',
				disalowed_shipping_range_district: '',
				variant_options: [],
				created_at: createdAt,
				//
			};
		}
		doProductUpdate()
			.command(body)
			.then((res) => {
				toast.success('Berhasil disimpan, halamanmu akan dialihkan');
				setTimeout(() => {
					history.push('/products');
				}, 5000);
			})
			.catch((err) => {
				setLoading(false);
				toast.error('Gagal menyimpan');
			});
	};

	const handleImage = (e, name) => {
		var upload = URL.createObjectURL(e);
		var result = {
			name,
			data: e,
			preview: upload,
		};
		let dummy = [...image];
		let found = dummy.findIndex((e) => e.name === name);
		if (dummy.length > 0) {
			if (found < 0) {
				setImage((oldFiles) => [...oldFiles, result]);
			} else {
				dummy[found] = result;
				setImage(dummy);
			}
		} else {
			setImage((oldFiles) => [...oldFiles, result]);
		}
		let dummyEditedImage = [...editedImage];
		dummyEditedImage[parseInt(name.split('-')[1])] = true;
		setEditedImage(dummyEditedImage);
	};

	const handleDiscount = () => {
		const original_price = parseInt(getValues('original_price'));
		const discount_percentage = parseInt(getValues('discount_percentage'));
		if (original_price !== null && discount_percentage !== null) {
			setValue(
				'selling_price',
				original_price - (original_price * discount_percentage) / 100,
			);
		}
	};

	const handleDiscountVariant = (index) => {
		const original_price = parseInt(getValues(`original_price-${index}`));
		const discount_percentage = parseInt(
			getValues(`discount_percentage-${index}`),
		);
		if (original_price !== null && discount_percentage !== null) {
			setValue(
				`selling_price-${index}`,
				original_price - (original_price * discount_percentage) / 100,
			);
		}
	};

	const handleVolume = () => {
		const length = parseInt(getValues('length'));
		const width = parseInt(getValues('width'));
		const height = parseInt(getValues('height'));
		if (length !== null && width !== null && height !== null) {
			setValue('volume', length * width * height);
		}
	};

	const extractTypeVariant = (position) => {
		const variant = [];

		for (let i = 0; i < 3; i++) {
			if (
				getValues(`typeVariant-${i}`) !== undefined &&
				getValues(`typeVariant-${i}`) !== ''
			) {
				const dummy = {
					name: getValues(`typeVariant-${i}`),
					value: getValues(`typeVariant-${i}`),
					status: 10,
					position,
					id: i,
				};
				variant.push(dummy);
				setVariantType((oldVariant) => [...oldVariant, dummy]);
			}
		}
		for (let i = 0; i < 3; i++) {
			setValue(`typeVariant-${i}`, undefined);
		}
		return variant;
	};

	const handleVariant = () => {
		const typeVariant = extractTypeVariant(variant.length + 1);
		const newVariant = {
			name: getValues(`nameVariant`),
			position: variant.length + 1,
			values: typeVariant,
		};
		setVariant((oldVariant) => [...oldVariant, newVariant]);
		setValue('nameVariant', '');
		setCountingType([[]]);
	};

	const handleCountingType = () => {
		setCountingType((oldTypeVariant) => [...oldTypeVariant, []]);
	};

	const handleDeleteVariant = (index) => {
		const dummyVariant = [...variant];
		const dummyType = [...variantType];
		const spliceVariant = dummyType[index];
		dummyVariant[spliceVariant.position - 1].values.splice(
			spliceVariant.id,
			1,
		);
		dummyType.splice(index, 1);
		setVariant(dummyVariant);
		setVariantType(dummyType);
	};

	const columns = React.useMemo(
		() => [
			{
				Header: 'Varian',
				Cell: (props) => {
					return (
						<div className='w-8'>
							{props?.row.original.option1}{' '}
							{props?.row.original.option2}{' '}
							{props?.row.original.option3}
						</div>
					);
				},
			},
			{
				Header: 'Unit',
				Cell: (props) => {
					return (
						<div className='w-64'>
							<Controller
								control={control}
								name={`satuan-${props.row.index}`}
								render={({
									field: { onChange, name, value },
								}) => (
									<Select
										name={name}
										value={value}
										onChange={onChange}
										className='basic-multi-select'
										placeholder='Pilih Satuan'
										options={dataSatuan}
										styles={customStyle}
										getInputRef={register(
											`satuan-${props.row.index}`,
											{
												required: {
													value: true,
													message: 'Wajib Diisi',
												},
											},
										)}
									/>
								)}
							/>
							{errors[`satuan-${props.row.index}`] &&
								errors[`satuan-${props.row.index}`].type ===
									'required' && (
									<div className='pt-2 text-red-500'>
										*Wajib Diisi!
									</div>
								)}
						</div>
					);
				},
			},
			{
				Header: 'Harga',
				Cell: (props) => {
					return (
						<div className='w-128'>
							{/* Harga Jual */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Harga Jual*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											Rp.
										</div>
										<Controller
											control={control}
											name={`original_price-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`original_price-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
														},
													)}
													placeholder='Masukan Harga Jual'
													value={value}
												/>
											)}
										/>
									</div>
									{errors[
										`original_price-${props.row.index}`
									] &&
										errors[
											`original_price-${props.row.index}`
										].type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`original_price-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
							{/* Diskon */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Diskon*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											%
										</div>
										<Controller
											control={control}
											name={`discount_percentage-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`discount_percentage-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
															max: {
																value: 100,
																message:
																	'Angka maksimal 100',
															},
														},
													)}
													placeholder='Masukan Diskon'
													value={value}
												/>
											)}
										/>
									</div>
									{errors[
										`discount_percentage-${props.row.index}`
									] &&
										errors[
											`discount_percentage-${props.row.index}`
										].type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`discount_percentage-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
							{/* Harga Jual Setelah Diskon */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Harga Jual Setelah Diskon*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											Rp.
										</div>
										<Controller
											control={control}
											name={`selling_price-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`selling_price-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
														},
													)}
													placeholder='Masukan Harga Jual Setelah Diskon'
													value={value}
													disabled
												/>
											)}
										/>
									</div>
									{errors[
										`selling_price-${props.row.index}`
									] &&
										errors[
											`selling_price-${props.row.index}`
										].type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`selling_price-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
							{/* Harga Pasar */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Harga Pasar*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											Rp.
										</div>
										<Controller
											control={control}
											name={`msrp-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`msrp-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
														},
													)}
													placeholder='Masukan Harga Pasar'
													value={value}
												/>
											)}
										/>
									</div>
									{errors[`msrp-${props.row.index}`] &&
										errors[`msrp-${props.row.index}`]
											.type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`msrp-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
							{/* Harga Modal */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Harga Modal*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											Rp.
										</div>
										<Controller
											control={control}
											name={`distributor_price-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`distributor_price-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
														},
													)}
													placeholder='Masukan Harga Modal'
													value={value}
												/>
											)}
										/>
									</div>
									{errors[
										`distributor_price-${props.row.index}`
									] &&
										errors[
											`distributor_price-${props.row.index}`
										].type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`distributor_price-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
							{/* Komisi */}
							<div className='grid grid-cols-8 items-center mt-8'>
								<label className='col-span-3 text-xs'>
									Komisi*
								</label>
								<div className='col-span-5'>
									<div className='flex w-full'>
										<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
											Rp.
										</div>
										<Controller
											control={control}
											name={`comm-${props.row.index}`}
											render={({
												field: {
													onChange,
													name,
													value,
												},
											}) => (
												<NumberFormat
													className='form-input border rounded-r-lg text-sm w-full'
													onChange={(e) => {
														onChange(
															e.target.value,
														);
														handleDiscountVariant(
															props.row.index,
														);
													}}
													name={name}
													getInputRef={register(
														`comm-${props.row.index}`,
														{
															required: {
																value: true,
																message:
																	'Wajib Diisi',
															},
															min: {
																value: 0,
																message:
																	'Angka tidak boleh minus',
															},
														},
													)}
													placeholder='Masukan Komisi'
													value={value}
												/>
											)}
										/>
									</div>
									{errors[`comm-${props.row.index}`] &&
										errors[`comm-${props.row.index}`]
											.type === 'required' && (
											<p className='text-red-500 mt-2'>
												*
												{
													errors[
														`comm-${props.row.index}`
													].message
												}
											</p>
										)}
								</div>
							</div>
						</div>
					);
				},
			},
			{
				Header: 'Stok',
				Cell: (props) => {
					return (
						<div className='w-64'>
							<Controller
								control={control}
								name={`stock-${props.row.index}`}
								render={({
									field: { onChange, name, value },
								}) => (
									<NumberFormat
										className='form-input border rounded-r-lg text-sm w-full'
										onChange={onChange}
										name={name}
										getInputRef={register(
											`stock-${props.row.index}`,
											{
												required: {
													value: true,
													message: 'Wajib Diisi',
												},
												min: {
													value: 0,
													message:
														'Angka tidak boleh minus',
												},
											},
										)}
										placeholder='Masukkan stok'
										value={value}
									/>
								)}
							/>
							{errors[`stock-${props.row.index}`] &&
								errors[`stock-${props.row.index}`].type ===
									'required' && (
									<p className='text-red-500 mt-2'>
										*
										{
											errors[`stock-${props.row.index}`]
												.message
										}
									</p>
								)}
						</div>
					);
				},
			},
			{
				Header: 'Aksi',
				Cell: (props) => {
					return (
						<button
							type='button'
							className='btn flex items-center gap-x-2 rounded-md text-sm bordertext-black'
							onClick={() => handleDeleteVariant(props.row.index)}
						>
							<FiXCircle className='stroke-current text-lg' />
						</button>
					);
				},
			},
		],
		[
			control,
			customStyle,
			dataSatuan,
			errors,
			handleDeleteVariant,
			handleDiscountVariant,
			register,
		],
	);

	return (
		<>
			{detailStatus === 'success' && (
				<div className='px-20'>
					<Breadcrumb items={breadcrumbs} />
					<Toaster />
					<form>
						<div className='flex justify-between items-center'>
							<h4 className='py-3 font-bold'>Edit Produk</h4>
							<div className='flex items-end'>
								<div>
									<button
										type='button'
										className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 border mt-8'
										onClick={() =>
											history.push('/products')
										}
										value={detail?.data.data.data.name}
									>
										Batalkan
									</button>
								</div>
								<div className='ml-4'>
									<Button
										loading={loading}
										onClick={handleSubmit(submit)}
										text={'Simpan'}
										className='btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 bg-primary text-white'
									/>
								</div>
							</div>
						</div>
						<div className='grid grid-cols-8 mt-6'>
							<div className='col-span-2 px-4'>
								<div className='w-full p-4 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800'>
									<div className='flex flex-col space-y-6'>
										<a
											href='#add'
											className='flex flex-row'
										>
											<TiDocumentText className='mr-2 text-lg stroke-current' />
											<span>Informasi Umum</span>
										</a>
										<a
											href='#sales'
											className='flex flex-row'
										>
											<GiSellCard className='mr-2 text-lg stroke-current' />
											<span>Informasi Penjualan</span>
										</a>
										<a
											href='#delivery'
											className='flex flex-row'
										>
											<AiOutlineCar className='mr-2 text-lg stroke-current' />
											<span>Pengiriman</span>
										</a>
										<a
											href='#other'
											className='flex flex-row'
										>
											<HiOutlineDotsCircleHorizontal className='mr-2 text-lg stroke-current' />
											<span>Lainnya</span>
										</a>
									</div>
								</div>
							</div>
							<div className='col-span-6 px-4'>
								{/* Informasi Umum */}
								<div className='shadow rounded-lg p-4' id='add'>
									<div className='text-base font-bold'>
										Informasi Umum
									</div>
									{/* Nama Produk */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Nama Produk*
										</label>
										<div className='col-span-6 '>
											<input
												type='text'
												className='form-input border rounded-lg text-sm w-full'
												placeholder='Masukkan nama produk'
												{...register('name', {
													required: true,
												})}
											/>
											{errors.name &&
												errors.name.type ===
													'required' && (
													<div className='pt-2 text-red-500'>
														*Wajib Diisi!
													</div>
												)}
										</div>
									</div>
									{/* Deskiripsi Produk */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Deskripsi Produk*
										</label>
										<div className='col-span-6'>
											<CustomToolbarExample
												onChange={(e) =>
													setValue('description', e)
												}
												value={getValues('description')}
											/>
										</div>
									</div>
									{/* Foto Produk */}
									<div className='grid grid-cols-8 mt-8 mb-16'>
										<label className='col-span-2'>
											Foto Produk
										</label>
										<div className='grid grid-cols-4 col-span-6'>
											<div className='add-product__form-image-content'>
												<label
													className='add-product__form-image-content-review'
													style={{
														backgroundImage: `url(${image[0]?.preview})`,
													}}
												>
													{image[0] == null && (
														<>
															<FiImage className='stroke-current text-lg' />
															<p className='text-center mt-3'>
																Upload
															</p>
														</>
													)}
													<input
														type='file'
														className='hidden'
														accept='image/*'
														onChange={(e) => {
															handleImage(
																e.target
																	.files[0],
																'image-0',
															);
														}}
													/>
												</label>
												<div className='add-product__form-image-content-radio'>
													<label>
														<input
															className='mr-2'
															type='radio'
															{...register(
																`foto-utama-0`,
															)}
															onChange={() =>
																handleMainPhoto(
																	0,
																)
															}
															disabled={
																imageUpload ===
																	undefined ||
																imageUpload[0] ===
																	undefined
															}
														/>
														Foto Utama
													</label>
												</div>
											</div>
											<div className='add-product__form-image-content'>
												<label
													className='add-product__form-image-content-review'
													style={{
														backgroundImage: `url(${image[1]?.preview})`,
													}}
												>
													{image[1] == null && (
														<>
															<FiImage className='stroke-current text-lg' />
															<p className='text-center mt-3'>
																Upload
															</p>
														</>
													)}
													<input
														type='file'
														className='hidden'
														accept='image/*'
														onChange={(e) => {
															handleImage(
																e.target
																	.files[0],
																'image-1',
															);
														}}
													/>
												</label>
												<div className='add-product__form-image-content-radio'>
													<label>
														<input
															className='mr-2'
															type='radio'
															name='radio-image'
															{...register(
																`foto-utama-1`,
															)}
															onChange={() =>
																handleMainPhoto(
																	1,
																)
															}
															disabled={
																imageUpload ===
																	undefined ||
																imageUpload[1] ===
																	undefined
															}
														/>
														Foto Utama
													</label>
												</div>
											</div>
											<div className='add-product__form-image-content'>
												<label
													className='add-product__form-image-content-review'
													style={{
														backgroundImage: `url(${image[2]?.preview})`,
													}}
												>
													{image[2] == null && (
														<>
															<FiImage className='stroke-current text-lg' />
															<p className='text-center mt-3'>
																Upload
															</p>
														</>
													)}
													<input
														type='file'
														className='hidden'
														accept='image/*'
														onChange={(e) => {
															handleImage(
																e.target
																	.files[0],
																'image-2',
															);
														}}
													/>
												</label>
												<div className='add-product__form-image-content-radio'>
													<label>
														<input
															className='mr-2'
															type='radio'
															name='radio-image'
															{...register(
																`foto-utama-2`,
															)}
															onChange={() =>
																handleMainPhoto(
																	2,
																)
															}
															disabled={
																imageUpload ===
																	undefined ||
																imageUpload[2] ===
																	undefined
															}
														/>
														Foto Utama
													</label>
												</div>
											</div>
											<div className='add-product__form-image-content'>
												<label
													className='add-product__form-image-content-review'
													style={{
														backgroundImage: `url(${image[3]?.preview})`,
													}}
												>
													{image[3] == null && (
														<>
															<FiImage className='stroke-current text-lg' />
															<p className='text-center mt-3'>
																Upload
															</p>
														</>
													)}
													<input
														type='file'
														className='hidden'
														accept='image/*'
														onChange={(e) => {
															handleImage(
																e.target
																	.files[0],
																'image-3',
															);
														}}
													/>
												</label>
												<div className='add-product__form-image-content-radio'>
													<label>
														<input
															className='mr-2'
															type='radio'
															name='radio-image'
															{...register(
																`foto-utama-3`,
															)}
															onChange={() =>
																handleMainPhoto(
																	3,
																)
															}
															disabled={
																imageUpload ===
																	undefined ||
																imageUpload[3] ===
																	undefined
															}
														/>
														Foto Utama
													</label>
												</div>
											</div>
										</div>
									</div>
									{/* Satuan Produk */}
									{variantType.length === 0 && (
										<div className='grid grid-cols-8 items-center mt-8'>
											<label className='col-span-2'>
												Satuan Produk*
											</label>
											<div className='col-span-6 '>
												<Controller
													control={control}
													name={`satuan`}
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<Select
															name={name}
															value={value}
															onChange={onChange}
															className='basic-multi-select'
															placeholder='Pilih Satuan'
															options={dataSatuan}
															styles={customStyle}
															getInputRef={register(
																`satuan`,
																{
																	required: {
																		value: true,
																		message:
																			'Wajib Diisi',
																	},
																},
															)}
														/>
													)}
												/>
												{errors[`satuan`] &&
													errors[`satuan`].type ===
														'required' && (
														<div className='pt-2 text-red-500'>
															*Wajib Diisi!
														</div>
													)}
											</div>
										</div>
									)}
									{/* Kategori */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Kategori*
										</label>
										<div className='col-span-6 '>
											<Controller
												control={control}
												name='categories'
												render={({
													field: {
														onChange,
														name,
														value,
													},
												}) => (
													<Select
														isMulti
														name={name}
														value={value}
														onChange={onChange}
														className='basic-multi-select'
														placeholder='Pilih Kategori'
														options={categoryList}
														styles={customStyle}
													/>
												)}
											/>
											{errors.categories &&
												errors.categories.type ===
													'required' && (
													<div className='pt-2 text-red-500'>
														*Wajib Diisi!
													</div>
												)}
										</div>
									</div>
								</div>
								{/* Informasi Penjualan */}
								<div
									className='shadow rounded-lg p-4 mt-4'
									id='sales'
								>
									<div className='text-base font-bold mb-4'>
										Informasi Penjualan
									</div>
									{/* {variant.length < 3 && (
										<Modal
											modalName={
												<button
													type='button'
													className='btn flex items-center gap-x-2 rounded-md text-sm mt-3 py-2 px-4 border rounded-lg text-black'
												>
													<FiPlus className='stroke-current text-lg' /> Varian
													Produk
												</button>
											}
											title='Variant Produk'
											body={
												<div>
													<div className='grid grid-cols-8 mt-8 items-center'>
														<label className='col-span-2'>Nama Varian</label>
														<input
															type='text'
															className='form-input col-span-6 border rounded-lg text-sm'
															placeholder='Misalnya : Warna, Ukuran, Seri'
															{...register(`nameVariant`)}
														/>
													</div>
													<div className='grid grid-cols-8 mt-8 items-start'>
														<label className='col-span-2'>Jenis Varian</label>
														<div className='col-span-6 '>
															{countingType.map((item, index) => {
																return (
																	<input
																		type='text'
																		className='form-input w-full border rounded-lg text-sm mb-4'
																		placeholder='Misalnya: Merah, XL'
																		{...register(`typeVariant-${index}`)}
																	/>
																);
															})}
															{countingType.length < 3 && (
																<button
																	className='flex btn gap-x-2 rounded-md font-bold text-sm py-2 px-4 border'
																	type='button'
																	onClick={handleCountingType}
																>
																	<FiPlus className='stroke-current text-lg' />
																	Jenis
																</button>
															)}
														</div>
													</div>
												</div>
											}
											buttonSubmit={() => handleVariant()}
											buttonLeft='Simpan & Kembali'
											buttonClassLeft='btn btn-default btn-rounded border'
											buttonRight='Simpan Varian Baru'
											buttonClassRight='btn btn-default btn-rounded bg-primary text-white'
										/>
									)} */}
									{variantType.length === 1 ? (
										<>
											{/* Stock */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Stok*
												</label>
												<div className='col-span-6 '>
													<Controller
														control={control}
														name='stock'
														render={({
															field: {
																onChange,
																name,
																value,
															},
														}) => (
															<NumberFormat
																className='form-input border rounded-r-lg text-sm w-full'
																onChange={
																	onChange
																}
																name={name}
																getInputRef={register(
																	'stock',
																	{
																		required:
																			{
																				value: true,
																				message:
																					'Wajib Diisi',
																			},
																		min: {
																			value: 0,
																			message:
																				'Angka tidak boleh minus',
																		},
																	},
																)}
																placeholder='Masukkan stok'
																value={value}
															/>
														)}
													/>
													{errors?.stock?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors?.stock
																	?.message
															}
														</p>
													)}
												</div>
											</div>
											{/* Harga Modal */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Harga Modal*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															Rp.
														</div>
														<Controller
															control={control}
															name='distributor_price'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={
																		onChange
																	}
																	name={name}
																	getInputRef={register(
																		'distributor_price',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																		},
																	)}
																	placeholder='Masukan Harga Modal'
																	value={
																		value
																	}
																/>
															)}
														/>
													</div>
													{errors?.distributor_price
														?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors
																	?.distributor_price
																	?.message
															}
														</p>
													)}
												</div>
											</div>
											{/* Harga Pasar */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Harga Pasar*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															Rp.
														</div>
														<Controller
															control={control}
															name='msrp'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={
																		onChange
																	}
																	name={name}
																	getInputRef={register(
																		'msrp',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																		},
																	)}
																	placeholder='Masukan Harga Pasar'
																	value={
																		value
																	}
																/>
															)}
														/>
													</div>
													{errors?.msrp?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors?.msrp
																	?.message
															}
														</p>
													)}
													<HandleSwitch
														status={showMarketPrice}
														onChange={() =>
															setShowMarketPrice(
																!showMarketPrice,
															)
														}
													/>
												</div>
											</div>
											{/* Harga Jual */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Harga Jual*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															Rp.
														</div>
														<Controller
															control={control}
															name='original_price'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={(
																		e,
																	) => {
																		onChange(
																			e
																				.target
																				.value,
																		);
																		handleDiscount();
																	}}
																	name={name}
																	getInputRef={register(
																		'original_price',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																		},
																	)}
																	placeholder='Masukan Harga Jual'
																	value={
																		value
																	}
																/>
															)}
														/>
													</div>
													{errors?.original_price
														?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors
																	?.original_price
																	?.message
															}
														</p>
													)}
												</div>
											</div>
											{/* Presentase Diskon */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Presentase Diskon*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															%
														</div>
														<Controller
															control={control}
															name='discount_percentage'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={(
																		e,
																	) => {
																		onChange(
																			e
																				.target
																				.value,
																		);
																		handleDiscount();
																	}}
																	name={name}
																	getInputRef={register(
																		'discount_percentage',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																			max: {
																				value: 100,
																				message:
																					'Angka maksimal 100',
																			},
																		},
																	)}
																	placeholder='Masukan Presentase Diskon'
																	value={
																		value
																	}
																/>
															)}
														/>
													</div>
													{errors?.discount_percentage
														?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors
																	?.discount_percentage
																	?.message
															}
														</p>
													)}
												</div>
											</div>
											{/* Harga Jual Setelah Diskon */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Harga Jual Setelah Diskon*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															Rp.
														</div>
														<Controller
															control={control}
															name='selling_price'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={
																		onChange
																	}
																	name={name}
																	getInputRef={register(
																		'selling_price',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																		},
																	)}
																	placeholder='Masukan Harga Jual Setelah Diskon'
																	value={
																		value
																	}
																	disabled
																	style={{
																		background:
																			'#E0E0E0',
																	}}
																/>
															)}
														/>
													</div>
													{errors?.selling_price
														?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors
																	?.selling_price
																	?.message
															}
														</p>
													)}
												</div>
											</div>
											{/* Komisi Reseler */}
											<div className='grid grid-cols-8 items-center mt-8'>
												<label className='col-span-2'>
													Komisi Reseler*
												</label>
												<div className='col-span-6'>
													<div className='flex w-full'>
														<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
															Rp.
														</div>
														<Controller
															control={control}
															name='comm'
															render={({
																field: {
																	onChange,
																	name,
																	value,
																},
															}) => (
																<NumberFormat
																	className='form-input border rounded-r-lg text-sm w-full'
																	onChange={
																		onChange
																	}
																	name={name}
																	getInputRef={register(
																		'comm',
																		{
																			required:
																				{
																					value: true,
																					message:
																						'Wajib Diisi',
																				},
																			min: {
																				value: 0,
																				message:
																					'Angka tidak boleh minus',
																			},
																		},
																	)}
																	placeholder='Masukan Komisi Reseler'
																	value={
																		value
																	}
																/>
															)}
														/>
													</div>
													{errors?.comm?.message && (
														<p className='text-red-500 mt-2'>
															*
															{
																errors?.comm
																	?.message
															}
														</p>
													)}
													<HandleSwitch
														status={
															showResellerCommision
														}
														onChange={() =>
															setShowResellerCommision(
																!showResellerCommision,
															)
														}
													/>
												</div>
											</div>
										</>
									) : (
										<div
											style={{
												maxWidth: '100%',
												overflow: 'scroll',
											}}
											className='px-4 mt-4'
										>
											<Datatable
												columns={columns}
												data={variantType}
												checkbox={false}
												disablePagination
											/>
										</div>
									)}
								</div>
								{/* Informasi Pengiriman */}
								<div
									className='shadow rounded-lg p-4 mt-4'
									id='delivery'
								>
									<div className='text-base font-bold'>
										Informasi Pengiriman
									</div>
									{/* Panjang */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Panjang
										</label>
										<div className='col-span-6'>
											<div className='flex w-full'>
												<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
													cm
												</div>
												<Controller
													control={control}
													name='length'
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<NumberFormat
															className='form-input border rounded-r-lg text-sm w-full'
															onChange={(e) => {
																onChange(
																	e.target
																		.value,
																);
																handleVolume();
															}}
															name={name}
															getInputRef={register(
																'length',
																{
																	min: {
																		value: 0,
																		message:
																			'Angka tidak boleh minus',
																	},
																},
															)}
															placeholder='Masukan Panjang'
															value={value}
														/>
													)}
												/>
											</div>
											{errors?.length?.message && (
												<p className='text-red-500 mt-2'>
													*{errors?.length?.message}
												</p>
											)}
										</div>
									</div>
									{/* Lebar */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Lebar
										</label>
										<div className='col-span-6'>
											<div className='flex w-full'>
												<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
													cm
												</div>
												<Controller
													control={control}
													name='width'
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<NumberFormat
															className='form-input border rounded-r-lg text-sm w-full'
															onChange={(e) => {
																onChange(
																	e.target
																		.value,
																);
																handleVolume();
															}}
															name={name}
															getInputRef={register(
																'width',
																{
																	min: {
																		value: 0,
																		message:
																			'Angka tidak boleh minus',
																	},
																},
															)}
															placeholder='Masukan Lebar'
															value={value}
														/>
													)}
												/>
											</div>
											{errors?.width?.message && (
												<p className='text-red-500 mt-2'>
													*{errors?.width?.message}
												</p>
											)}
										</div>
									</div>
									{/* Tinggi */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Tinggi
										</label>
										<div className='col-span-6'>
											<div className='flex w-full'>
												<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
													cm
												</div>
												<Controller
													control={control}
													name='height'
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<NumberFormat
															className='form-input border rounded-r-lg text-sm w-full'
															onChange={(e) => {
																onChange(
																	e.target
																		.value,
																);
																handleVolume();
															}}
															name={name}
															getInputRef={register(
																'height',
																{
																	min: {
																		value: 0,
																		message:
																			'Angka tidak boleh minus',
																	},
																},
															)}
															placeholder='Masukan Tinggi'
															value={value}
														/>
													)}
												/>
											</div>
											{errors?.height?.message && (
												<p className='text-red-500 mt-2'>
													*{errors?.height?.message}
												</p>
											)}
										</div>
									</div>
									{/* Berat */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Berat*
										</label>
										<div className='col-span-6'>
											<div className='flex w-full'>
												<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
													kg
												</div>
												<Controller
													control={control}
													name='weight'
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<NumberFormat
															className='form-input border rounded-r-lg text-sm w-full'
															onChange={onChange}
															name={name}
															getInputRef={register(
																'weight',
																{
																	required: {
																		value: true,
																		message:
																			'Wajib Diisi',
																	},
																	min: {
																		value: 0,
																		message:
																			'Angka tidak boleh minus',
																	},
																},
															)}
															placeholder='Masukan Berat'
															value={value}
														/>
													)}
												/>
											</div>
											{errors?.weight?.message && (
												<p className='text-red-500 mt-2'>
													*{errors?.weight?.message}
												</p>
											)}
										</div>
									</div>
									{/* Volume */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Volume*
										</label>
										<div className='col-span-6'>
											<div className='flex w-full'>
												<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
													cm&sup3;
												</div>
												<Controller
													control={control}
													name='volume'
													render={({
														field: {
															onChange,
															name,
															value,
														},
													}) => (
														<NumberFormat
															className='form-input border rounded-r-lg text-sm w-full'
															onChange={onChange}
															name={name}
															getInputRef={register(
																'volume',
																{
																	required: {
																		value: true,
																		message:
																			'Wajib Diisi',
																	},
																	min: {
																		value: 0,
																		message:
																			'Angka tidak boleh minus',
																	},
																},
															)}
															placeholder='Masukan Volume'
															value={value}
															style={{
																background:
																	'#E0E0E0',
															}}
															disabled
														/>
													)}
												/>
											</div>
											{errors?.volume?.message && (
												<p className='text-red-500 mt-2'>
													*{errors?.volume?.message}
												</p>
											)}
										</div>
									</div>
								</div>
								{/* Lainnya */}
								<div
									className='shadow rounded-lg p-4 mt-4'
									id='other'
								>
									<div className='text-base font-bold'>
										Lainnya
									</div>
									{/* Status Produk */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Status Produk
										</label>
										<div className='col-span-6 '>
											<select
												className='form-select col-span-6 border rounded-lg text-sm w-full'
												{...register('status', {
													required: true,
												})}
											>
												<option value={10} selected>
													Aktif
												</option>
												<option value={0}>
													Tidak Aktif
												</option>
											</select>
											{errors.status &&
												errors.status.type ===
													'required' && (
													<div className='pt-2 text-red-500'>
														*Wajib Diisi!
													</div>
												)}
										</div>
									</div>
									{/* Visibilitas Produk */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Visibilitas Produk
										</label>
										<div className='col-span-6 '>
											<Controller
												control={control}
												name='visibilitas'
												render={({
													field: {
														onChange,
														name,
														value,
													},
												}) => (
													<Select
														isMulti
														name={name}
														value={value}
														onChange={onChange}
														className='basic-multi-select'
														placeholder='Pilih'
														options={membershipList}
														styles={customStyle}
													/>
												)}
											/>
											{errors.visibilitas &&
												errors.visibilitas.type ===
													'required' && (
													<div className='pt-2 text-red-500'>
														*Wajib Diisi!
													</div>
												)}
										</div>
									</div>
									{/* Tujuan Pengiriman */}
									<div className='grid grid-cols-8 items-center mt-8'>
										<label className='col-span-2'>
											Tujuan Pengiriman
										</label>
										<div className='col-span-6 '>
											<Controller
												control={control}
												name='destinationSelect'
												render={({
													field: {
														onChange,
														name,
														value,
													},
												}) => (
													<Select
														name={name}
														value={value}
														onChange={(e) => {
															onChange(e);
															setTypeDestination(
																e.value,
															);
														}}
														className='basic-multi-select'
														placeholder='Pilih'
														options={destination}
														styles={customStyle}
													/>
												)}
											/>
											{typeDestination === 1 && (
												<div className='mt-4'>
													<Controller
														control={control}
														name='destination'
														render={({
															field: {
																onChange,
																name,
																value,
															},
														}) => (
															<Select
																isMulti
																name={name}
																value={value}
																onChange={
																	onChange
																}
																className='basic-multi-select'
																placeholder='Pilih Kategori'
																options={
																	provinciesList
																}
																styles={
																	customStyle
																}
															/>
														)}
													/>
												</div>
											)}
											{errors.destination &&
												errors.destination.type ===
													'required' && (
													<div className='pt-2 text-red-500'>
														*Wajib Diisi!
													</div>
												)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</form>
				</div>
			)}
		</>
	);
};

export default Edit;
