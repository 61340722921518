import React, { useState } from 'react';
import PropTypes from 'prop-types';
import '../../css/components/tabs.css';
import { Badge, CircularBadge } from '../badges';

export const VerticalTabs = ({ tabs, customChild = false, children }) => {
  const [openTab, setOpenTab] = useState(0);
  return (
    <div className='flex flex-row items-start justify-start tabs-vertical'>
      <div className='flex-shrink-0 border rounded-md shadow-custom'>
        <div className='box-border flex flex-col flex-wrap py-2'>
          {tabs.map((tab, key) => (
            <button
              key={key}
              onClick={() => {
                setOpenTab(tab.index);
              }}
              className={`tab p-3 flex gap-x-3 items-center ${
                openTab === tab.index ? 'tab-active' : ''
              }`}
              type='button'
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>
      {!customChild && (
        <div className='w-full ml-10'>
          {tabs.map((tab, key) => (
            <div
              className={`tab-content ${
                openTab !== tab.index ? 'hidden' : 'block'
              }`}
            >
              {tab.content}
            </div>
          ))}
        </div>
      )}

      {customChild && (
        <div className='w-full ml-10'>
          {children}
        </div>
      )}
    </div>
  );
};

VerticalTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      content: PropTypes.element,
      title: PropTypes.any,
    })
  ).isRequired,
};

export const Pills = ({ tabs }) => {
  const [openTab, setOpenTab] = useState(0);
  return (
    <div className='flex flex-col flex-wrap w-full tabs'>
      <div className='flex flex-row lg:flex-wrap lg:space-x-2'>
        {tabs.map((tab, key) => (
          <div key={key} className='flex-none'>
            <button
              onClick={() => {
                setOpenTab(tab.index);
              }}
              className={`tab tab-pill ${
                openTab === tab.index ? 'tab-active' : ''
              }`}
              type='button'
            >
              {tab.title}
            </button>
          </div>
        ))}
      </div>
      {tabs.map((tab, key) => (
        <div
          className={`tab-content ${
            openTab !== tab.index ? 'hidden' : 'block'
          }`}
        >
          {tab.content}
        </div>
      ))}
    </div>
  );
};

Pills.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      content: PropTypes.element,
      title: PropTypes.any,
    })
  ).isRequired,
};

export const IconTabs = ({ tabs }) => {
  const [openTab, setOpenTab] = useState(0);
  return (
    <div className='flex flex-col flex-wrap w-full tabs'>
      <div className='flex flex-row lg:flex-wrap lg:space-x-2'>
        {tabs.map((tab, key) => (
          <div key={key} className='flex-none'>
            <button
              onClick={() => {
                setOpenTab(tab.index);
              }}
              className={`tab rounded-lg flex flex-row items-center justify-around ${
                openTab === tab.index ? 'tab-active' : ''
              }`}
              type='button'
            >
              {tab.title}
            </button>
          </div>
        ))}
      </div>
      {tabs.map((tab, key) => (
        <div
          className={`tab-content ${
            openTab !== tab.index ? 'hidden' : 'block'
          }`}
        >
          {tab.content}
        </div>
      ))}
    </div>
  );
};

IconTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      content: PropTypes.element,
      title: PropTypes.any,
    })
  ).isRequired,
};

export const UnderlinedTabs = ({ tabs, haveChild = true }) => {
  const [openTab, setOpenTab] = useState(0);
  return (
    <div className='flex flex-col flex-wrap w-full tabs'>
      <div className='flex flex-row border-b lg:flex-wrap border-blue-grey-100 lg:space-x-2'>
        {tabs.map((tab, key) => (
          <div key={key} className='flex items-center'>
            <button
              onClick={() => {
                setOpenTab(tab.index);
              }}
              className={
                openTab === tab.index
                  ? 'tab tab-underline tab-active'
                  : 'tab tab-underline'
              }
              type='button'
            >
              {tab.title}
              <CircularBadge
                color={
                  openTab === tab.index
                    ? 'bg-secondary ml-2 text-primary'
                    : 'bg-blue-grey-50 text-grey-500 ml-2'
                }
              >
                1
              </CircularBadge>
            </button>
          </div>
        ))}
      </div>
      {haveChild &&
        tabs.map((tab, key) => (
          <div
            key={key}
            className={`tab-content ${
              openTab !== tab.index ? 'hidden' : 'block'
            }`}
          >
            {tab.content}
          </div>
        ))}
    </div>
  );
};

UnderlinedTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      content: PropTypes.element,
      title: PropTypes.any,
    })
  ).isRequired,
};

export const DefaultTabs = ({ tabs }) => {
  const [openTab, setOpenTab] = useState(0);
  return (
    <div className='flex flex-col flex-wrap w-full tabs'>
      <div className='flex flex-row lg:flex-wrap lg:space-x-2'>
        {tabs.map((tab, key) => (
          <div key={key} className='flex-none'>
            <button
              onClick={() => {
                setOpenTab(tab.index);
              }}
              className={`tab ${openTab === tab.index ? 'tab-active' : ''}`}
              type='button'
            >
              {tab.title}
            </button>
          </div>
        ))}
      </div>
      {tabs.map((tab, key) => (
        <div
          className={`tab-content ${
            openTab !== tab.index ? 'hidden' : 'block'
          }`}
        >
          {tab.content}
        </div>
      ))}
    </div>
  );
};

DefaultTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      content: PropTypes.element,
      title: PropTypes.any,
    })
  ).isRequired,
};
