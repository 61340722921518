import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { FiX } from 'react-icons/fi';
import Portal from '../../../components/portal';

const FeatureComingSoonModal = ({className, text}) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef]);

  return (
    <>
      <button type='button' onClick={show}>
        <div className={className}>
          <span>{text}</span>
        </div>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h6 className='font-bold'>Coming Soon...</h6>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex-auto p-4 space-y-5 text-center'>
                  <span className='block text-grey-600'>Fitur ini sedang dalam pengembangan. Kami akan informasikan setelah fitur ini rilis.</span>
                  <span className='text-grey-600'>Terima kasih</span>
                  <div className='self-center mt-5 space-x-2 text-center'>
                    <button
                      className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                      type='button'
                      onClick={hide}
                    >
                      Tutup
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default FeatureComingSoonModal;
