import queryCommander from "../queryCommander";

export const getListProvincies = () => queryCommander({
  method: "GET",
  path: "/addresses/provinces",
  queryType: "query",
  authenticated: true,
})

export const doGetListCities = (id: any) => queryCommander({
  method: "GET",
  path: `/addresses/cities/${id}`,
  queryType: "query",
  authenticated: true,
})

export const doGetListDistrict = (id: any) => queryCommander({
  method: "GET",
  path: `/addresses/districts/${id}`,
  queryType: "query",
  authenticated: true,
})

export const doGetAllCities = () => queryCommander({
  method: 'GET',
  path: "/addresses/all_cities",
  queryType: "query",
  authenticated: true
})