import React, { useEffect, useState } from 'react';
import { unmountComponentAtNode } from "react-dom";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import grapejs from 'grapesjs';
import ModalShop from './components/modal';
import { doGetLandingPage, doUpdateLandingPage } from '../../services/api/command/landing-page.command';
import { queryClient } from '../../App';
import cssDefaultValue from './defaults/cssEditorValue';
import htmlDefaultValue from './defaults/htmlEditorValue';
import styleDefaultValue from './defaults/stylesEditorValue';
import projectDefaultValue from './defaults/projectEditorValue';
import { doGetProfile } from 'services/api/command/profile.command';

function LandingBuilder() {
  const history = useHistory();
  const [save, saveTemplate] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const profile = doGetProfile().query('profile').data?.data.data;
  const landingPage = doGetLandingPage().query('landing-page').data?.data;

  if (typeof(landingPage) === 'undefined') {
    localStorage.setItem('gjs-styles', styleDefaultValue);
    localStorage.setItem('gjs-css', cssDefaultValue);
    localStorage.setItem('gjs-html', htmlDefaultValue);
    // localStorage.setItem('gjs-components', landingPage?.component);
    localStorage.setItem('gjsProject', projectDefaultValue);
  } else {
    localStorage.setItem('gjs-styles', landingPage?.style);
    localStorage.setItem('gjs-css', landingPage?.css);
    localStorage.setItem('gjs-html', landingPage?.html);
    // localStorage.setItem('gjs-components', landingPage?.component);
    localStorage.setItem('gjsProject', landingPage?.project);
  }
  
  useEffect(() => {
    const editor = grapejs.init({
      container: '#gjs',
      plugins: ['gjs-preset-webpage'],
      storageManager: {
        id: 'gjs-', // Prefix identifier that will be used on parameters
        type: 'local', // Type of the storage
        // Store data automatically
        save,
      },
    });
  

    return () => { // remove when unmount
      unmountComponentAtNode(document.getElementById('gjs'));
    }
  }, [save, landingPage]);

  const onSave = () => {
    saveTemplate(true);

    const styles = localStorage.getItem('gjs-styles');
    const html = localStorage.getItem('gjs-html');
    const css = localStorage.getItem('gjs-css');
    const component = localStorage.getItem('gjs-components');
    const project = localStorage.getItem('gjsProject');
    const page = `<html><head><style>${css}</style></head>${html}<html>`;

    const body = {
      'content': page,
      'html': html,
      'css': css,
      'style': styles,
      'component': component,
      'project': project
    }

    doUpdateLandingPage()
      .command(body)
      .then((response) => {
        toast.success('Landing Page berhasil diperbarui.');
        localStorage.removeItem("gjs-styles");
        localStorage.removeItem("gjs-html");
        localStorage.removeItem("gjs-css");
        localStorage.removeItem("gjs-components");
        localStorage.removeItem("gjsProject");
        queryClient.invalidateQueries('landing-page');
        history.goBack();
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  };

  const handleBack = () => {
    history.goBack();
  }

  return (
    <>
      <div className='flex items-center justify-between mb-6 '>
        <h5 className='py-3 font-bold'>Landing Page</h5>
        <div className='flex items-center gap-x-10'>
          <div
            onClick={() => setModalOpen(true)}
            className='cursor-pointer text-primary hover:underline'
          >
            Lihat Toko Saya
          </div>
          <div className='flex items-center justify-between gap-x-4'>
            <button className='px-8 py-2 border rounded-lg btn text-primary border-primary' onClick={handleBack}>
              Kembali
            </button>
            <button
              onClick={onSave}
              className='px-8 py-2 text-white border rounded-lg btn bg-primary border-primary'
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
      <div id='gjs' className='border'></div>
      <ModalShop open={isModalOpen} setOpen={setModalOpen} data={profile} />
    </>
  );
}

export default LandingBuilder;
