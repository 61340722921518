import queryCommander from "../queryCommander";

interface TMembershipListRequest {
  limit: number;
  page: number;
  q: string;
  status: string;
}

export const doGetMembershipList = <TMembershipList = TMembershipListRequest>() => queryCommander<TMembershipList>({
  method: "GET",
  path: "/agent/agent_list",
  queryType: "query",
  authenticated: true
});

interface TMembershipApprovalRequest {
  agent_id: number,
  status: number
}

export const doApprovalMembership = <TApprovalMembership = TMembershipApprovalRequest>() => queryCommander<TApprovalMembership>({
  method: "POST",
  path: "/agent/approval",
  queryType: "query",
  authenticated: true
});

interface TMembershipLevelRequest {
  limit: number;
  page: number;
  q: string;
  status: string;
}

export const doGetMembershipLevelList = <TMembershipLevel = TMembershipLevelRequest>() => queryCommander<TMembershipLevel>({
  method: "GET",
  path: "/level/list",
  queryType: "query",
  authenticated: true
});

interface TCreateMembershipLevelRequest {
  name: string,
  description: string,
  transaction_duration: number,
  transaction_minimum: number,
  color: string,
  commission_percentage: number,
  benefit: string,
  is_active: number
}

export const doCreateMembershipLevel = <TCreateMembershipLevel = TCreateMembershipLevelRequest>() => queryCommander<TCreateMembershipLevel>({
  method: "POST",
  path: "/level/create",
  queryType: "query",
  authenticated: true
})

interface TUpdateMembershipLevelRequest {
  id: number,
  name: string,
  description: string,
  transaction_duration: number,
  transaction_minimum: number,
  color: string,
  commission_percentage: number,
  benefit: string,
  is_active: number
}

export const doUpdateMembershipLevel = <TUpdateMembershipLevel = TUpdateMembershipLevelRequest>() => queryCommander<TUpdateMembershipLevel>({
  method: "PUT",
  path: "/level/update",
  queryType: "query",
  authenticated: true
})

interface TStatusMembershipLevelRequest {
  id: number,
  status: number
}

export const doUpdateStatusMembershipLevel = <TStatusMembershipLevel = TStatusMembershipLevelRequest>() => queryCommander<TStatusMembershipLevel>({
  method: "PUT",
  path: "/level/change-status",
  queryType: "query",
  authenticated: true
});

export const doGetMembershipType = () => queryCommander({
  method: 'GET',
  path: '/subscribe/list',
  queryType: 'query',
  authenticated: true
})

interface TUpdateStatusMembershipTypeRequest {
  kode: string,
  status: string
}

export const doUpdateStatusMembershipType = <TUpdateStatusMembershipType = TUpdateStatusMembershipTypeRequest>() => queryCommander<TUpdateStatusMembershipType>({
  method: 'PUT',
  path: '/subscribe/change-status',
  queryType: 'query',
  authenticated: true
})

interface TUpdateMembershipTypeRequest {
  brandowner_id: number,
  description: string,
  kode: number,
  label: string,
  status: string,
  package: Package[]
}

interface Package {
  duration: number,
  id: number,
  name: string,
  price: number,
  status: number
}

export const doUpdateMembershipType = <TUpdateMembershipType = TUpdateMembershipTypeRequest>() => queryCommander<TUpdateMembershipType>({
  method: 'PUT',
  path: '/subscribe/update',
  queryType: 'query',
  authenticated: true
})

interface TReferralCodeRequest {
  limit: number;
  page: number;
  keyword: string;
  status: string;
}

export const doGetReferralCodeList = <TReferralCode = TReferralCodeRequest>() => queryCommander<TReferralCode>({
  method: "GET",
  path: "/access-code/list",
  queryType: "query",
  authenticated: true
});

interface TCreateReferralCodeRequest {
  brandowner_id: number,
  count: number,
  prefix: string
}

export const doCreateReferralCode = <TCreateReferralCode = TCreateReferralCodeRequest>() => queryCommander<TCreateReferralCode>({
  method: "GET",
  path: "/access-code/generate",
  queryType: "query",
  authenticated: true
});