import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { queryClient } from '../../App';
import Breadcrumb from '../../components/breadcrumbs';
import { createCategoryRequest } from '../../services/api/command/categories.command';
import { Button } from '../../components/buttons/buttons';
import toast from 'react-hot-toast';
import { createMediaRequest } from '../../services/api/command/media.command';

function CreateCategory() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();

  const breadcrumbs = [
    { title: 'List Kategori', url: '/orcategories', last: false },
    { title: `Tambah Kategori`, url: '', last: true },
  ];

  const handleCreateCategory = () => {
    handleUploadImage().then((response) => {
      createCategoryRequest()
        .command({
          ...getValues(),
          parent_id: 0,
          article_hierarchy: '',
          attribute_set_id: 0,
          attribute_variant_set_id: 0,
          article_hierarchy_parent: '',
          path: '',
          image_app: response.public_url,
          image_web: '',
          image: '',
          include_in_menu: 0,
          status: 10,
          order_no: 0,
        })
        .then(() => {
          setLoading(false);
          queryClient.invalidateQueries('category list');
          resetField();
          history.goBack();
        })
        .catch((err) => {
          toast.error(err.response.data.error.message);
          setLoading(false);
        });
    });
  };

  const handleUploadImage = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', image);
    formData.append('source', 'upload');
    formData.append('provider', 'cloudinary');
    formData.append('title', getValues('name'));
    formData.append('description', getValues('description'));

    return createMediaRequest()
      .command(formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      });
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(e.target.files[0].name);
  };

  return (
    <div className='px-20'>
      <Breadcrumb items={breadcrumbs} />
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Tambah Kategori</h4>
        <div className=''>
          <Button
            loading={loading}
            text={'Simpan'}
            onClick={handleCreateCategory}
            className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
          />
        </div>
      </div>
      <div className='flex flex-col my-6'>
        <div className='w-72'>
          <label className='col-span-2'>Nama Kategori</label>
          <input
            type='text'
            className='form-input border mt-2 rounded-lg text-sm w-full'
            placeholder='Masukkan nama kategori'
            name='name'
            {...register('name', { required: true })}
          />
        </div>
        <div className='w-72 mt-6'>
          <label className='col-span-2'>Deskripsi Kategori</label>
          <textarea
            type='text'
            className='form-input border mt-2 rounded-lg text-sm w-full'
            placeholder='Masukkan deskripsi kategori'
            {...register('description', { required: true })}
          />
        </div>
      </div>
      <label className='col-span-2 mb-3'>Upload Logo</label>
      <div className='mt-2'>
        <label className='w-72 flex flex-col items-center px-4 py-2 bg-white rounded-md shadow-sm border cursor-pointer text-primary ease-linear transition-all duration-150'>
          <span className='text-base leading-normal'>Plih Logo</span>
          <input
            accept='.jpg,.png'
            onChange={(res) => handleChangeImage(res)}
            type='file'
            className='hidden'
          />
        </label>
        {imagePath && <div className='mt-2 text-primary'>../{imagePath}</div>}
      </div>
    </div>
  );
}

export default CreateCategory;
