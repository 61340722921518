import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { FiArchive, FiBox, FiCreditCard, FiEye, FiFile, FiHeart, FiMenu, FiSettings, FiTruck } from 'react-icons/fi';
import Breadcrumb from '../../components/breadcrumbs';
import { VerticalTabs } from '../../components/tabs';
import OrderInformation from './components/order-information';
import OrderStatus from './components/order-status';
import PaymentInformation from './components/payment-information';
import ProductInformation from './components/product-information';
import ShippingInformation from './components/shipping-information';
import { doGetInvoiceDetail, doGetOrderDetail } from '../../services/api/command/order.command';
import UpdateStatusInvoiceModal from './components/modals/update-status';
import OrderModal from './components/modals/order';

function OrderDetailPage() {
  let { id } = useParams();

  const breadcrumbs = [
    { title: 'List Order', url: '/orders', last: false },
    { title: `Detail Order ${id}`, url: '', last: true },
  ];

  const pills = [
    {
      index: 0,
      content: '',
      title: (
        <>
          <FiFile size={18} style={{ stroke :'#71869D' }} />
          <span>Status Order</span>
        </>
      ),
    },
    {
      index: 1,
      title: (
        <>
          <FiArchive size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Order</span>
        </>
      ),
      content: '',
    },
    {
      index: 2,
      title: (
        <>
          <FiBox size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Produk</span>
        </>
      ),
      content: '',
    },
    {
      index: 3,
      title: (
        <>
          <div>
            <FiCreditCard size={18} style={{ stroke :'#71869D' }} />
          </div>
          <div>
            <span>Informasi Pembayaran</span>
          </div>
        </>
      ),
      content: '',
    },
    {
      index: 4,
      title: (
        <>
          <FiTruck size={18} style={{ stroke :'#71869D' }} />
          <span>Informasi Pengiriman</span>
        </>
      ),
      content: '',
    },
  ];

  const response = doGetOrderDetail(id).query(['order']);
  const order = response.data?.data.data.order;
  const status = response.data?.data.data.order.status;
  const products = response.data?.data.data.items;
  const subtotal = response.data?.data.data.order.subtotal || 0;

  return (
    <div className='px-20'>
      <Breadcrumb items={breadcrumbs} />
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Detail Order {id}</h4>
        <div className='flex gap-x-2'>
          <OrderModal order={response.data?.data.data} />
          {status == 'paid' && (
            <UpdateStatusInvoiceModal order={response.data?.data.data} />
          )}
        </div>
      </div>
      <div className='flex flex-wrap my-6'>
        <div className='w-full'>
          <VerticalTabs customChild={true} tabs={pills}>
            <OrderStatus data={status} />
            <OrderInformation data={order} />
            <ProductInformation data={products} subtotal={subtotal} />
            <PaymentInformation data={order} />
            <ShippingInformation data={order} />
          </VerticalTabs>
        </div>
      </div>
    </div>
  );
}

export default OrderDetailPage;
