import React, {useState} from 'react'
import SwitchComponent from 'react-switch'
import {getColor} from '../../functions/colors'

const Switch = ({initialState = false, color = 'blue', hasText = false, textWhenTrue = '', textWhenFalse = ''}) => {
  const [checked, handleChange] = useState(initialState)
  let offColor = `bg-blue-grey-100`
  let offHandleColor = 'bg-blue-grey-200'

  return (
    <div className='flex'>
      <SwitchComponent
        onChange={() => handleChange(!checked)}
        checked={checked}
        onColor='#B1D5DB'
        onHandleColor='#2FAEC3'
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        height={8}
        width={38}
        className="react-switch "
      />
      {hasText && <span className='pl-3'>{checked ? textWhenTrue : textWhenFalse}</span>}
    </div>
  )
}

export default Switch
