import { string } from 'prop-types';
import React from 'react';
import { FiCheckCircle, FiDownload, FiEdit, FiFilter, FiTrash, FiUser, FiUserPlus } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { CircularBadge } from '../../components/badges';
import Datatable from '../../components/datatable';
import Modal from '../../components/modals/modal';
import Search from '../../components/navbar-1/search';
import countries from '../../json/countries.json';
import { doGetReferralCodeList } from '../../services/api/command/membership.command';
import CreateMembershipReferralModal from './components/modal';

function MembershipsReferral() {
  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const referralCodeList = doGetReferralCodeList().query(
    ['membership referral list', page, pageSize],
    {},
    { limit: pageSize, page: page }
  );

  let rows = [];

  if (! referralCodeList.error && referralCodeList.status !== 'loading') {
    rows = referralCodeList.data?.data.data.list; 
  }
  
  const handleChangePage = (newPage) => {
    setPage(newPage.selected + 1);
  }

  const buttonSubmit = () => {

  }

  const Table = () => {
    const columns = React.useMemo(
      () => [
        {
          Header: 'Kode Referral',
          accessor: 'code',
          Cell: (props) => (
            <div className='flex items-center justify-start gap-x-8'>
              <div>
                <span>{props.value}</span>
              </div>
            </div>
          ),
        },
        {
          Header: 'Status',
          accessor: 'status_text',
          Cell: (props) => {
            let row = props.row.original;
            return (
              <div className={`inline-flex px-2 py-1 text-xs text-center rounded-lg ${row.status === 0 ? 'text-success-primary bg-success-secondary' : 'text-warning-primary bg-warning-secondary'}`}>{props.value}</div>
            )
          },
        },
        {
          Header: 'Nama Brand',
          accessor: 'brandowner_name',
          Cell: (props) => <span>{props.value}</span>,
        },
        {
          Header: 'Tanggal Generate',
          accessor: 'created_at',
          Cell: (props) => <span>{props.value}</span>,
        },
        {
          Header: 'Tanggal Redeem',
          accessor: 'used_at',
          Cell: (props) => <span>{props.value}</span>,
        },
      ],
      []
    );

    const data = React.useMemo(() => rows, [rows]);
    return (
      <Datatable
        totalPage={referralCodeList?.data?.data.data.total_page}
        columns={columns}
        handleChangePage={handleChangePage}
        data={data}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    );
  };

  return (
    <div>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Referral Code</h4>
        <CreateMembershipReferralModal />
      </div>
      <div className='mt-6 border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Kode referral' />
          <div className='flex'>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiDownload className='stroke-current text-md' />
              <a href='#' className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'>Export</a>
            </div>
            <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
              <FiFilter className='stroke-current text-md' />
              <select
                className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
                // value={pageSize}
                // onChange={(e) => {
                //   setPageSize(Number(e.target.value));
                // }}
              >
                {['Filter', 25, 50, 100].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </select>
              <span>
                <CircularBadge color='bg-blue-grey-100 text-black'>
                  1
                </CircularBadge>
              </span>
            </div>
          </div>
        </div>
        <Table />
      </div>
    </div>
  );
}

export default MembershipsReferral;
