import queryCommander from "../queryCommander";

// export const getLandingPageTemplates = () => queryCommander({
//   method: "GET",
//   path: "/brand/landing_page/list",
//   queryType: "query",
//   authenticated: true,
// })

export const doGetLandingPage = () => queryCommander({
  method: "GET",
  path: "/brand/landing_page/detail",
  queryType: "query",
  authenticated: true
})

interface TLandingPageUpdateRequest {
  content: string;
  html: string;
  css: string;
  style: string;
  components: string;
  projects: string;
}

export const doUpdateLandingPage = <TLandingPageUpdate = TLandingPageUpdateRequest>() => queryCommander<TLandingPageUpdate>({
  method: "PUT",
  path: "/brand/landing_page/update",
  queryType: "query",
  authenticated: true
});