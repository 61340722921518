import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { set, useForm } from 'react-hook-form';
import { TiCreditCard, TiUserOutline } from 'react-icons/ti';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/buttons/buttons';
import phoneSanity from '../../functions/phoneSanity';
import { getCourierList } from '../../services/api/command/courier.command';
import { doGetPaymentBrandownerList } from '../../services/api/command/payment.command';
import { doCheckAvailableSubdomain, doGetProfile, doUpdateProfile } from '../../services/api/command/profile.command';
import { createMediaRequest } from '../../services/api/command/media.command';
import { queryClient } from '../../App';

function Profile() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [paymentBrandowner, setPaymentBrandowner] = useState(null);
  const [availability, setAvailability] = useState(null);
  const [availabilityInfo, setAvailabilityInfo] = useState('hidden');
  
  const {
    control,
		register,
		handleSubmit,
		getValues,
		setValue,
    resetField,
		formState: { errors },
	} = useForm();

  const profile = doGetProfile().query('profile').data?.data.data;
  const payment_brandowners = doGetPaymentBrandownerList().query('payment brandowner list');
  const couriers = getCourierList().query('courier list');
  
  const handleChangePaymentBrandowner = (payment) => {
    setPaymentBrandowner(payment);
  }

  const handleUploadImage = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append('file', image);
    formData.append('source', 'upload');
    formData.append('provider', 'cloudinary');
    formData.append('title', getValues('name'));
    formData.append('description', getValues('description'));

    return createMediaRequest()
      .command(formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      });
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(e.target.files[0].name);
  };

  const handleUpdate = () => {
    if (image) {
      handleUploadImage().then((response) => {
        const body = {
          name: getValues('name') || profile?.name,
          phone: phoneSanity(getValues('phone')) || profile?.phone,
          image: response.public_url || profile?.image,
          banner: getValues('banner') || profile?.banner,
          email: getValues('email') || profile?.email,
          address: getValues('address') || profile?.address,
          bank_account_name: getValues('bank_account_name') || profile?.bank_account_name,
          bank_account_number: getValues('bank_account_number') || profile?.bank_account_number,
          bank_name: getValues('bank_name') || profile?.bank_name,
          status: 10,
          payment_brandowner_id: parseInt(getValues('payment_brandowner_id') || profile?.payment_brandowner.id),
          subdomain: getValues('subdomain') || profile?.subdomain,
          supported_couriers: getValues('supported_couriers') || []
        }
    
        doUpdateProfile()
          .command(body)
          .then((response) => {
            setLoading(false);
            toast.success('Profil berhasil diubah');
            queryClient.invalidateQueries('profile');
            resetField();
            history.go(0);
          })
          .catch((err) => {
            toast.error(err.response.data.error.message);
            setLoading(false);
          })
      });
    } else {
      const body = {
        name: getValues('name') || profile?.name,
        phone: phoneSanity(getValues('phone')) || profile?.phone,
        image: profile?.image,
        banner: getValues('banner') || profile?.banner,
        email: getValues('email') || profile?.email,
        address: getValues('address') || profile?.address,
        bank_account_name: getValues('bank_account_name') || profile?.bank_account_name,
        bank_account_number: getValues('bank_account_number') || profile?.bank_account_number,
        bank_name: getValues('bank_name') || profile?.bank_name,
        status: 10,
        payment_brandowner_id: parseInt(getValues('payment_brandowner_id') || profile?.payment_brandowner.id),
        subdomain: getValues('subdomain') || profile?.subdomain,
        supported_couriers: getValues('supported_couriers') || []
      }
  
      doUpdateProfile()
      .command(body)
      .then((response) => {
        setLoading(false);
        toast.success('Profil berhasil diubah');
        queryClient.invalidateQueries('profile');
        resetField();
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
        setLoading(false);
      })
    }
  }

  const handleSubdomainCheck = () => {
    const body = {
      subdomain: getValues('subdomain')
    }

    if (getValues('subdomain').length <= 2) {
      setLoading(false);
      setAvailability(false);
      setAvailabilityInfo('');
    } else {
      doCheckAvailableSubdomain()
      .command(body)
      .then((response) => {
        setLoading(false);
        setAvailability(response.data.available);
        setAvailabilityInfo('');
      })
      .catch((err) => {
        // toast.error(err.response.data.error.message);
        setLoading(false);
        setAvailability(null);
        setAvailabilityInfo('hidden');
      })
    }
  }

  return (
    <>
      <div className="w-full pt-3 mb-6">
        <div className="flex flex-row items-end justify-between mb-4">
          <div className="flex flex-col">
            <div className="text-xl font-bold">Profil Brand</div>
          </div>
        </div>
      </div>
      <div className='flex justify-end mb-3'>
        <Button
          loading={loading}
          text={'Simpan'}
          onClick={handleUpdate}
          className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
        />
        {/* <button 
          className="px-4 py-2 text-xs font-bold text-white uppercase bg-primary rounded-lg hover:bg-[color:#175761] space-x-1"
        >
          <span>Simpan</span>
        </button> */}
      </div>
      <div className="flex flex-row w-full mb-2 space-x-5">
        <div className="w-1/4">
          <div className="w-full p-4 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800">
            <div className="flex flex-col space-y-6">
              <a href="#informasi-brand" className="flex flex-row text-primary">
                <TiUserOutline className='mr-2 text-lg stroke-current' />
                <span>Informasi Brand</span>
              </a>
              <a href="#informasi-pembayaran" className="flex flex-row">
                <TiCreditCard className='mr-2 text-lg stroke-current' />
                <span>Informasi Pembayaran</span>
              </a>
            </div>
          </div>
        </div>
        <div className='w-full space-y-8'>
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="informasi-brand">
            <div className='flex justify-between'>
              <div className="mb-5 font-bold">Informasi Brand</div>
            </div>
            <div className="flex flex-row items-start space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Subdomain Brand</label>
              <div className='flex flex-row justify-center w-full space-x-3'>
                <div className='flex flex-col w-full'>
                  <div className='space-x-2'>
                    <input 
                      placeholder="Masukkan subdomain" 
                      type="text" 
                      name="subdomain" 
                      disabled={profile?.subdomain == '' ? false : true}
                      defaultValue={profile?.subdomain}
                      className="w-3/5 bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm"
                      {...register('subdomain')}
                    />
                    <span className='w-2/5'>.rintiz.id</span>
                  </div>
                  <div className='pt-2 text-xs text-blue-300'>Subdomain minimal terdiri dari 5 karakter</div>
                  <div className={`${availabilityInfo} pt-2 ${availability ? 'text-green-500' : 'text-red-500'}`}>Subdomain {availability ? 'tersedia' : 'tidak tersedia'}</div>
                </div>
                {profile?.subdomain == '' && (
                  <Button
                    loading={loading}
                    text={'Cek Ketersediaan Domain'}
                    onClick={handleSubdomainCheck}
                    className='self-start text-center cursor-pointer px-4 py-2 text-xs w-1/2 font-bold text-white bg-blue-300 rounded-lg hover:bg-[color:#175761] space-x-1'
                  />
                )}
                {profile?.subdomain != '' && (
                  <a 
                    href={`https://${profile?.subdomain}.rintiz.id`}
                    className='self-start text-center cursor-pointer px-4 py-2 text-xs w-1/2 font-bold text-white bg-primary rounded-lg hover:bg-[color:#175761] space-x-1'
                  >
                    Kunjungi Website Brand
                  </a>
                )}
              </div>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nama Brand</label>
              <input 
                placeholder="Masukkan nama brand" 
                type="text" 
                name="name" 
                defaultValue={profile?.name}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('name')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nomor HP</label>
              <input 
                placeholder="Masukkan nomor hp" 
                type="text" 
                name="phone" 
                defaultValue={profile?.phone}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('phone')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Email</label>
              <input 
                placeholder="Masukkan email" 
                type="email" 
                name="email" 
                defaultValue={profile?.email}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('email')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Alamat</label>
              <input 
                placeholder="Masukkan alamat" 
                type="text" 
                name="address" 
                defaultValue={profile?.address}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('address')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className='block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap'>Upload Logo</label>
              <div className='block w-full mt-2'>
                {profile?.image && (
                  <img src={profile?.image} alt="" className='object-cover w-48 h-48 mb-2' />
                )}
                <label className='flex flex-col items-center w-32 px-4 py-2 transition-all duration-150 ease-linear bg-white border rounded-md shadow-sm cursor-pointer text-primary'>
                  <span className='text-base leading-normal'>Pilih Logo</span>
                  <input
                    accept='.jpg,.png'
                    onChange={(res) => handleChangeImage(res)}
                    type='file'
                    className='hidden'
                  />
                </label>
                {imagePath && <div className='mt-2 text-primary'>../{imagePath}</div>}
                <span className='block pt-2 text-xs text-blue-300'>Hanya menerima file *.jpg dan *.png. Ukuran maksimum 2MB.</span>
              </div>
            </div>
            {/* <div className="flex flex-row items-start space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Ganti Kata Sandi</label>
              <div className='flex flex-col w-full space-y-3'>
                <input placeholder="Masukkan kata sandi lama" type="password" name="old_password" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
                <input placeholder="Masukkan kata sandi baru" type="password" name="new_password" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
                <input placeholder="Masukkan kata sandi baru" type="password" name="repeat_new_password" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
              </div>
            </div> */}
          </div>
          <div className="w-full p-4 space-y-8 bg-white border border-gray-100 rounded-lg dark:bg-gray-900 dark:border-gray-800" id="informasi-pembayaran">
            <div className='flex justify-between'>
              <div className="mb-5 font-bold">Informasi Pembayaran</div>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Metode Pembayaran</label>
              <select 
                name="payment_brandowner_id" 
                className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                {...register('payment_brandowner_id')}
                value={paymentBrandowner || profile?.payment_brandowner.id}
                onChange={(event) => handleChangePaymentBrandowner(event.target.value)}
              >
                <option value="">Pilih</option>
                {payment_brandowners.data?.data.data.map((payment) => (
                  <option value={payment.id}>{payment.payment}</option>  
                ))}
              </select>
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Bank</label>
              <input 
                placeholder="Masukkan nama bank" 
                type="text" 
                name="bank_name" 
                defaultValue={profile?.bank_name}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('bank_name')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nama Pemilik Rekening</label>
              <input 
                placeholder="Masukkan nama pemilik rekening" 
                type="text" 
                name="bank_account_name" 
                defaultValue={profile?.bank_account_name}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('bank_account_name')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Nomor Rekening</label>
              <input 
                placeholder="Masukkan nomor rekening" 
                type="text" 
                name="bank_account_number" 
                defaultValue={profile?.bank_account_number}
                className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                {...register('bank_account_number')}
              />
            </div>
            <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Ekspedisi Pengiriman</label>
              <div className="block w-full">
                <select 
                  disabled
                  name="selected_couriers[]" 
                  className="block w-full bg-white border-gray-300 rounded-md dark:bg-gray-800 dark:border-gray-700 form-select focus:ring-blue-500 focus:border-blue-500 focus:ring-0 sm:text-sm"
                  {...register('selected_couriers[]')}
                >
                  <option value="">Woobiz Courier</option>
                  {couriers.data?.data.data.couriers.map((courier) => (
                    <option value={courier.courier_code}>{courier.courier_name} - {courier.courier_service_name}</option>  
                  ))}
                </select>
                <div className='block w-full pt-2 text-red-500'>*Fitur ini masih dalam pengembangan</div>
              </div>
            </div>
            {/* <div className="flex flex-row items-center space-x-5">
              <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">Cabang</label>
              <input placeholder="Masukkan cabang bank" type="text" name="name" className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
