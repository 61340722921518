export const categories = [
  {
    label: 'Alat Elektronik',
    value: 'Alat Elektronik',
  },
  {
    label: 'Alat Tulis Kantor',
    value: 'Alat Tulis Kantor',
  },
  {
    label: 'Fashion Anak & Bayi',
    value: 'Fashion Anak & Bayi',
  },
  {
    label: 'Fashion Pria',
    value: 'Fashion Pria',
  },
  {
    label: 'Fashion Wanita',
    value: 'Fashion Wanita',
  },
  {
    label: 'Ibu & Bayi',
    value: 'Ibu & Bayi',
  },
  {
    label: 'Kecantikan',
    value: 'Kecantikan',
  },
  {
    label: 'Kesehatan',
    value: 'Kesehatan',
  },
  {
    label: 'Komputer & Laptop',
    value: 'Komputer & Laptop',
  },
  {
    label: 'Mainan & Hobi',
    value: 'Mainan & Hobi',
  },
  {
    label: 'Makanan & Minuman',
    value: 'Makanan & Minuman',
  },
  {
    label: 'Olahraga',
    value: 'Olahraga',
  },
  {
    label: 'Otomotif',
    value: 'Otomotif',
  },
  {
    label: 'Peralatan Dapur',
    value: 'Peralatan Dapur',
  },
  {
    label: 'Perawatan Hewan',
    value: 'Perawatan Hewan',
  },
  {
    label: 'Perawatan Tubuh',
    value: 'Perawatan Tubuh',
  },
  {
    label: 'Pertukangan',
    value: 'Pertukangan',
  },
  {
    label: 'Pulsa & Voucher/Tagihan',
    value: 'Pulsa & Voucher/Tagihan',
  },
  {
    label: 'Rumah Tangga',
    value: 'Rumah Tangga',
  }
]