import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layouts from './layouts';
import Routes from './Routes';

// GrapesJS
import 'grapesjs/dist/css/grapes.min.css';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.css';
import 'grapesjs/dist/grapes.min.js';
import 'grapesjs-preset-webpage/dist/grapesjs-preset-webpage.min.js';

import './css/tailwind.css';
import './css/animate.css';
import './css/main.css';
import './css/_components.css';
import { QueryClient, QueryClientProvider } from 'react-query';

export const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			cacheTime: 30000,
			staleTime: 50000,
			refetchOnWindowFocus: true,
			retry: 1,
		},
	},
});

const Wrapper = ({ children }) => {
	return <Layouts>{children}</Layouts>;
};

const App = () => {
	return (
		<Router>
			<QueryClientProvider client={queryClient}>
				<Wrapper>
					<Routes />
				</Wrapper>
			</QueryClientProvider>
		</Router>
	);
};
export default App;
