import React, { useState } from 'react';
import { formatNumber } from '../../../functions/numbers';
import Datatable from '../../../components/datatable';
import { FiTrash, FiEdit, FiFilter, FiBox, FiFile } from 'react-icons/fi';
import Search from '../../../components/navbar-1/search';
import { CircularBadge } from '../../../components/badges';
import { useHistory } from 'react-router-dom';
import formatPrice from '../../../functions/formatPrice';
import { doGetBrandSubscriptionHistoryList } from '../../../services/api/command/brand-subscription.command';
import ucwords from '../../../functions/ucwords';

const Table = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const subscriptionHistoryList = doGetBrandSubscriptionHistoryList().query(
    ['subscription history list', page, pageSize],
    {},
    { limit: pageSize, page: page }
  );
  
  const handleChangePage = (newPage) => {
    setPage(newPage.selected + 1);
  }

  const handleViewInvoice = (id) => {
      history.push(`/subscriptions/invoice/${id}`);
  }

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tanggal',
        accessor: 'created_at',
        Cell: (props) => (
          <div className='flex items-center justify-start gap-x-8'>
            <span className='text-grey-600'>{props.value}</span>
          </div>
        ),
      },
      {
        Header: 'No. Invoice',
        accessor: 'transaction_number',
        Cell: (props) => {
          return (
            <div className='flex items-center justify-start gap-x-8'>
              <span className=''><a className='underline text-primary' target={'_blank'} rel={'noopener noreferrer'} href={props.row.original.payment_link}>{props.value}</a></span>
            </div>
          )
        },
      },
      {
        Header: 'Paket & Periode',
        accessor: 'subscription_package_id',
        Cell: (props) => {
            let row = props.row.original;
            let subcription_package_name = row.subscription_package_name;
            let duration = row.duration + ' Bulan';
            
            return (
              <div className='flex items-center justify-start gap-x-8'>
                <span className=''>{subcription_package_name} ({duration})</span>
              </div>
            )
        },
      },
      {
        Header: 'Harga',
        accessor: 'subscription_package_price',
        Cell: (props) => {
          return (
            <div className='flex items-center justify-start gap-x-8'>
              <span className=''>{formatPrice(props.value)}</span>
            </div>
          )
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        Cell: (props) => {
          return (
            <div className='flex items-center justify-start gap-x-8'>
              <span className='px-2 py-1 font-bold text-2xs bg-warning-secondary text-warning-primary'>{ucwords(props.value)}</span>
            </div>
          )
        },
      },
      {
        Header: '',
        accessor: 'id',
        Cell: (props) => {
          return (
            <div className='flex justify-end pr-4 gap-x-4'>
              <button className='px-3 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon' onClick={() => handleViewInvoice(props.value)}>
                <FiFile className='mr-2 stroke-current' />
                <span className='text-sm'>Invoice</span>
              </button>
            </div>
          )
        },
      },
    ],
    []
  );
  let rows = [];

  if (!subscriptionHistoryList.error && subscriptionHistoryList.status !== 'loading') {
    rows = subscriptionHistoryList.data.data.data.list;
  }

  const data = React.useMemo(() => rows, [rows]);
  
  return (
    <Datatable
      totalPage={subscriptionHistoryList?.data?.data.data.total_page}
      columns={columns}
      handleChangePage={handleChangePage}
      data={data}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  )
};

const SubscriptionHistory = () => {
  return (
    <>
      <div className='relative flex items-center justify-between overflow-x-scroll'>
        <h4 className='py-3 font-bold'>Riwayat Paket</h4>
      </div>
      <div className='border rounded-lg custom-shadow'>
        <div className='flex items-center justify-between py-3'>
          <Search placeholder='Cari Riwayat Transaksi Paket' />
          <div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
            <FiFilter className='stroke-current text-md' />
            <select
              className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
              // value={pageSize}
              // onChange={(e) => {
              //   setPageSize(Number(e.target.value));
              // }}
            >
              {['Filter', 25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
            <span>
              <CircularBadge color='bg-blue-grey-100 text-black'>1</CircularBadge>
            </span>
          </div>
        </div>
        <Table />
      </div>
    </>
  );
}

export default SubscriptionHistory;
