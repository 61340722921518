import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import Portal from '../portal';

const Modal = ({
	modalName,
	color,
	title,
	modalWidth, // tailwind based width
	showButtom,
	buttonCancel,
	buttonLeft,
	buttonRight,
	buttonClassLeft,
	buttonClassRight,
	buttonPlacement,
	body,
	buttonSubmit,
}) => {
	const { palettes } = useSelector(
		(state) => ({
			palettes: state.palettes,
		}),
		shallowEqual,
	);
	let { background } = {
		...palettes,
	};

	const modalRef = useRef(null);
	const [open, setOpen] = useState(false);
	const show = () => {
		setOpen(true);
	};
	const hide = () => {
		setOpen(false);
	};

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (!modalRef || !modalRef.current) return false;

			if (!open || modalRef.current.contains(event.target)) {
				return false;
			}
			setOpen(!open);
		};
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [open, modalRef]);

	return (
		<>
			<button
				className={`text-white bg-${color}-500 btn btn-rounded hover:bg-${color}-600`}
				type='button'
				onClick={show}
			>
				{modalName}
			</button>
			{open && (
				<Portal selector='#portal'>
					<div className='modal-backdrop fade-in'></div>
					<div
						className={`modal show ${
							background === 'dark' ? 'dark-mode' : ''
						}`}
						data-background={background}
					>
						<div
							className={`relative ${modalWidth || 'w-1/2'}`}
							ref={modalRef}
						>
							<div className='relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none text-grey-900 border-grey-200 dark:bg-grey-800 dark:text-white dark:border-grey-700'>
								<div className='relative flex-auto p-4'>
									<div className='mb-2 text-lg font-bold'>
										{title}
									</div>
									{body}
								</div>
								{showButtom && (
									<div
										className={`flex items-center ${buttonPlacement} p-4 space-x-2 border-t border-solid rounded-b border-grey-200 dark:border-grey-700`}
									>
										<button
											className={buttonClassLeft}
											type='button'
											onClick={hide}
										>
											{buttonLeft}
										</button>
										<button
											className={buttonClassRight}
											type='button'
											onClick={() => {
												buttonSubmit();
												hide();
											}}
										>
											{buttonRight}
										</button>
									</div>
								)}
							</div>
						</div>
					</div>
				</Portal>
			)}
		</>
	);
};

export default Modal;
