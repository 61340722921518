import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, shallowEqual } from 'react-redux';
import { FiX } from 'react-icons/fi';
import Portal from '../../../../components/portal';
import { doUpdateStatusInvoice } from '../../../../services/api/command/order.command';
import { useHistory } from 'react-router-dom';
import { queryClient } from '../../../../App';
import { useForm } from 'react-hook-form';
import { createMediaRequest } from '../../../../services/api/command/media.command';
import ucwords from '../../../../functions/ucwords';

const UpdateAwbModal = ({ order }) => {
  const history = useHistory();
  const [image, setImage] = useState(null);
  const [imagePath, setImagePath] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  const {
    control,
		register,
		handleSubmit,
		getValues,
		setValue,
    resetField,
		formState: { errors },
	} = useForm();

  const orderStatuses = ['paid', 'invoice_send_to_brand', 'confirm_stock', 'confirm_ready_ship', 'send_delivery_order', 'shipped', 'delivered', 'completed'];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;

      if (event.target.id === 'updateStatus') {
        handleUpdateStatus(order.invoice, order.shipping, imageUrl);
      }

      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef, order, imageUrl]);

  const handleUploadImage = (e) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    formData.append('source', 'upload');
    formData.append('provider', 'cloudinary');
    formData.append('title', getValues('name'));
    formData.append('description', getValues('description'));

    return createMediaRequest()
      .command(formData)
      .then((res) => {
        return res.data.data;
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      });
  };

  const handleChangeImage = (e) => {
    setImage(e.target.files[0]);
    setImagePath(e.target.files[0].name);
    handleUploadImage(e).then((response) => {
      setImageUrl(response.public_url);
    })
  };

  const handleUpdateStatus = (invoice, shipping, image) => {
    let nextStatus = orderStatuses[orderStatuses.indexOf(invoice.status_ops) + 1];
    const body = {
      sales_invoice_no: invoice.sales_invoice_no,
      status: nextStatus,
      shipping_awb: getValues('shipping_awb'),
      shipping_awb_image_url: image,
      courier: shipping?.delivery_courier,
      service_type: shipping?.delivery_type,
      autocreate_shipping_order: 0,
      note: '',
    }

    doUpdateStatusInvoice()
      .command(body)
      .then((response) => {
        hide();
        toast.success('Status Invoice berhasil diubah');
        queryClient.invalidateQueries('update-status-invoice');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <>
      <button type='button' onClick={show}>
        <div className='flex items-center justify-start px-3 py-1 text-white border rounded-lg border-primary gap-x-2 bg-primary'>
         Ready to Ship
        </div>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h5 className='text-xl font-semibold'>Input Resi</h5>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='p-4 modal-body'>
                  <div className="flex flex-row items-center space-x-5">
                    <label className="block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap">No. Resi</label>
                    <input 
                      placeholder="Masukkan no. resi" 
                      type="text" 
                      name="shipping_awb" 
                      className="block w-full bg-white border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 sm:text-sm" 
                      {...register('shipping_awb')}
                    />
                  </div>
                  <div className="flex flex-row items-center space-x-5">
                    <label className='block w-2/5 text-sm font-medium text-gray-700 dark:text-gray-200 whitespace-nowrap'>Upload Bukti Pengiriman</label>
                    <div className='block w-full mt-2'>
                      <label className='flex flex-col items-center w-full px-4 py-2 transition-all duration-150 ease-linear bg-white border rounded-md shadow-sm cursor-pointer text-primary'>
                        <span className='text-base leading-normal'>Pilih Gambar</span>
                        <input
                          accept='.jpg,.png'
                          onChange={(res) => handleChangeImage(res)}
                          type='file'
                          className='hidden'
                        />
                      </label>
                      {imagePath && <div className='mt-2 text-primary'>../{imagePath}</div>}
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <div className='self-center mt-5 space-x-2 text-center'>
                    <button
                    className='border btn btn-default btn-rounded'
                    type='button'
                    onClick={hide}
                    >
                    Tidak
                    </button>
                    <button
                    className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                    type='button'
                    id='updateStatus'
                    onClick={hide}
                    >
                    Ya
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default UpdateAwbModal;
