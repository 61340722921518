import React, { useState, useEffect } from 'react';
import Datatable from '../../components/datatable';
import { FiEdit, FiFilter, FiBox } from 'react-icons/fi';
import Search from '../../components/navbar-1/search';
import { CircularBadge } from '../../components/badges';
import {
	doGetProductList,
	doProductUpdateStatus,
} from '../../services/api/command/product.command';
import SwitchComponent from 'react-switch';
import { getColor } from '../../functions/colors';
import { useHistory } from 'react-router-dom';
import formatPrice from '../../functions/formatPrice';

const Switch = ({ initialState, productId, productName }) => {
	let offColor = `bg-blue-grey-100`;
	let offHandleColor = 'bg-blue-grey-200';

	const [checked, setChecked] = useState(initialState);

	const handleChange = () => {
		if (
			window.confirm(
				`Apakah anda akan ${
					checked ? 'menonaktifkan' : 'mengaktifkan'
				} produk ${productName}?`,
			)
		) {
			if (checked) {
				doProductUpdateStatus().command([
					{
						id: productId,
						status: '0',
					},
				]);
			} else {
				doProductUpdateStatus().command([
					{
						id: productId,
						status: '10',
					},
				]);
			}
			setChecked(!checked);
		}
	};

	return (
		<div className='flex'>
			<SwitchComponent
				onChange={() => handleChange()}
				checked={checked}
				onColor='#B1D5DB'
				onHandleColor='#2FAEC3'
				offColor={getColor(offColor)}
				offHandleColor={getColor(offHandleColor)}
				handleDiameter={24}
				uncheckedIcon={false}
				checkedIcon={false}
				activeBoxShadow='0px 1px 5px rgba(0, 0, 0, 0.2)'
				height={8}
				width={38}
				className='react-switch '
			/>
			<span className='pl-3'>{checked ? 'Aktif' : 'Non-Aktif'}</span>
		</div>
	);
};

const Products = () => {
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(10);
	const {
		data: product,
		status: productStatus,
		isSuccess: productSuccess,
		remove: productRemove,
	} = doGetProductList().query(
		['product list', page, pageSize],
		{},
		{ limit: pageSize, page: page },
	);

	useEffect(() => {
		return () => productRemove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const history = useHistory();

	const handleChangePage = (newPage) => {
		setPage(newPage.selected + 1);
	};

	const Table = () => {
		const sortByVariantLowestPrice = (variants, price_type) => {
			let price = variants?.map((variant) => {
					return variant.prices[0][price_type];
				})
				.sort((a, b) => {
					return a - b;
				});

			if (typeof price[0] == 'undefined') {
				return formatPrice(0);
			}

			return formatPrice(price);
		};

		const formatPrice = (price) => {
			return (
				'Rp. ' +
				price?.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
			);
		};

		const handleEditProduct = (product_id) => {
			history.push(`product/edit/${product_id}`);
		};

		const columns = React.useMemo(
			() => [
				{
					Header: '',
					accessor: 'variants[0]',
					Cell: (props) => {
						let mainImage = props.value?.images.find(
							(e) => e.status === 10,
						);
						return (
							<div className='w-8'>
								{mainImage !== undefined ? (
									<img
										src={`https://res.cloudinary.com/rintiz/image/upload${mainImage.image_url}`}
										className='w-10 rounded'
										alt='img'
									/>
								) : (
									<img
										src={`https://picsum.photos/200`}
										className='w-10 rounded'
										alt='img'
									/>
								)}
							</div>
						);
					},
				},
				// {
				// 	Header: '',
				// 	accessor: 'variants',
				// 	Cell: (props) => {
				// 		return (
				//       <div>asd</div>
				// 			// <img
				// 			// 	src={`res.cloudinary.com/rintiz/image/upload/${props.value[0].images[0]}`}
				// 			// 	className='w-10 rounded'
				// 			// 	alt='img'
				// 			// />
				// 		);
				// 	},
				// },
				{
					Header: 'Nama Produk',
					accessor: 'name',
					Cell: (props) => {
						return <div className='font-bold'>{props.value}</div>;
					},
				},
				{
					Header: 'Harga',
					accessor: 'prices',
					Cell: (props) => {
						let row = props.row.index;
						let selling_price = sortByVariantLowestPrice(
							props.data[row].variants,
							'selling_price',
						);
						let distributor_price = sortByVariantLowestPrice(
							props.data[row].variants,
							'distributor_price',
						);
						return (
							<div className='flex items-center justify-start w60 gap-x-8'>
								<div>
									<span className='font-bold text-teal-500'>
										Jual
									</span>
									<div className='text-grey-500'>
										<small>Mulai</small>{' '}
										{selling_price.split(',')[0]}
									</div>
								</div>
								<div>
									<span className='font-bold text-blue-500'>
										Modal
									</span>
									<div className='text-grey-500'>
										<small>Mulai</small>{' '}
										{distributor_price.split(',')[0]}
									</div>
								</div>
							</div>
						);
					},
				},
				{
					Header: 'Stok',
					accessor: 'is_in_stock',
					Cell: (props) => (
						<div className='w-24 font-bold'>{`${
							props.value ? 'Ya' : 'Tidak'
						}`}</div>
					),
				},
				{
					Header: 'Varian',
					accessor: 'is_variant',
					Cell: (props) => (
						<div className='w-24 font-bold'>{`${
							props.value ? 'Ya' : 'Tidak'
						}`}</div>
					),
				},
				{
					Header: 'status',
					accessor: 'status',
					Cell: (props) => {
						let row = props.row.index;
						let product_id = props.data[row].product_id;
						let product_name = props.data[row].name;
						return (
							<div className=''>
								<Switch
									initialState={
										props.value === 0 ? false : true
									}
									productId={product_id}
									productName={product_name}
								/>
							</div>
						);
					},
				},
				{
					Header: 'Aksi',
					accessor: 'variants',
					Cell: (props) => {
						return (
							<div className='flex justify-end pr-4 gap-x-4'>
								<button
									className='px-4 py-1 bg-transparent border shadow-sm btn btn-rounded btn-icon'
									onClick={() =>
										handleEditProduct(props.value[0].sku)
									}
								>
									<FiEdit className='mr-2 stroke-current' />
									<span className='text-sm'>Edit</span>
								</button>
							</div>
						);
					},
				},
			],
			[sortByVariantLowestPrice],
		);

		let rows = [];

		if (productStatus !== 'loading' && productSuccess) {
			rows = product.data.data.list;
		}

		const data = React.useMemo(() => rows, [rows]);

		return (
			<Datatable
				checkbox={false}
				totalPage={product?.data.data.total_page}
				columns={columns}
				handleChangePage={handleChangePage}
				data={data}
				pageSize={pageSize}
				setPageSize={setPageSize}
			/>
		);
	};

	return (
		<>
			<div className='flex items-center justify-between'>
				<h4 className='py-3 font-bold'>List Produk</h4>
				<div className=''>
					<button
						className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
						onClick={() => history.push('/product/add')}
					>
						<FiBox className='text-lg stroke-current' />
						Tambah Produk
					</button>
				</div>
			</div>
			<div className='border rounded-lg custom-shadow'>
				<div className='flex items-center justify-between py-3'>
					<Search placeholder='Search produk atau SKU' />
					<div className='flex items-center justify-start px-4 py-2 mr-4 border rounded-lg gap-x-3'>
						<FiFilter className='stroke-current text-md' />
						<select
							className='m-0 text-sm text-black shadow-none outline-none appearance-none focus:shadow-none'
							// value={pageSize}
							// onChange={(e) => {
							//   setPageSize(Number(e.target.value));
							// }}
						>
							{['Filter', 25, 50, 100].map((pageSize) => (
								<option key={pageSize} value={pageSize}>
									{pageSize}
								</option>
							))}
						</select>
						<span>
							<CircularBadge color='bg-blue-grey-100 text-black'>
								1
							</CircularBadge>
						</span>
					</div>
				</div>
				<Table />
			</div>
		</>
	);
};
export default Products;
