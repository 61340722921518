import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { FiSidebar } from 'react-icons/fi';
import { FaMoneyCheck } from 'react-icons/fa';
import ProfileDropdown from './ProfileDropdown';
import NotificationDropdown from './NotificationDropdown';
import Search from './search';
import '../../css/components/navbar.css';
import { BiWallet } from 'react-icons/bi';
import Modal from '../modals/modal';
import { doGetWallet } from '../../services/api/command/brand-subscription.command';
import { doGetProfile } from '../../services/api/command/profile.command';
import { formatNumberIDR } from '../../functions/numbers';
import Bank from './assets/bank.png';

const Navbar = () => {
	const history = useHistory();
	const { config } = useSelector(
		(state) => ({
			config: state.config,
		}),
		shallowEqual,
	);
	let { collapsed } = { ...config };
	const dispatch = useDispatch();
	let wallet = doGetWallet().query(['wallet']);
	const {
		data: profile,
		status: profileStatus,
		remove: profileRemove,
	} = doGetProfile().query(['profile']);

	useEffect(() => {
		return () => profileRemove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (wallet.isSuccess && !wallet.isLoading) {
		wallet = wallet.data.data.data.amount;
	}

	return (
		<div className='border-b navbar navbar-1'>
			<div className='flex items-center justify-start w-full navbar-inner'>
				<button
					onClick={() =>
						dispatch({
							type: 'SET_CONFIG_KEY',
							key: 'collapsed',
							value: !collapsed,
						})
					}
					className='mx-4'
				>
					<FiSidebar size={20} color='#6A7786' />
				</button>
				<span className='ml-auto'></span>
				<Modal
					color='primary'
					modalWidth={
						profile?.data?.data?.bank_account_number === null ||
						profile?.data?.data?.bank_account_name === null
							? 'w-1/2'
							: 'w-1/4'
					}
					modalName={
						<div className='relative flex gap-2 mr-10 space-x-1 font-semibold'>
							<BiWallet size={22} color='#6A7786' />
							<span className='text-grey-700'>
								{formatNumberIDR(wallet)}
							</span>
						</div>
					}
					body={
						<div>
							{profile?.data?.data?.bank_account_number ===
								null ||
							profile?.data?.data?.bank_account_name === null ? (
								<div className='flex justify-center items-center flex-col'>
									<img src={Bank} alt='Bank' width={64} />
									<div className='font-bold mt-6'>
										Tambahkan Nomor Rekening Anda
									</div>
									<div className='text-xs mt-6'>
										Anda memerlukan no rekening agar dana
										dapat dikirimkan
									</div>
									<button
										className='btn btn-rounded bg-primary text-white text-sm gap-x-2 px-4 py-2 mt-6'
										onClick={() => history.push('/profile')}
									>
										Tambahkan Sekarang
									</button>
								</div>
							) : (
								<div>
									<div className='flex items-center justify-between mt-5'>
										<div>
											<span className='block mb-3 text-xs text-grey-500'>
												Sisa saldo anda
											</span>
											<span className='block text-lg font-bold'>
												{formatNumberIDR(wallet)}
											</span>
										</div>
										<div>
											<FaMoneyCheck
												size={42}
												color='#E3AF74'
											/>
										</div>
									</div>
									<div className='flex items-center justify-end mt-6'>
										<button
											className='btn btn-rounded bg-grey-500 text-white text-sm gap-x-2 px-4 py-2 mr-4'
											onClick={() =>
												history.push(
													'/withdrawal/history',
												)
											}
										>
											Riwayat
										</button>
										<button className='btn btn-rounded bg-primary text-white text-sm gap-x-2 px-4 py-2'>
											Tarik Dana
										</button>
									</div>
								</div>
							)}
						</div>
					}
				/>
				<NotificationDropdown />
				<ProfileDropdown />
				<div className='mr-5' />
			</div>
		</div>
	);
};

export default Navbar;
