import React, { useState } from "react";
import toast from 'react-hot-toast';
import { useForm } from "react-hook-form";
import Breadcrumb from "../../../components/breadcrumbs";
import { useHistory } from "react-router-dom";
import { CustomToolbarExample } from "../../../components/text-editor";
import SwitchComponent from 'react-switch'
import { getColor } from "../../../functions/colors";
import { doUpdateMembershipType } from "../../../services/api/command/membership.command";
import { queryClient } from "../../../App";
import { Button } from "../../../components/buttons/buttons";

function MembershipTypeSwitch({initialState = false, id}) {
  let offColor = `bg-blue-grey-100`;
	let offHandleColor = 'bg-blue-grey-200';

	const [checked, setChecked] = useState(initialState);

  const handleChange = () => {
    setChecked(!checked)
  }

  return (
    <div className='flex'>
      <SwitchComponent
        onChange={() => handleChange(!checked)}
        checked={checked}
        onColor='#B1D5DB'
        onHandleColor='#2FAEC3'
        offColor={getColor(offColor)}
        offHandleColor={getColor(offHandleColor)}
        handleDiameter={24}
        uncheckedIcon={false}
        checkedIcon={false}
        activeBoxShadow="0px 1px 5px rgba(0, 0, 0, 0.2)"
        height={8}
        width={38}
        className="react-switch "
        id={id}
      />
    </div>
  )
}

function FreeMembershipType() {
  const breadcrumb = [
    { title: "Tipe Membership", url: "/membership/type", last: false },
    { title: "Free Membership", url: "", last: true },
  ];
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const {
    register,
    getValues,
    setValue,
    resetField,
    formState: { errors },
  } = useForm();

  const data = JSON.parse(localStorage.getItem('membership-type'));

  const handleUpdate = () => {
    const body = {
      'brandowner_id': data.brandowner_id,
      'description': getValues('description'),
      'kode': 0,
      'label': 'Free',
      'status': getValues('status'),
      'package': [
        {
          'status': document.getElementById('package-forever.status').checked ? 1 : 0,
          'duration': -1,
          'id': parseInt(getValues(`package-forever.id`)),
          'price': 0,
          'name': 'Selamanya'
        },
        {
          'status': document.getElementById('package-monthly.status').checked ? 1 : 0,
          'duration': parseInt(getValues(`package-monthly.duration`)),
          'id': parseInt(getValues(`package-monthly.id`)),
          'price': 0,
          'name': 'Bulanan'
        }
      ]
    }

    doUpdateMembershipType().command(body).then((response) => {
      setLoading(false);
      toast.success('Membership Type berhasil diubah');
      queryClient.invalidateQueries('membership-type');
      history.goBack();
    })
    .catch((err) => {
      toast.error(err.response.data.error.message);
    })
  }

  return (
    <div className="px-32">
      <Breadcrumb items={breadcrumb} />
      <div className="flex items-center justify-between">
        <h4 className="py-3 font-bold">Free Membership</h4>
        <div className="flex items-end">
          <div>
            <button className="px-4 py-2 mt-8 text-sm font-bold border rounded-md btn gap-x-2">
              Batalkan
            </button>
          </div>
          <div className="ml-4">
            <Button
              loading={loading}
              text={'Simpan'}
              onClick={handleUpdate}
              className='flex items-center px-4 py-2 text-sm font-bold text-white rounded-md btn gap-x-2 bg-primary'
            />
          </div>
        </div>
      </div>
      {/* Deskripsi */}
      <div className="mt-8">
        <label className="text-base font-medium">Deskripsi</label>
        <div className="mt-8">
          <CustomToolbarExample 
            value={data.description}
            onChange={(e) => setValue('description', e)}
          />
        </div>
      </div>
      {data.package.map((periode) => {
        return (
          <div className="p-5 mt-6 border shadow">
            <div className="flex items-center justify-between">
              <div className="text-base font-semibold">{periode.name}</div>
              <MembershipTypeSwitch initialState={periode.status === 1 ? true : false} id={`package-${periode.duration === -1 ? 'forever' : 'monthly'}.status`} />
            </div>
            <input 
              type='hidden' 
              name="id" 
              value={periode.id}
              {...register(`package-${periode.duration === -1 ? 'forever' : 'monthly'}.id`, {required: true})}
            />
            {periode.duration !== -1 && (
            <div className="mt-10">
              <div className="grid grid-cols-8 mt-8">
                <label className="col-span-2">Jangka Waktu</label>
                <select 
                  className="col-span-6 text-sm border rounded-lg form-select"
                  {...register(`package-${periode.duration === -1 ? 'forever' : 'monthly'}.duration`, {required: true})}
                >
                  <option value="30">1 Bulan</option>
                  <option value="90">3 Bulan</option>
                  <option value="180">6 Bulan</option>
                  <option value="360">12 Bulan</option>
                </select>
              </div>
            </div>
          )}
          </div>
        )
      })}
      {/* Lainnya */}
      <div className="p-5 mt-6 border shadow">
        <div className="text-base font-semibold">Lainnya</div>
        <div className="mt-10">
          <div className="grid grid-cols-8 mt-8">
            <label className="col-span-2">Status Membership</label>
            <select 
              className="col-span-6 text-sm border rounded-lg form-select"
              {...register('status', {required: true})}
            >
              <option value="active">Aktif</option>
              <option value="nonactive">Non-aktif</option>
            </select>
          </div>
        </div>
      </div>
    <div className="p-5 mt-6 border shadow">
      <div className="flex items-center justify-between">
        <div className="text-base font-semibold">Referral Code</div>
        <MembershipTypeSwitch />
      </div>
    </div>
  </div>
  );
}

export default FreeMembershipType;
