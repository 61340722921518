import React from "react";
import { FiPrinter } from "react-icons/fi";
import formatPrice from "../../../../functions/formatPrice";
import shippingLabel from "../../../shipping-label";

function InvoiceShippingInformation({data, tracking}) {
  const delivery_courier = data?.delivery_courier;
  const delivery_category = data?.delivery_category;
  const delivery_estimated_time = data?.delivery_estimated_time;
  const delivery_cost = data?.delivery_cost || 0;

  function closePrint() {
    document.body.removeChild(this.__container__);
  }

  function setPrint() {
    this.contentWindow.__container__ = this;
    this.contentWindow.onbeforeunload = closePrint;
    this.contentWindow.onafterprint = closePrint;
    this.contentWindow.focus(); // Required for IE
    this.contentWindow.print();
  }

  function printPage(sURL) {
    var oHiddFrame = document.createElement("iframe");
    oHiddFrame.onload = setPrint;
    oHiddFrame.style.visibility = "hidden";
    oHiddFrame.style.position = "fixed";
    oHiddFrame.style.right = "0";
    oHiddFrame.style.bottom = "0";
    oHiddFrame.src = sURL;
    document.body.appendChild(oHiddFrame);
  }

  return (
    <div id="order-information">
      <div className="p-4 mb-6 border rounded-md shadow-custom">
        <div className="font-bold text-md">Informasi Pengiriman</div>
        <div className="flex flex-col mt-4 gap-x-10">
          <table className="w-full text-left capitalize table-auto">
            <tbody>
              <tr>
                <td className="py-2 font-medium leading-4 w-52 text-grey-500">
                  Jasa Kirim
                </td>
                <td className="py-2 whitespace-no-wrap">
                  {delivery_courier ? delivery_courier + ' ' + delivery_category + ' (' + delivery_estimated_time + ')' : '-'}
                </td>
              </tr>
              <tr>
                <td className="py-2 font-medium leading-4 text-grey-500">
                  Nomor Resi
                </td>
                <td className="py-2 whitespace-no-wrap">{tracking?.awb || '-'}</td>
              </tr>
              <tr>
                <td className="py-2 font-medium leading-4 text-grey-500">
                  Sub total
                </td>
                <td className="py-2 whitespace-no-wrap">
                  <div>{formatPrice(delivery_cost)}</div>
                </td>
              </tr>
              {delivery_courier && (
                <tr>
                  <td>
                    <button
                      className="flex items-center px-1 py-2 mt-3 text-sm border rounded-lg btn gap-x-2"
                      onClick={() => printPage("/shipping-label")}
                    >
                      <FiPrinter />
                      Cetak label pengiriman
                    </button>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default InvoiceShippingInformation;
