import queryCommander from "../queryCommander";

interface TCouponListRequest {
  limit: number;
  page: number;
  status: string;
  q: string;
}

export const doGetCouponList = <TCouponList = TCouponListRequest>() =>
  queryCommander<TCouponList>({
    method: "GET",
    path: "/coupon/list",
    queryType: "query",
    authenticated: true,
  });

interface TCouponCreateRequest {
  code: string;
  name: string;
  description: string;
  category: string;
  type: string;
  value: number;
  maximum_value: number;
  start_date: string;
  expired_date: string;
  minimum_spend: number;
  maximum_spend: number;
  maximum_use: number;
  maximum_use_coupon: number;
  individual_use: string;
  allow_all_products: boolean;
  allowed_products: string[];
  exclude_products: string[];
  allow_all_brands: boolean;
  allowed_brands: string[];
  exclude_brands: string[];
  requirement: string;
  how_to_use: string;
  agent_id: number;
  image: string;
}

export const doCouponCreate = <TCouponCreate = TCouponCreateRequest>() =>
  queryCommander<TCouponCreate>({
    method: "POST",
    path: "/coupon/create",
    queryType: "query",
    authenticated: true,
  });

export const doEditCoupon = <TCouponCreate = TCouponCreateRequest>() =>
  queryCommander<TCouponCreate>({
    method: "POST",
    path: "/coupon/update",
    queryType: "query",
    authenticated: true,
  });
