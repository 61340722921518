import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import NumberFormat from 'react-number-format';
import { ActionMeta, OnChangeValue } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/buttons/buttons';
import phoneSanity from '../../functions/phoneSanity';
import { doRegister } from '../../services/api/command/auth.command';
import axios from 'axios';
import { useQuery } from 'react-query';
import { getCategories } from '../../services/api/command/categories.command';
import { FiEye, FiEyeOff } from 'react-icons/fi';
import { categories } from './type';

const fetchCategories = async () => {
	let newArr = [];
	const response = await axios.get(
		'https://api.dev.rintiz.id/category/?limit=10&page=1&status=10',
		{
			data: {
				bob: 's',
			},
		},
		{
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				Accept: 'application/json',
			},
		}
	);

	await response.data.data.list.forEach((element) => {
		newArr.push({
			value: element.category_id,
			label: element.name,
		});
	});

	return newArr;
};

const Register = () => {
	const history = useHistory();
	const [data, setData] = useState(categories);
	const [loading, setLoading] = useState(false);
	const [isPasswordSeeing, setPasswordSeeing] = useState(false);
	const [isRePasswordSeeing, setRePasswordSeeing] = useState(false);

	const [repassword, setRepassword] = useState('');
	const [password, setPassword] = useState('');
	// const { data } = useQuery('categories', fetchCategories);

	const customStyle = {
		control: (provided, state) => ({
			...provided,
			padding: '10px',
			borderRadius: '0.375rem',
			border: '1px solid #e2e8f0',
			outline: 'none',
		}),
	};

	const {
		register,
		handleSubmit,
		getValues,
		reset,
		control,
		formState: { errors },
	} = useForm({
		criteriaMode: 'all',
	});

	const handleSeePassword = () => {
		setPasswordSeeing(!isPasswordSeeing);
	};

	const handleSeeRePassword = () => {
		setRePasswordSeeing(!isRePasswordSeeing);
	};

	const extractCategoryData = () => {
		const categories = [];

		(getValues('category') || []).forEach((element) => {
			categories.push(element.value);
		});
		return categories.join();
	};

	const handleRegis = () => {
		setLoading(true);
		const QtyEstimate = getValues('transaction_qty_estimation')
			.split('.')
			.join('');

		if (repassword !== password) {
			toast.error('Kata sandi tidak sama');
			setLoading(false);
			return;
		}

		doRegister()
			.command({
				...getValues(),
				phone_number: phoneSanity(getValues('phone_number')),
				transaction_qty_estimation: QtyEstimate,
				category: extractCategoryData(),
			})
			.then(() => {
				setLoading(false);
				history.push({
					pathname: '/otp',
					state: {
						phone_number: phoneSanity(getValues('phone_number')),
						from: '/register',
					},
				});
				reset();
			})
			.catch((err) => {
				setLoading(false);
				toast.error(err.response.data.error.message);
			});
	};

	const handleTypePassword = (e) => {
		setRepassword(e.target.value);
	};

	const handlePassword = (e) => {
		setPassword(e.target.value);
	};

	return (
		<div className='bg-grey-50 w-full'>
			<div className='w-1/2 py-16 m-auto'>
				<div className='flex'>
					{/* <img
						src='/rintiz-logo.svg'
						className='mr-2'
						width={'30px'}
						alt='Rintiz Logo'
					/> */}
					<span>
						<img
							src='/rintiz-logo.svg'
							style={{ height: '38px' }}
							alt='Rintiz Name Logo'
						/>
					</span>
				</div>
				<form className='mt-10' onSubmit={handleSubmit(handleRegis)}>
					<span>
						<label className='text-base'>*Nama Lengkap</label>
						<input
							placeholder='Masukkan Nama Lengkap'
							type='text'
							{...register('fullname', {
								required: 'Wajib Diisi',
								maxLength: 50,
							})}
							name='fullname'
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.fullname?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
					</span>

					<div className='mt-4'>
						<label className='text-base'>*Nama Brand / Company</label>
						<input
							placeholder='Masukkan Nama Brand / Company'
							type='text'
							{...register('name', { required: true, maxLength: 50 })}
							name='name'
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.name?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
					</div>
					<div className='mt-4'>
						<label className='text-base'>*Alamat Email</label>
						<input
							placeholder='Masukan Alamat Email'
							type='email'
							name='email'
							{...register('email', {
								required: true,
								maxLength: 30,
								pattern: {
									value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
									message: 'invalid email address',
								},
							})}
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.email?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
						{errors?.email?.message && (
							<p className='text-red-500 mt-2'>*email tidak valid</p>
						)}
					</div>
					<div className='mt-4 relative'>
						<label className='text-base'>*Kata Sandi</label>
						<input
							placeholder='Minimal 8 karakter'
							type={isPasswordSeeing ? 'text' : 'password'}
							{...register('password', { required: true, minLength: 8 })}
							name='password'
							onChange={handlePassword}
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.password?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
						{errors?.password?.type === 'minLength' && (
							<p className='text-red-500 mt-2'>*password min.8 karakter</p>
						)}
						<div className='absolute right-0 pr-6 flex' style={{ top: '59px' }}>
							{isPasswordSeeing ? (
								<FiEye
									onClick={handleSeePassword}
									size={18}
									className='stroke-current cursor-pointer'
								/>
							) : (
								<FiEyeOff
									onClick={handleSeePassword}
									size={18}
									className='stroke-current cursor-pointer'
								/>
							)}
						</div>
					</div>
					<div className='mt-4 relative'>
						<label className='text-base'>*Konfirmasi Kata Sandi</label>
						<input
							placeholder='Minimal 8 karakter'
							type={isRePasswordSeeing ? 'text' : 'password'}
							{...register('repassword', { required: true, minLength: 8 })}
							name='repassword'
							// inputRef={register({ validate: value => value === getValues("password")})}
							onChange={handleTypePassword}
							className='mt-4 py-4 px-5 text-sm block w-full b  order-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.repassword?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
						{errors?.repassword?.type === 'minLength' && (
							<p className='text-red-500 mt-2'>*password min.8 karakter</p>
						)}
						<div className='absolute right-0 pr-6 flex' style={{ top: '59px' }}>
							{isRePasswordSeeing ? (
								<FiEye
									onClick={handleSeeRePassword}
									size={18}
									className='stroke-current cursor-pointer'
								/>
							) : (
								<FiEyeOff
									onClick={handleSeeRePassword}
									size={18}
									className='stroke-current cursor-pointer'
								/>
							)}
						</div>
					</div>

					<div className='mt-4'>
						<label className='text-base'>*Nomor Kontak</label>
						<input
							placeholder='Masukkan Nomor Kontak'
							type='number'
							name='phone_number'
							{...register('phone_number', {
								required: true,
								maxLength: 13,
								minLength: 10,
								pattern: {
									value: /^[0-9]+$/,
									message: 'Please enter a number',
								},
							})}
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.phone_number?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
						{errors?.phone_number?.type === 'minLength' && (
							<p className='text-red-500 mt-2'>*nomor min.10 karakter</p>
						)}
						{errors?.phone_number?.type === 'maxLength' && (
							<p className='text-red-500 mt-2'>*nomor max.13 karakter</p>
						)}
					</div>
					<div className='mt-4'>
						<label className='text-base'>Kategori Produk</label>
						<div className='mt-4'>
							<Controller
								control={control}
								name='category'
								render={({ field: { onChange, name, value } }) => (
									<CreatableSelect
										isMulti
										name={name}
										value={value}
										onChange={onChange}
										className='basic-multi-select'
										placeholder='Pilih Kategori'
										options={data}
										styles={customStyle}
										getInputRef={register(`category`, {
											required: {
												value: true,
											},
										})}
									/>
								)}
							/>
						</div>
						{errors?.kategory_id?.type === 'required' && (
							<p className='text-red-500 mt-2'>*wajib diisi</p>
						)}
					</div>
					<div className='mt-4'>
						<label className='text-base'>
							*Estimasi Jumlah Reseller yang Dikelola Saat Ini
						</label>
						<input
							placeholder='Masukan Jumlah Reseller'
							type='number'
							name='reseller_qty_estimation'
							{...register('reseller_qty_estimation', {
								required: {
									value: true,
									message: 'wajib diisi',
								},
								min: {
									value: 1,
									message: 'Angka tidak boleh minus',
								},
							})}
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
						/>
						{errors?.reseller_qty_estimation?.message && (
							<p className='text-red-500 mt-2'>
								*{errors?.reseller_qty_estimation?.message}
							</p>
						)}
					</div>
					<div className='mt-4'>
						<label className='text-base'>
							*Estimasi Jumlah Transaksi Per Bulan (Rupiah)
						</label>
						<div className='flex mt-4 col-span-6'>
							<div className='text-center py-4 bg-grey-300 rounded-l-lg w-10'>
								Rp.
							</div>
							<Controller
								control={control}
								name='transaction_qty_estimation'
								render={({ field: { onChange, name, value } }) => (
									<NumberFormat
										className='border-r pl-5 border-t border-b rounded-r-lg text-sm w-full'
										thousandSeparator={'.'}
										decimalSeparator={','}
										onChange={onChange}
										name={name}
										getInputRef={register('transaction_qty_estimation', {
											required: {
												value: true,
												message: 'wajib diisi',
											},
											min: {
												value: 1,
												message: 'Angka tidak boleh minus',
											},
										})}
										placeholder='Masukan Jumlah Transaksi'
										value={value}
									/>
								)}
							/>
						</div>
						{errors?.reseller_qty_estimation?.message && (
							<p className='text-red-500 mt-2'>
								*{errors?.reseller_qty_estimation?.message}
							</p>
						)}
					</div>
					<div className='mt-4'>
						<label className='text-base'>Kode Akses (opsional)</label>
						<input
							type='number'
							name='transaction_qty_estimation'
							{...register('referral_code', {
								required: false,
							})}
							className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
							placeholder='Masukan Kode Akses'
						/>
					</div>
					<div className='mt-8'>
						<Button
							loading={loading}
							text={'Daftar'}
							className='btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-primary text-white'
						/>
					</div>
				</form>
			</div>
		</div>
	);
};

export default Register;
