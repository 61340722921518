import React, { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Layout from '../../layouts/centered';
import LoginImage from './assets/login.jpg';
import { doRequestChangePassword } from '../../services/api/command/auth.command';
import { Button } from '../../components/buttons/buttons';
import replacePhone from '../../functions/replacePhone';
import toast from 'react-hot-toast';

const NewPassword = () => {
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    register,
    getValues,
    formState: { errors },
  } = useForm();

  const handleCreateNewPassword = () => {
    setLoading(true);
    doRequestChangePassword()
      .command({
        phone_number: replacePhone(location.state.phone_number),
        ...getValues(),
      })
      .then(() => {
        setLoading(false);
        toast.success('Password changed successfully', {
          duration: 3000,
        })
      }).then(() => {
        history.push('/login');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Layout className='flex content-between'>
      <div className='flex w-full justify-center'>
        <div className='w-3/5 my-auto mx-32'>
          <div className='flex'>
            <img
              src='/rintiz-logo.svg'
              className='mr-2'
              width={'30px'}
              alt='Rintiz Logo'
            />
            <span>
              <img
                src='/rintiz-name.svg'
                style={{ height: '38px' }}
                alt='Rintiz Name Logo'
              />
            </span>
          </div>
          <div className='mt-8'>
            <label className='text-base'>Kata Sandi Baru</label>
            <input
              placeholder='Masukkan kata sandi baru'
              type='password'
              {...register('password', { required: true, maxLength: 30 })}
              name='password'
              className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0 '
            />
            {errors.password && errors.password.type === 'required' && (
              <div className='pt-2 text-red-500'>*This is required</div>
            )}
          </div>
          <div className='mt-4'>
            <label className='text-base'>Konfirmasi Kata Sandi Baru</label>
            <input
              placeholder='Konfirmasi kata sandi'
              type='password'
              {...register('re_password', { required: true, maxLength: 30 })}
              name='re_password'
              className='mt-4 py-4 px-5 text-sm block w-full border-gray-300 rounded-md form-input dark:bg-gray-800 dark:border-gray-700 flex-grow-1 focus:border-blue-500 focus:ring-0'
            />
            {errors.re_password && errors.re_password.type === 'required' && (
              <div className='pt-2 text-red-500'>*This is required</div>
            )}
          </div>
          <div className='mt-8'>
            <Button
              onClick={handleCreateNewPassword}
              loading={loading}
              text={'Simpan Perubahan'}
              className='btn w-full gap-x-2 rounded-md font-bold text-base py-4 bg-primary text-white'
            />
          </div>
        </div>
      </div>
      <img src={LoginImage} alt='...' className='h-full w-auto' />
    </Layout>
  );
};

export default NewPassword;
