import React, { useState, useEffect, useRef } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { FiEye, FiX } from 'react-icons/fi';
import Portal from '../../../../components/portal';
import ucwords from '../../../../functions/ucwords';
import formatPrice from '../../../../functions/formatPrice';

const OrderModal = ({ order }) => {
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;
      console.log(modalRef.current.contains(event.target));
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef]);

  return (
    <>
      <button type='button' onClick={show}>
        <div className='flex items-center px-3 py-1 text-sm font-bold bg-white border rounded-md text-primary btn gap-x-2 border-grey-500'>
            <FiEye className='mr-1 stroke-current' />
            <span className='text-sm'>View Invoice</span>
        </div>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-4/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h5 className='text-xl font-semibold'>Invoice ({order.order.sales_order_no})</h5>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex flex-auto p-4 space-x-3 gap-y-3'>
                  <div className='w-1/2 space-y-3'>
                    <div className='border border-grey-300'>
                      <div className='p-3 font-bold bg-grey-300'>
                        <h5>{order.order.sales_order_no}</h5>
                      </div>
                      <div className='flex flex-col px-3 py-4 space-y-2'>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Nomor Order</div>
                          <div className='w-2/3 p-2'>{order.order.sales_order_id}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Nomor Invoice</div>
                          <div className='w-2/3 p-2'>{order.order.sales_order_no}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Status</div>
                          <div className='w-2/3 p-2'>{ucwords(order.order.status)}</div>
                        </div>
                      </div>
                    </div>
                    <div className='border border-grey-300'>
                      <div className='p-3 font-bold bg-grey-300'>
                        <span className='font-bold'>Informasi Pengiriman</span>
                      </div>
                      <div className='flex flex-col px-3 py-4 space-y-2'>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Jasa Kirim</div>
                          <div className='w-2/3 p-2'>-</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Nomor Resi</div>
                          <div className='w-2/3 p-2'>-</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Berat</div>
                          <div className='w-2/3 p-2'>-</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Penerima</div>
                          <div className='w-2/3 p-2'>{order.order.shipping.receiver_name}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>No. Telp</div>
                          <div className='w-2/3 p-2'>{order.order.shipping.receiver_phone}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Email</div>
                          <div className='w-2/3 p-2'>{order.order.shipping.receiver_email}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Alamat</div>
                          <div className='w-2/3 p-2'>{ucwords(order.order.shipping.address)} {ucwords(order.order.shipping.kecamatan_name)} {ucwords(order.order.shipping.city_name)} {ucwords(order.order.shipping.province_name)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='w-1/2'>
                    <div className='border border-grey-300'>
                      <div className='p-3 font-bold bg-grey-300'>
                        <span className='font-bold'>Informasi Order</span>
                      </div>
                      <div className='flex flex-col px-3 py-4 space-y-2'>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Tanggal Order</div>
                          <div className='w-2/3 p-2'>{order.order.created_at}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Reseller</div>
                          <div className='w-2/3 p-2'>{order.order.agent.name}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>No. Telp</div>
                          <div className='w-2/3 p-2'>{order.order.agent.phone}</div>
                        </div>
                        <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Email</div>
                          <div className='w-2/3 p-2'>{order.order.agent.email}</div>
                        </div>
                        {/* <div className='flex border rounded-lg border-grey-300'>
                          <div className='w-1/3 p-2 font-semibold text-center bg-grey-200 text-grey-700'>Alamat</div>
                          <div className='w-2/3 p-2'>{ucwords(order.order.shipping.address)} {ucwords(order.order.shipping.kecamatan_name)} {ucwords(order.order.shipping.city_name)} {ucwords(order.order.shipping.province_name)}</div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='relative flex flex-auto p-4 space-x-3 gap-y-3'>
                  <div className='w-full'>
                    <div className='border border-grey-300'>
                      <div className='p-3 font-bold bg-grey-300'>
                        <span className='font-bold'>Informasi Produk</span>
                      </div>
                      <div className='flex flex-col space-y-2'>
                        <table className='table striped table-lg'>
                          <thead>
                            <tr>
                              <th>Nama Produk</th>
                              <th>Qty</th>
                              <th>Harga per Produk / Satuan</th>
                              <th>Subtotal</th>
                            </tr>
                          </thead>
                          <tbody>
                            {order.items.map((item) => {
                              console.log(item)
                              return (
                                <tr>
                                  <td>{item.details[0].name}</td>
                                  <td>{item.details[0].qty}</td>
                                  <td>{formatPrice(item.details[0].selling_price)}</td>
                                  <td>{formatPrice(item.details[0].subtotal)}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='modal-footer'>
                  <div className='self-center space-x-2 text-center'>
                    <button
                      className='border btn btn-default btn-rounded'
                      type='button'
                      onClick={hide}
                    >
                      Tutup
                    </button>
                    <button
                      className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                      type='button'
                      onClick={hide}
                    >
                      Print Invoice
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default OrderModal;
