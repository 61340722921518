import React, { useState, useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { useSelector, shallowEqual } from 'react-redux';
import { FiX } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import formatPrice from '../../../../functions/formatPrice';
import Portal from '../../../../components/portal';
import { doSubscribe } from '../../../../services/api/command/brand-subscription.command';
import { queryClient } from '../../../../App';
import { doGetProfile } from '../../../../services/api/command/profile.command';

const SubscriptionConfirmationModal = ({ subscribeOn, duration, isCustom, isFree }) => {
  const history = useHistory();
  const { palettes } = useSelector(
    (state) => ({
      palettes: state.palettes,
    }),
    shallowEqual
  );
  let { background } = {
    ...palettes,
  };

  const modalRef = useRef(null);
  const [open, setOpen] = useState(false);
  const show = () => {
    setOpen(true);
  };
  const hide = () => {
    setOpen(false);
  };

  const profile = doGetProfile().query('profile').data?.data.data;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!modalRef || !modalRef.current) return false;

      if (event.target.id === 'updateStatus') {
        handleUpdateSubscription(subscribeOn, duration, isFree);
      }
      
      if (!open || modalRef.current.contains(event.target)) {
        return false;
      }
      setOpen(!open);
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open, modalRef]);

  const handleUpdateSubscription = (subscribeOn, duration, isFree) => {
    const body = {
      'package_id': subscribeOn?.id,
      'duration': duration,
      'callback': `https://cms.dev.rintiz.id/subscriptions/history`
    }

    doSubscribe()
      .command(body)
      .then((response) => {
        if (! isFree) {
          toast.success('Subskripsi Paket berhasil dilakukan. Anda akan diarahkan ke halaman pembayaran.');
          window.open(response.data?.data.payment_link, "_blank");
        } else {
          toast.success('Subskripsi Paket berhasil dilakukan.');
        }
        queryClient.invalidateQueries('update-subscription');
        history.go(0);
      })
      .catch((err) => {
        toast.error(err.response.data.error.message);
      })
  }

  return (
    <>
      <button type='button' onClick={show}>
        <div className='flex items-center justify-start px-4 py-2 text-white border rounded-lg border-primary gap-x-2 bg-primary'>
          <span>Upgrade</span>
        </div>
      </button>
      {open && (
        <Portal selector='#portal'>
          <div className='modal-backdrop fade-in'></div>
          <div className={`modal show bg-black bg-opacity-25`}>
            <div
              className='relative w-2/5 mx-auto min-w-sm lg:max-w-5xl'
              ref={modalRef}
            >
              <div className='bg-white modal-content'>
                <div className='modal-header'>
                  <h5 className='text-xl font-semibold'>Konfirmasi</h5>
                  <button
                    className='modal-close btn btn-transparent'
                    onClick={hide}
                  >
                    <FiX size={18} className='stroke-current' />
                  </button>
                </div>
                <div className='relative flex-auto p-4 space-y-5 text-center'>
                  <h6 className='font-bold'>Upgrade Paket Subskripsi</h6>
                  <span className='text-grey-600'>Anda akan upgrade paket subskripsi menjadi</span>
                  <div className={`w-full p-3 border-2 rounded border-primary`}>
                    <div className='flex items-center justify-between'>
                      <div className='space-y-2'>
                        <span>{subscribeOn?.name}</span>
                        <h5 className='font-bold'>{subscribeOn?.price ? (formatPrice(subscribeOn?.price * duration)) : subscribeOn?.price === 0 ? 'Free' : 'Harga Spesial'}</h5>
                      </div>
                      <div className='space-y-2 text-xs'>
                        <span className='text-primary'>Periode {duration} bulan</span>
                        <div className='p-1 rounded bg-grey-200 text-grey-600'>{subscribeOn?.description}</div>
                      </div>
                    </div>
                  </div>
                  <div className='self-center mt-5 space-x-2 text-center'>
                    <button
                      className='border btn btn-default btn-rounded'
                      type='button'
                      onClick={hide}
                    >
                      Batalkan
                    </button>
                    {! isCustom && (
                      <button
                        className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                        type='button'
                        id='updateStatus'
                        onClick={hide}
                      >
                        Lanjutkan
                      </button>
                    )}
                    {isCustom && (
                      <a 
                        href={`https://wa.me/6281299881647?text=Halo%20saya%20${profile.name}%20ingin%20pesan%20paket%20Paket%20Custom%20Subskripsi%20dengan%20jumlah%20reseller%20lebih%20dari%205000.`} 
                        target='_blank' 
                        rel='noopener noreferrer'
                        onClick={hide}
                        className='text-white bg-primary btn btn-default btn-rounded hover:bg-blue-600'
                      >
                        Hubungi Kami
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default SubscriptionConfirmationModal;
